import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Grid, Typography, Paper, Box, FormHelperText, Accordion, AccordionSummary, AccordionDetails, Autocomplete, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useFormContext, useFormState, useWatch, Controller } from "react-hook-form";
import { toast } from "react-toastify";

// domain
import { requirementLevelOptions } from "domain/selectOptions";

// store
import { useAppSelector } from "store";

// services
import { getOperationTypesByCustomers } from "services/operationType";

// helpers 
import { makeSelectOptions } from "helpers/makeSelectOptions";

// components
import Aux from "hoc/auxiliar";
import PermissionsGate from "components/PermissionsGate";
import { Input } from "components/react-hook-form";
import RangeComponent from "components/RangeComponent";

import useStyles from "./styles";

const StyledAutocomplete = styled(Autocomplete)(() => ({
  maxWidth: '540px',
  '.MuiInputBase-root': {
    borderRadius: 20,
  }
}));

const FormVehicleGroupComponent = () => {
  const classes = useStyles();

  const { currentCustomer } = useAppSelector((state) => state.global.user);

  const { control, setValue } = useFormContext();

  const { errors } = useFormState({ control });

  const rewardOptions = useMemo(() => ([
    {
      value: 'mileageReward',
      label: 'Premiação por km e nota',
      description: 'O motorista pode ganhar até o valor máximo definido com base nos km percorridos. Exemplo: se a meta é 5000 km e ele rodou 2500 km, só poderá ganhar até metade do valor. Esse valor será ajustado pela nota. Se a nota for 70, ele ganha 70% do valor máximo proporcional aos km rodados. Caso tenha batido a meta de km ou você coloque zero, será considerado o proporcional da nota.',
      permissions: [],
    },
    {
      value: 'scoreMediumReward',
      label: 'Premiação por nota mínima proporcional',
      description: 'Defina uma nota mínima para o motorista começar a ganhar. Exemplo: com uma nota de 70, ele só começa a ganhar a partir daí. O valor será proporcional tanto à nota quanto aos km rodados. A partir da nota mínima, a premiação vai sendo calculada. Caso tenha batido a meta de km ou você coloque zero, será considerado o proporcional da nota.',
      permissions: ['can_create_minscore', 'can_edit_minscore'],
    },
    {
      value: 'scoreMinimunReward',
      label: 'Premiação por nota mínima',
      description: 'Ao atingir a nota mínima (ex: 70) e com uma premiação máxima de 1000 reais, o motorista ganha 700 reais. O valor final também considera os km rodados. Caso tenha batido a meta de km ou você coloque zero, será considerado o proporcional da nota.',
      permissions: ['can_create_minscore', 'can_edit_minscore'],
    },
    {
      value: 'range',
      label: 'Premiação por faixa/range',
      description: 'O motorista irá receber o valor que você inserir enquanto estiver na faixa de nota indicada, tendo sua premiação alterada apenas quanto atingir uma nota que esteja em outra faixa.',
      permissions: ['can_edit_range'],
    }
  ]), []);

  const [isDisabledRequirementLevel, setIsDisabledRequirementLevel] = useState(false);
  const [operationTypeAllData, setOperationTypeAllData] = useState([]);
  const [operationTypeOptions, setOperationTypeOptions] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const operationType = useWatch({ control, name: "operationType" });
  const operationTypeOption = useWatch({ control, name: "operationTypeOption" });
  const requirementLevelOption = useWatch({ control, name: "requirementLevelOption" });
  const rewardFormat = useWatch({ control, name: "rewardFormat" });
  const enabledExtraReward = useWatch({ control, name: "enabledExtraReward" });
  const considerScore = useWatch({ control, name: "considerScore" });

  const fetchOperationTypes = useCallback(async () => {
    try {
      const response = await getOperationTypesByCustomers(currentCustomer);

      const options = makeSelectOptions({
        data: response.data.operationTypes,
        keyValue: "id",
        keyLabel: "name",
      });

      setOperationTypeOptions(options);
      setOperationTypeAllData(response.data.operationTypes);
    } catch (error) {
      toast.error(
        "Erro ao buscar tipos de operações! Entre em contato com o suporte.",
      );
    }
  }, [currentCustomer])

  useEffect(() => {
    if (operationType) {
      const findType = operationTypeAllData.find(
        (op) => op.id === operationType.value,
      );

      if (findType?.type === 2) {
        setValue("requirementLevel", { value: 4, label: "Personalizada", type: "custom" });
        setIsDisabledRequirementLevel(true);
      } else {
        const defaultRequirementLevel = requirementLevelOptions.find(
          (option) => option.value === requirementLevelOption,
        );

        if (defaultRequirementLevel) {
          setValue("requirementLevel", defaultRequirementLevel);
        } else {
          setValue("requirementLevel", "");
        }

        setIsDisabledRequirementLevel(false);
      }
    }
  }, [operationType, requirementLevelOption]);

  useEffect(() => {
    if (operationTypeOption && operationTypeOptions && operationTypeOptions.length > 0) {
      const defaultOperationTypeOption = operationTypeOptions.find((elm) => elm.value === operationTypeOption);

      setValue("operationType", defaultOperationTypeOption);
    }
  }, [operationTypeOption, operationTypeAllData]);

  useEffect(() => {
    if (requirementLevelOption && requirementLevelOptions && requirementLevelOptions.length > 0) {
      const defaultRequirementLevel = requirementLevelOptions.find((elm) => elm.value === requirementLevelOption);

      if (defaultRequirementLevel) {
        setValue("requirementLevel", defaultRequirementLevel);
      }
    }
  }, [requirementLevelOption, requirementLevelOptions]);

  useEffect(() => {
    currentCustomer && fetchOperationTypes(currentCustomer);
  }, [currentCustomer, fetchOperationTypes]);

  const handleOptionClick = (selectedOption) => {
    setValue("rewardFormat", selectedOption.value);
    setExpanded(false);
  };

  const renderInputField = (name, label, error, errorMessage) => (
    <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
      <Input
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        name={name}
        variant="outlined"
        fullWidth
        label={label}
        error={error}
      />
      <FormHelperText className={classes.textError} error>
        {errorMessage}
      </FormHelperText>
    </Grid>
  );

  const renderFieldsBasedOnFormat = () => {
    return (
      <>
        {renderInputField(
          "targetMileage",
          "Meta de km rodado por mês *",
          errors.targetMileage && true,
          errors.targetMileage?.message
        )}

        {rewardFormat !== "range" && (
          renderInputField(
            "maxAmount",
            "Premiação máx por usuário *",
            errors.maxAmount && true,
            errors.maxAmount?.message
          )
        )}

        {rewardFormat === "scoreMinimunReward" && (
          <PermissionsGate scopes={['can_create_minscore', 'can_edit_minscore']}>
            {renderInputField(
              "minScore",
              "Nota mínima de avaliação diária  *",
              errors.minScore && true,
              errors.minScore?.message
            )}
          </PermissionsGate>
        )}

        {rewardFormat === "scoreMediumReward" && (
          <PermissionsGate scopes={['can_create_minscore', 'can_edit_minscore']}>
            {renderInputField(
              "minProportionalScore",
              "Nota mínima proporcional *",
              errors.minProportionalScore && true,
              errors.minProportionalScore?.message
            )}
          </PermissionsGate>
        )}

        {["scoreMinimunReward", "scoreMediumReward"].includes(rewardFormat) && (
          <PermissionsGate scopes={['can_create_minscore', 'can_edit_minscore']}>
            {renderInputField(
              "minMileage",
              "Km mínimo rodado por mês",
              errors.minMileage && true,
              errors.minMileage?.message
            )}
          </PermissionsGate>
        )}

        {rewardFormat === "range" && (
          <PermissionsGate scopes={['can_edit_range']}>
            <Grid item xs={12}>
              <RangeComponent />
            </Grid>
          </PermissionsGate>
        )}
      </>
    );
  };

  return !rewardFormat ? null : (
    <Aux>
      <Paper elevation={2} className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container item xs={12}>
              <Typography className={classes.title} data-cy="pGroupData">
                Dados do grupo
              </Typography>
              <Grid container item xs={12}>
                <Grid item xs={12} py={2} data-cy="divGroupName">
                  <Input
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Nome do grupo *"
                    error={errors.name}
                  />
                  <FormHelperText className={classes.textError} error>
                    {errors.name?.message}
                  </FormHelperText>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography className={classes.title}>
                  Selecione o formato de Premiação
                </Typography>
                <Grid item xs={12} py={2} data-cy="divGroupName">
                  <Accordion
                    className={classes.accordion}
                    expanded={expanded}
                    onChange={() => setExpanded(!expanded)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className={classes.accordionSummary}
                    >
                      <Typography
                        fontWeight={400}
                        variant="span"
                        className={classes.heading}
                      >
                        {rewardOptions.find(option => option.value === rewardFormat)?.label}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDrop}>
                      {rewardOptions.map((optionItem) => (
                        <PermissionsGate
                          key={optionItem.value}
                          scopes={optionItem.permissions}
                          disabledGate={!optionItem.permissions?.length}
                        >
                          <Box>
                            <Typography
                              onClick={() => handleOptionClick(optionItem)}
                              className={classes.rewardTypeTitle}
                            >
                              {optionItem.label}
                            </Typography>
                            <Typography
                              onClick={() => handleOptionClick(optionItem)}
                              className={classes.rewardTypeDescription}
                            >
                              {optionItem.description}
                            </Typography>
                          </Box>
                        </PermissionsGate>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <FormHelperText className={classes.textError} error>
                  {errors.rewardFormat?.message}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Typography className={classes.title}>
                Valores de meta e premiação
              </Typography>
              <Grid container item xs={12} spacing={3} py={2}>
                {renderFieldsBasedOnFormat()}
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Typography className={classes.title} data-cy="pTypeRatingAwards">
                Tipo de avaliação para premiação
              </Typography>
              <Grid container item xs={12} spacing={3} py={2}>
                <Grid item md={6} sm={12} xs={12} data-cy="divAwardGroup">
                  <Controller
                    control={control}
                    name="operationType"
                    render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                      <>
                        <StyledAutocomplete
                          {...rest}
                          options={operationTypeOptions}
                          renderInput={(params) => <TextField {...params} label="Grupo de premiação *" error={error} />}
                          value={value}
                          onChange={(_, value) => onChange(value)}
                          isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                          getOptionLabel={(option) => option?.label ?? ''}
                          renderOption={({ key, ...props }, option) => (
                            <Box key={option.value} component="li" {...props}>
                              <Typography>{option.label}</Typography>
                            </Box>
                          )}
                          disablePortal
                          noOptionsText="Nenhum grupo de premiação cadastrado"
                          error={error}
                        />
                        <FormHelperText className={classes.textError} error>
                          {error?.message}
                        </FormHelperText>
                      </>
                    )}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12} data-cy="divRequirement">
                  <Controller
                    control={control}
                    name="requirementLevel"
                    render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                      <>
                        <StyledAutocomplete
                          {...rest}
                          options={requirementLevelOptions}
                          renderInput={(params) => <TextField {...params} label="Exigência  *" error={error} />}
                          value={value}
                          onChange={(_, value) => onChange(value)}
                          isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                          getOptionLabel={(option) => option?.label ?? ''}
                          renderOption={({ key, ...props }, option) => (
                            <Box key={option.value} component="li" {...props}>
                              <Typography>{option.label}</Typography>
                            </Box>
                          )}
                          disablePortal
                          noOptionsText="Nenhuma exigência cadastrada"
                          disabled={isDisabledRequirementLevel}
                          error={error}
                        />
                        <FormHelperText className={classes.textError} error>
                          {error?.message}
                        </FormHelperText>
                      </>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <PermissionsGate scopes={['can_edit_extra_reward']}>
              <Grid container item xs={12}>
                <Grid item xs={12} py={2}>
                  <Controller
                    name="enabledExtraReward"
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      <FormControlLabel
                        control={<Checkbox {...rest} color="secondary" checked={enabledExtraReward} />}
                        label="Adicionar premiação extra"
                        className={classes.checkboxGroup}
                      />
                    )}
                  />
                </Grid>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={12} md={5}>
                    <Controller
                      name="extraTargetMileage"
                      control={control}
                      render={({ field: { ref, value = '', ...rest }, fieldState: { error } }) => (
                        <>
                          <Input
                            {...rest}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) event.preventDefault()
                            }}
                            fullWidth
                            value={value}
                            label="Mínimo de km para começar a ganhar o adicional"
                            disabled={!enabledExtraReward}
                            error={error}
                          />
                          <FormHelperText className={classes.textError} error>
                            {error?.message}
                          </FormHelperText>
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="extraRewardMileage"
                      control={control}
                      render={({ field: { ref, value = '', ...rest }, fieldState: { error } }) => (
                        <>
                          <Input
                            {...rest}
                            onKeyPress={(event) => {
                              if (!/^[\d.,]+$/.test(event.key)) event.preventDefault()
                            }}
                            fullWidth
                            value={value}
                            label="Valor a ser pago a cada X km"
                            disabled={!enabledExtraReward}
                            error={error}
                          />
                          <FormHelperText className={classes.textError} error>
                            {error?.message}
                          </FormHelperText>
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="extraIntervalMileage"
                      control={control}
                      render={({ field: { ref, value = '', ...rest }, fieldState: { error } }) => (
                        <>
                          <Input
                            {...rest}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) event.preventDefault()
                            }}
                            fullWidth
                            value={value}
                            label="Km a ser considerado na conta"
                            disabled={!enabledExtraReward}
                            error={error}
                          />
                          <FormHelperText className={classes.textError} error>
                            {error?.message}
                          </FormHelperText>
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
                <PermissionsGate scopes={['can_link_extra_reward_score']}>
                  <Grid item xs={12} display="flex" flexDirection="column" py={2}>
                    <Controller
                      name="considerScore"
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <FormControlLabel
                          control={<Checkbox {...rest} color="secondary" disabled={!enabledExtraReward} checked={considerScore} />}
                          label="Atrelar à nota"
                          className={classes.checkboxGroup}
                        />
                      )}
                    />
                    <span className={classes.hint}>
                      *Se habilitado, o valor será proporcional à nota do motorista e a premiação extra (ex: nota 80 = 80% do valor a receber a cada km rodado).
                    </span>
                  </Grid>
                </PermissionsGate>
              </Grid>
            </PermissionsGate>
          </Grid>
        </Grid>
      </Paper>
    </Aux>
  );
};

export default FormVehicleGroupComponent;
