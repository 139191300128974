import { Button, Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

import makeStyles from "./styles";

export const EvaluationCriteriaModal = ({ open, onClose }) => {
  const classes = makeStyles();

  const criteria = [
    "Nota da sua performance",
    "Km percorrida",
    "Nota do indicador inicio da faixa verde",
    "Nota do indicador motor ligado parado",
    "Nota do indicador aproveitamento de embalo",
    "Nota no indicador acelerando acima do verde",
    "Nota no indicador excesso de velocidade",
    "Nota do indicador pressão do acelerador",
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="divModalRanking"
      data-cy="divModalRanking"
      PaperProps={{
        style: { 
          borderRadius: 24,
          width: 492,
        }
      }}
    >
      <IconButton
        aria-label="close"
        className={classes.iconButton}
        onClick={onClose}
        size="large">
        <CloseIcon />
      </IconButton>
      <DialogContent className={classes.content}>
        <Typography variant="h4" className={classes.title}>
          Critérios de avaliação
        </Typography>
        <Typography className={classes.text}>
          Para a montagem do ranking, são avaliados os seguintes itens como critérios de desempate:
        </Typography>
        {criteria.map((criterion, index) => (
          <Typography key={criterion} className={classes.listItem}>
            {`${index + 1}. ${criterion}`}
          </Typography>
        ))}
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={onClose}
          className={classes.button}
          data-cy="buttonRankingModal"
        >
          Entendi
        </Button>
      </DialogContent>
    </Dialog>
  )
}