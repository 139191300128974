import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export function Dates({afterHour, afterMinute, afterSecond, beforeHour, beforeMinute, beforeSecond}){
  const dateNow = new Date()
  const dateAfter = new Date()
  const dateBefore = new Date()
  if(afterHour){
    dateAfter.setHours(dateAfter.getHours() + afterHour)
  }
  if(afterMinute){
    dateAfter.setMinutes(dateAfter.getMinutes() + afterMinute)
  }
  if(afterSecond){
    dateAfter.setSeconds(dateAfter.getSeconds() + afterSecond)
  }
  if(beforeHour){
    dateBefore.setHours(dateBefore.getHours() - beforeHour)
  }
  if(beforeMinute){
    dateBefore.setMinutes(dateBefore.getMinutes() - beforeMinute)
  }
  if(beforeSecond){
    dateBefore.setSeconds(dateBefore.getSeconds() - beforeSecond)
  }
  return{dateBefore,dateNow,dateAfter}
}

export const convertDateBr = (dateHour, seconds = false) => {
  if(!dateHour) return '';
  
  const options = {
    timeZone: 'America/Sao_Paulo',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };
  if (seconds) {
    options.second = 'numeric';
  }
  return new Date(dateHour).toLocaleString('pt-BR', options).replace(',', '');
}

export const convertHourMinBr = (dateHour) => {
  const options = {
    timeZone: 'America/Sao_Paulo',
    hour: 'numeric',
    minute: 'numeric'
  };
  return new Date(dateHour).toLocaleString('pt-BR', options).replace(',', '');
}

export const convertDateStringToDB = (dateHour) => {
  let date;

  if (dayjs(dateHour, 'DD/MM/YYYY HH:mm:ss', true).isValid()) {
    date = dayjs.tz(dateHour, 'DD/MM/YYYY HH:mm:ss', 'America/Sao_Paulo');
  } else if (dayjs(dateHour, 'DD/MM/YYYY HH:mm', true).isValid()) {
    date = dayjs.tz(dateHour, 'DD/MM/YYYY HH:mm', 'America/Sao_Paulo');
  } else {
    date = dayjs.tz(dateHour, 'America/Sao_Paulo');
  }

  if (!date.isValid()) {
    throw new Error("Invalid date format. Please use a valid date format.");
  }

  return date.format('YYYY-MM-DDTHH:mm:ssZZ');
};