import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFaqById, updateFaq } from "services/faq";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import FaqManager from "components/FaqManager";
import Swal from "sweetalert2";
import useStyles from "./styles";


const EditFaq = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [faqData, setFaqData] = useState(null);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const fetchFaq = async () => {
      try {
        const response = await getFaqById(id);
        if (response.data.success) {
          setFaqData(response.data.data);
        }
      } catch (error) {
        toast.error("Erro ao carregar FAQ");
      } finally {
        setLoading(false);
      }
    };
    fetchFaq();
  }, [id]);

  const onSubmit = async (data) => {
    try {
      const formattedData = {
        id: id,
        question: data.question,
        answer: data.answer,
        is_V3: data.v3 || false,
        is_App: data.app || false,
        tags: data.tags.map(tag => tag.value),
      };
      const response = await updateFaq(formattedData);
      if (response.data.success) {
        return response;
      } else {
        throw new Error(response.data.message || "Erro ao atualizar FAQ");
      }
    } catch (error) {
      toast.error("Erro ao editar FAQ");
      throw error;
    }
  };
  const handleEditFaq = async (data) => {
    Swal.fire({
      customClass: {
        popup: classes.modalContainer,
        title: classes.modalTitle,
        text: classes.modalText,
        confirmButton: classes.btnConfirm,
        cancelButton: classes.btnCancel,
      },
      title: "Tem certeza que deseja editar essa pergunta e resposta cadastrada?",
      text: "Essa ação é irreversível. Ao confirmar a exclusão, o item será permanentemente removido do FAQ (Perguntas Frequentes) da V3 e/ou do aplicativo dos motoristas.",
      reverseButtons: true,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, confirmar!",
      cancelButtonText: "Voltar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await onSubmit(data);
        Swal.fire({
          customClass: {
            popup: classes.modalContainer,
            title: classes.modalTitle,
            text: classes.modalText,
            confirmButton: classes.btnConfirm,
            cancelButton: classes.btnCancel,
          },
          title: "Pergunta e resposta editada com sucesso!",
          text: "Em breve, a pergunta e sua respectiva resposta estarão disponíveis na seção de FAQ (Perguntas Frequentes) do ambiente cadastrado.",
          reverseButtons: true,
          icon: "success",
          confirmButtonText: "Voltar para Gerenciamento",
        }).then(() => history.push("/gofurther/faq-list"));
      }
    });
  };

  const defaultValues = faqData ? {
    question: faqData.question,
    answer: faqData.answer,
    v3: faqData.isV3,
    app: faqData.isApp,
    tags: faqData.tags.map(tag => ({
      label: tag,
      value: tag
    }))
  } : {
    question: "",
    answer: "",
    v3: false,
    app: false,
    tags: []
  };

  if (loading) {
    return null;
  }

  return !loading ? (
    <FaqManager
      mode="edit"
      defaultValues={defaultValues}
      onSubmit={handleEditFaq}
      permissionsScope="can_edit_faq"
      title="Edição de perguntas FAQ"
      subtitle="Edite perguntas e respostas do FAQ na V3 e/ou aplicativo do motorista"
      submitLabel="Editar"
    />
  ) : null;
};

export default EditFaq;
