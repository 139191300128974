import React, { useState, useMemo, useCallback } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { debounce } from 'lodash';
import { virtualizedTable } from '../styles';

export function FilterPopover({ column, data, onFilterChange, currentFilters, onClose }) {
  const classes = virtualizedTable();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilters, setSelectedFilters] = useState(currentFilters[column.name] || []);

  const uniqueValues = useMemo(() => {
    return [...new Set(data.map(item => item[column.name]))].sort();
  }, [data, column.name]);

  const filteredValues = useMemo(() => {
    if (!searchTerm) return uniqueValues;
    return uniqueValues.filter(value =>
      value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [uniqueValues, searchTerm]);

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 1000),
    []
  );

  const handleSearchChange = (event) => {
    debouncedSearch(event.target.value);
  };

  const handleFilterToggle = useCallback((value) => {
    setSelectedFilters(prev =>
      prev.includes(value) ? prev.filter(v => v !== value) : [...prev, value]
    );
  }, []);

  const applyFilter = () => {
    onFilterChange(column.name, selectedFilters);
    onClose();
  };

  const clearFilter = () => {
    setSelectedFilters([]);
    onFilterChange(column.name, []);
    onClose();
  };

  return (
    <Box p={2} width={300}>
      <Typography variant="subtitle1">{column.label}</Typography>
      <TextField
        onChange={handleSearchChange}
        variant="outlined"
        size="small"
        fullWidth
        placeholder="Pesquisar..."
        margin="normal"
        sx={{
          height: '40px',
        }}
      />
      {
        filteredValues.length ? (
          <Box
            maxHeight={300}
            className={classes.scrollBox}
            sx={{
              overflowY: 'auto',
              my: 2,
              border: '1px solid #ccc',
            }}
          >
           {filteredValues.map((value) =>
            value ? (
              <FormControlLabel
                key={value}
                sx={{
                  mx: 0,
                  minWidth: '100%',
                }}
                control={
                  <Checkbox
                    checked={selectedFilters.includes(value)}
                    onChange={() => handleFilterToggle(value)}
                    className={classes.checkBox}
                  />
                }
                label={value?.toString()}
              />
            ) : null
          )}
          </Box>
        ) : (
          <Typography variant="h6" align="center" color="textSecondary">
            Não foram encontradas informações que correspondem ao seu filtro.
          </Typography>
        )
      }
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button variant="outlined" color="secondary" onClick={clearFilter}>
          Fechar
        </Button>
        <Button
          variant="contained"
          onClick={applyFilter}
          className={classes.primaryButton}
          disabled={!filteredValues.length}
        >
          Aplicar
        </Button>
      </Box>
    </Box>
  );
}
