import api from "./api";

export const getFaqs = async (term = '', limit = 10, page = 1,) => {
    try {
        const response = await api.get(`/web/v2/core/faqs?term=${term}&limit=${limit}&page=${page}`);
        return response
    } catch (err) {
        return err;
    }
};

export const createFaq = async (data) => {
    try {
        const response = await api.post(`/web/v2/core/faqs`, data);
        return response;
    } catch (err) {
        console.error('Erro ao criar FAQ:', err);
        throw err;
    }
};

export const updateFaq = async (data) => {
    try {
        const response = await api.put(`/web/v2/core/faqs/${data.id}`, data);
        return response;
    } catch (err) {
        console.error('Erro ao atualizar FAQ:', err);
        throw err;
    }
};

export const getFaqById = async (id) => {
    try {
        const response = await api.get(`/web/v2/core/faqs/${id}`);
        return response;
    } catch (err) {
        console.error('Erro ao buscar FAQ:', err);
        throw err;
    }
};

export const deleteFaq = async (id) => {
    try {
        const response = await api.delete(`/web/v2/core/faqs/${id}`);
        return response;
    } catch (err) {
        console.error('Erro ao deletar FAQ:', err);
        throw err;
    }
};