import * as yup from "yup";

const operationValidation = yup.object().shape({
  name: yup
    .string()
    .max(50, "Máximo de 50 caracteres excedido.")
    .required("Campo obrigatório"),
  targetMileage: yup
    .string()
    .max(14, "Kilometragem máxima excedida.")
    .required("Campo obrigatório"),
  maxAmount: yup
    .string()
    .when('rewardFormat', {
      is: 'range',
      then: yup
        .string()
        .notRequired(),
      otherwise: yup
        .string()
        .required("Campo obrigatório")
        .max(14, "Limite máximo excedido."),
    }),
  rewardFormat: yup.string().required("Campo obrigatório"),
  minProportionalScore: yup
    .string()
    .when('rewardFormat', {
      is: 'scoreMediumReward',
      then: yup.string().required('Campo obrigatório').max(14, "Limite máximo excedido."),
      otherwise: yup.string().notRequired()
    }),
  minScore: yup
    .string()
    .when('rewardFormat', {
      is: 'scoreMinimunReward',
      then: yup.string().required('Campo obrigatório').max(14, "Limite máximo excedido."),
      otherwise: yup.string().notRequired()
    }),
  rewardRange: yup
    .array()
    .of(
      yup.object().shape({
        range: yup
          .string()
          .matches(/^\d+-\d+$/, "A faixa deve conter dois números separados por um hífen (Ex: 0-69).")
          .required("A faixa é obrigatória."),
        reward: yup
          .number()
          .typeError("O valor deve ser numérico.")
          .required("O valor é obrigatório."),
      })
    )
    .when('rewardFormat', {
      is: 'range',
      then: yup.array().min(1, "Deve haver pelo menos uma faixa de premiação").required('Deve haver pelo menos uma faixa de premiação'),
      otherwise: yup.array().notRequired(),
    }),
  operationType: yup
    .object()
    .shape({
      value: yup.number().required(),
      label: yup.string().required(),
    })
    .nullable()
    .required("Campo obrigatório"),
  requirementLevel: yup
    .object()
    .shape({
      value: yup.number().required(),
      label: yup.string().required(),
      type: yup.string().required(),
    })
    .nullable()
    .required("Campo obrigatório"),
  enabledExtraReward: yup
    .bool(),
  extraTargetMileage: yup
    .number()
    .when('enabledExtraReward', {
      is: true,
      then: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
      otherwise: yup.number().typeError('Campo obrigatório')
    }),
  extraRewardMileage: yup
    .number()
    .when('enabledExtraReward', {
      is: true,
      then: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
      otherwise: yup.number().typeError('Campo obrigatório')
    }),
  extraIntervalMileage: yup
    .number()
    .when('enabledExtraReward', {
      is: true,
      then: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
      otherwise: yup.number().typeError('Campo obrigatório')
    }),
  considerScore: yup
    .bool(),
  minMileage: yup.number()
    .nullable()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    ),
});

export default operationValidation;
