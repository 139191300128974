import React from "react";
import { Typography } from "@mui/material";
import classnames from "classnames";
import makeStyles from "./styles";

export const makeColumnsForPrint = () => {
  const classes = makeStyles();

  const getCell = (data, textBold) => {
    return (
      <Typography
        variant="body2"
        className={classnames({
          [classes.textBold]: textBold,
        })}
        noWrap={true}
      >
        {data || '-'}
      </Typography>
    );
  };

  return [
    {
      name: "plate",
      label: "Placa",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "chassi",
      label: "Chassi",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
  ];
};