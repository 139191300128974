import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fields: {
    step: null
  },
  filters: {
    selectedDates: {
      initialDate: null,
      finishDate: null,
    },
    vehicles: null,
    drivers: null
  },
  driverDashboardData: {
    vehicles: "",
    initialDate: "",
    finalDate: "",
  }
};

export const driverDashboardSlice = createSlice({
  name: 'driverDashboard',
  initialState,
  reducers: {
    setVehicleDriverFilters: (state, action) => {
      state.filters = action.payload;
    },
    clearVehicleDriversFilters: (state) => {
      state.fields.step = null;
      state.filters = initialState.filters
    },
    setVehicleStepAdd: (state) => {
      if (!state?.fields.step) {
        state.fields.step = 1
      } else {
        state.fields.step = state.fields.step + 1
      }
    },
    setVehicleStepDeduct: (state) => {
      state.fields.step = state.fields.step - 1
    },
    resetVehicleStep: (state) => {
      state.fields = initialState.fields
    },
    setDriverDashboardData: (state, action) => {
      state.driverDashboardData = action.payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  clearVehicleDriversFilters,
  setVehicleDriverFilters,
  setVehicleStepAdd,
  setVehicleStepDeduct,
  resetVehicleStep,
  setDriverDashboardData
} = driverDashboardSlice.actions;

export default driverDashboardSlice.reducer;
