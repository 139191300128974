import React, { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom";
import { Box, Grid, Button } from "@mui/material";
import { toast } from "react-toastify";
import { useAppSelector } from "store";

// services
import { getUserById, getUnassignVehiclesUserByCustomersIDs } from "services/user";

// components
import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import VirtualizedTable from "components/VirtualizedTable/VirtualizedTable";
import PermissionsGate from "components/PermissionsGate";
import Widget from "components/Widget/Widget";
import { makeColumns } from "./tableColumns";
import { makeColumnsForPrint } from "./tableColumnsForPrint";
import TruckLoader from "components/TruckLoader";
import UpdateVehiclesDialog from "./fragments/update-vehicles-dialog";

// styles
import useStyles from "./styles";


const AssignVehiclesUser = () => {
  const { userID } = useParams();
  const classes = useStyles()
  const [loading, setLoading] = useState(true);
  const vehiclesUserList = useRef([]);
  const userEmail = useRef([]);
  const alreadyLinkedVehicles = useRef([]);
  const plateFromSelectedVehicles = useRef([]);
  const plateFromRemovedVehicles = useRef([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [vehiclesFiltered, setVehiclesFiltered] = useState([]);
  const columns = makeColumns(selectedVehicles, setSelectedVehicles, vehiclesFiltered);
  const columnsForPrint = makeColumnsForPrint();
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const [isModalOpen, setModalOpen] = useState(false);
  const title = "Vínculo usuário com veículos";
  
  const handleOpen = () => setModalOpen(true);
  
  const handleClose = (callAgain = false) => {
    if (typeof callAgain !== "boolean") {
      callAgain = false;
    }
    setModalOpen(false);
    if (callAgain) {
      fetchAssignVehiclesUserList(currentCustomer);
      setSelectedVehicles([]);
    }
  };

  const fetchAssignVehiclesUserList = async () => {
    try {
      setLoading(true);
      const userResponse = await getUserById(userID);
      if (userResponse.status !== 200) {
        throw new Error(response);
      }
      const { user } = userResponse.data.data;
      userEmail.current = user.email;
      const response = await getUnassignVehiclesUserByCustomersIDs(user.email, user.customerId);
      if (response.status !== 200) {
        throw new Error(response);
      }
      const linkedVehicles = response.data.data.linked.map((vehicle) => vehicle.id);
      vehiclesUserList.current = [...response.data.data.linked, ...response.data.data.available];
      alreadyLinkedVehicles.current = response.data.data.linked;
      linkedVehicles.length > 0 && setSelectedVehicles(linkedVehicles);
    } catch (error) {
      toast.error(
        "Erro ao carregar lista de veículos do usuário. Entre em contato com o suporte!",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    vehiclesUserList.current.length === 0 && fetchAssignVehiclesUserList(currentCustomer)
  }, [currentCustomer])

  const checkUpdates = () => {
    plateFromRemovedVehicles.current = alreadyLinkedVehicles.current
      .filter(vehicle => !selectedVehicles.includes(vehicle.id))
      .map(vehicle => vehicle.plate);

    plateFromSelectedVehicles.current = vehiclesUserList.current
      .filter(
        (vehicle) => 
          selectedVehicles.includes(vehicle.id) && 
          !alreadyLinkedVehicles.current.some(linked => linked.id === vehicle.id) 
      )
      .map((vehicle) => vehicle.plate);

    if (plateFromRemovedVehicles.current.length > 0 || plateFromSelectedVehicles.current.length > 0) {
      return false;
    }

    return true;
  }

  return (
    <Aux>
      <PermissionsGate scopes={['can_edit_assign_user_vehicles']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <Widget disableWidgetMenu title={title}>
              {loading ? (
                <TruckLoader />
              ) : (
                <Box height="600px">
                  <VirtualizedTable
                    data={vehiclesUserList.current}
                    columns={columns}
                    tableName="user-vehicles"
                    initialRowsPerPage={100}
                    setFiltered={setVehiclesFiltered}
                    excludedForFilterColumns={['id']}
                    tableTitle={title}
                    columnsForPrint={columnsForPrint}
                  />
                </Box>
              )}
            </Widget>
            <Grid container item justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <BackButton className={classes.backButton} />
                </Box>
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Button
                    fullWidth
                    color="secondary"
                    className={classes.updateButton}
                    disabled={checkUpdates()}
                    variant="contained"
                    onClick={handleOpen}
                    data-cy="buttonUpdate" 
                  >
                    Atualizar vínculos
                  </Button>
                 {!checkUpdates() && 
                    <UpdateVehiclesDialog 
                      isOpen={isModalOpen} 
                      onClose={handleClose} 
                      selectedVehicles={selectedVehicles} 
                      userEmail={userEmail.current}
                      plateFromSelectedVehicles={plateFromSelectedVehicles.current}
                      plateFromRemovedVehicles={plateFromRemovedVehicles.current}
                    />
                  }
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  )
}

export default AssignVehiclesUser