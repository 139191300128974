import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import makeAnimated from "react-select/animated";
import Select from "react-select";

// components
import Calendar from "../Calendar";
import OperationFilters from "../OperationFilters";
import SelectMonth from "../SelectMonth";
import MultiSelect from "../MultiSelect";
import { getYearsOptions } from "helpers/operations";
import { useWindowSize } from "hooks/useWindowsSize";
import DriverPerformanceDetailComponent from "components/DriverPerformanceDetailComponent";
import VehiclePerformanceDetailComponent from "components/VehiclePerformanceDetailComponent";

// styles
import useStyles from "./styles";
import { colourStyles } from "./colourStyles";

const PageToolbar = React.memo(({
  calendarMaxRanges,
  calendarMonthValue,
  isCalendarMonthDisabled,
  currentYear,
  detailedSelect = false,
  isDetail,
  isEmission,
  isSingleMode,
  handleSelectDate,
  handleSelectedData,
  handleSelectedOperation,
  handleYear,
  listData,
  loading = false,
  menuCalendar,
  menuOperations,
  selectedData,
  selectedDates,
  vehiclesMenuButton,
  driversMenuButton,
  vehicleProps,
  title = '',
  isAllSelected = false,
  initDebounce = true,
  driverSelectData
}) => {
  const classes = useStyles();
  const yearsOptions = getYearsOptions();
  const animatedComponents = makeAnimated();
  const size = useWindowSize();
  const handleCalendar = (startDate, endDate) => {
    handleSelectDate(startDate, endDate);
  };

  const renderPerformanceDetail = () => {
    if (!isDetail || !vehicleProps) return null;

    const Component = vehicleProps.screen === "map"
      ? VehiclePerformanceDetailComponent
      : DriverPerformanceDetailComponent;

    return (
      <Grid item xs={12} sm={12} xl={12} lg={12} className={classes.spacingContainer}>
        <Component vehicleProps={vehicleProps} />
      </Grid>
    );
  };

  return (
    <div>
      <Grid
        container
        data-cy="divGrupoSelects"
        spacing={1}
        direction="row"
        justifyContent="space-around"
        className={classes.pageToolbarContainer}
      >
        {title && (
          <Grid
            item
            xs={12}
            sm={3}
            container>
            <Typography variant="h5" align={'left'} className={classes.title}>{title}</Typography>
          </Grid>
        )}
        {vehiclesMenuButton && (
          <Grid item xs={12} sm={title ? 4 : 6} className={classes.spacingContainer}>
            <Paper elevation={0} className={classes.multiselect}>
              <MultiSelect
                isDetail={isDetail}
                isSingleMode={isSingleMode}
                listData={listData}
                handleSelectedListData={handleSelectedData}
                selectedData={selectedData}
                vehicleProps={vehicleProps}
                detailedSelect={detailedSelect}
                placeholder={detailedSelect && 'Digite o id da unidade, nome ou placa da frota, ou nome do motorista'}
                loading={loading}
                data-cy="divSelectFrota"
                isAllSelected={isAllSelected}
                initDebounce={initDebounce}
              />
            </Paper>
          </Grid>
        )}
        {driversMenuButton && (
          <Grid item xs={12} sm={title ? 4 : 6} className={classes.spacingContainer}>
            <Paper elevation={0} className={classes.multiselect}>
              <Select
                data-cy="divSelectMotorista"
                placeholder="Selecione um motorista"
                options={listData.map((item) => ({ value: item.id, label: item.name }))}
                value={driverSelectData}
                onChange={handleSelectedData}
                styles={{
                  ...colourStyles,
                  control: base => ({
                    ...base,
                    borderRadius: 20,
                    border: 'none',
                    padding: '5px 20px',
                    '&:hover': {
                      border: 'none'
                    }
                  }),
                  menuPortal: base => ({
                    ...base,
                    zIndex: 9999
                  })
                }}
              />
            </Paper>
          </Grid>
        )}

        {renderPerformanceDetail()}

        {menuOperations && (
          <Grid item xs={12} sm={12} className={classes.spacingContainer}>
            <OperationFilters
              handleCalendar={handleCalendar}
              handleSelectedOperation={handleSelectedOperation}
            />
          </Grid>
        )}
        {(menuCalendar && !isDetail) && (
          <Grid item xs={12} sm={title ? 5 : 6} className={classes.spacingContainer}>
            <Paper elevation={0} className={classes.multiselect}>
              <Calendar
                selectedDates={selectedDates}
                handleCalendar={handleCalendar}
                calendarMaxRanges={calendarMaxRanges}
              />
            </Paper>
          </Grid>
        )}

        {isEmission && (
          <>
            <Grid
              item
              xl={size.grid3}
              lg={size.grid3}
              md={size.grid3}
              sm={size.grid3}
              xs={size.grid3}
              style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
            >
              <Paper elevation={0} className={classes.paperSelect} data-cy="divSelectAno">
                <Select
                  name="year"
                  isSearchable={!size.mobile}
                  placeholder="Ano"
                  options={yearsOptions.reverse()}
                  styles={{ ...colourStyles, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  onChange={handleYear}
                  value={currentYear}
                  defaultValue={currentYear}
                  components={animatedComponents}
                />
              </Paper>
            </Grid>
            <Grid
              item
              xl={size.grid3}
              lg={size.grid3}
              md={size.grid3}
              sm={size.grid3}
              xs={size.grid3}
              style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
            >
              <SelectMonth
                data-cy="divSelectMes"
                title="Mês"
                value={calendarMonthValue}
                onSelectDate={handleSelectDate}
                disabled={isCalendarMonthDisabled}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
});

export default PageToolbar;
