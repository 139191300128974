import React from "react";
import { IconButton, Grid, Tooltip } from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

export const makeColumns = ({ onDelete, onEdit, size }) => {
  let columns = [
    {
      name: "question",
      label: "Pergunta",
      options: {
        sort: false,
      }
    },
    {
      name: "answer",
      label: "Resposta",
      options: {
        sort: false,
      }
    },
    {
      name: "tags",
      label: "Tags",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return value && value.length ? value.map((tag) => `${tag}`).join(", ") : 'Nenhuma tag';
        }
      }
    }
  ];

  if (!size.mobile && hasPermission({ scopes: ['can_view_faq', 'can_edit_faq', 'can_remove_faq'] })) {
    columns.push({
      name: "id",
      label: "Ações",
      options: {
        sort: false,
        print: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <Grid container style={{ minWidth: "140px" }}>
              <PermissionsGate scopes={['can_edit_faq']}>
                <Grid container item xs={4} justifyContent="center">
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="Editar"
                    size="large">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_faq']}>
                <Grid container item xs={4} justifyContent="center">
                  <IconButton
                    onClick={() => onDelete(value)}
                    aria-label="delete"
                    title="Excluir"
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }

  return columns;
};
