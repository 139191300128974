import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Alert } from "@material-ui/lab";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { mapValues, toArray } from "lodash";
import { Link, useHistory, useLocation } from "react-router-dom";
import sign from "jwt-encode";
import classnames from "classnames";

import { generateRandomBase64 } from 'helpers/token';

// hoc
import Aux from "hoc/auxiliar";

// components
import CardsSkeleton from "components/Skeletons/CardsSkeleton";
import FleetPerformance from "components/FleetPerformance";
import GForce from "components/GForce";
import HeaderDetails from "componentsNew/molecules/headerDetails/headerDetails";
import Map from "components/Map";
import MapSkeleton from "components/Skeletons/MapSkeleton";
import ModalFleetChart from "components/ModalFleetChart";
import FiltersPanel from "./fragments/filters-panel/filters-panel";
import PerformanceComponent from "components/PerformanceComponent";
import PerformanceSkeleton from "components/Skeletons/PerformanceSkeleton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import Pressure from "components/Pressure";
import PressureSkeleton from "components/Skeletons/PressureSkeleton";
import StatisticsCards from "components/StatisticsCards";
import { ValidationMessageComponent } from "components/ValidationMessageComponent";
import NotFoundInfo from "components/NotFoundInfo";
import NoDataBox from "components/NoDataBox";

// services
import {
  getFleetPercentages,
  getFleetLocations,
  getFleetChartData,
  getVehicleProfile,
  getVehiclesPerformancePack,
  getFleetStatistics,
} from "services/fleetPerformance";
import {
  getPressureGasPedal,
  getVehiclesByOperation,
  getVehicleValidation,
} from "services/vehicle";
import { getOperationById } from "services/operations";
import { getOperationTypeById } from "services/operationType";
import { getDrivers, getDriverVehiclesByPeriod } from "services/driver";

// helpers
import { getNameIndicator } from "helpers/indicators";
import { parseChartData } from "helpers/fleetChart";
import { allRequirementLevelOptions, indicatorsAndPenalizingOptionsConcat } from "domain/selectOptions";
import { convertDateStringToDB } from "helpers/dates";
import { orderByIndex, findValueByKey } from "helpers/functions";
import { orderVehiclesByCrescent } from "helpers/vehicles";

// context
import { useLayoutState } from "context/LayoutContext";
import { useAppDispatch, useAppSelector } from "store";
import {
  setStepAdd,
  setPrintData,
  setStepDeductBonus,
} from "store/features/bonusFollowSlice";
import { useWindowSize } from "hooks/useWindowsSize";
import { setStepDeduct } from "store/features/driversMonitoringSlice";
import {
  setVehicleDriverFilters,
  setVehicleStepAdd,
  setDriverDashboardData,
} from "store/features/driverDashboardSlice";

// styles
import useStyles from "./styles";

// icons
import dayjs from "dayjs";

export default function DriverDashboard() {
  const dispatch = useAppDispatch();
  const size = useWindowSize();
  const classes = useStyles();
  const layoutState = useLayoutState();
  const history = useHistory();

  const maxDate = new Date();
  maxDate.setHours(23, 59, 59, 0);
  const dateFormatTemplate = "yyyy-MM-dd'T'HH:mm:ssXX";
  const dateFormatTemplateOnly = "dd/MM/yyyy";
  const timeFormatTemplateOnly = "HH:mm:ss";

  const { state } = useLocation();
  const { filters } = useAppSelector((state) => state.driverDashboard);
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const { driverDashboardData: dataToRequestOfChart } = useAppSelector((state) => state.driverDashboard);

  const [consumption, setConsumption] = useState({
    idleConsumption: "",
    movementConsumption: "",
  });
  const [showValidationComponent, setShowValidationComponent] = useState(false);
  const [vehicleValidationMessage, setVehicleValidationMessage] = useState("");
  const [pressureGasPedal, setPressureGasPedal] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [indicatorsByOperation, setIndicatorsByOperation] = useState([]);
  const [indicatorsPerformance, setIndicatorsPerformance] = useState([]);
  const [indicatorsPercentage, setIndicatorsPercentage] = useState([]);
  const [indicatorsPressure, setIndicatorsPressure] = useState([]);
  const [locations, setLocations] = useState([]);
  const [locationsUnique, setLocationsUnique] = useState(false);
  const [statistics, setStatistics] = useState([]);
  const [summary, setSummary] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [showFleetChart, setShowFleetChart] = useState(false);
  const [throttlePressureScore, setThrottlePressureScore] = useState(0);
  const [hasThrottlePressureScore, setHasThrottlePressureScore] = useState(false);
  const [showThrottleGraphPressureScore, setShowThrottleGraphPressureScore] = useState(false);
  const [gForce, setGForce] = useState(null);
  const [penalty, setPenalty] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState();
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [multiSelectVehicles, setMultiSelectVehicles] = useState([]);
  const [selectedDates, setSelectedDates] = useState(null);
  const [alertDateDivergent, setAlertDateDivergent] = useState(null);
  const [segment, setSegment] = useState(null);
  const [showNoData, setShowNoData] = useState(false);
  const canViewDashboardGForce = hasPermission({ scopes: ["can_view_dashboard_gforce"] });

  const [loading, setLoading] = useState({
    chart: true,
    drivers: true,
    indicators: true,
    vehicles: true,
    locations: true,
    percentages: true,
    statistics: true,
    gForce: true,
  });
  const [searchParams, setSearchParams] = useState({
    dates: {},
    vehicles: [],
  });

  const noIndicatorsChartToShow = () => {
    toast.warning(
      "Sem dados para exibir, tente buscar outro período ou frota para análise.",
    );
  };

  const clearAllStates = () => {
    setMultiSelectVehicles([]);
    setSelectedVehicles([]);
    setShowNoData(false);
    setIndicators([]);
    setIndicatorsByOperation([]);
    setIndicatorsPerformance([]);
    setIndicatorsPercentage([]);
    setIndicatorsPressure([]);
    setLocations([]);
    setLocationsUnique(false);
    setStatistics([]);
    setSummary([]);
    setChartData([]);
    setThrottlePressureScore(0);
    setHasThrottlePressureScore(false);
    setShowThrottleGraphPressureScore(false);
    setGForce(null);
    setPenalty(false);
    setSegment(null);
    setShowValidationComponent(false);
    setVehicleValidationMessage("");
    setAlertDateDivergent(null);
  };

  const handleFilterChange = async ({
    driver = null,
    dates = null,
    vehicles = null
  }) => {
    setShowValidationComponent(false);
    setSegment(null);
    if (vehicles !== null) setSelectedVehicles(vehicles);
    if (driver !== null) setSelectedDriver(driver);
    if (dates !== null) {
      setSelectedDates({
        initialDate: format(dates.initialDate, dateFormatTemplate),
        finalDate: format(dates.finalDate, dateFormatTemplate),
      });
    }
    
    const updatedDriver = driver ?? selectedDriver;
    const updatedDates = dates ?? selectedDates;
  
    const initialDate = format(new Date(updatedDates?.initialDate || filters?.selectedDates?.initialDate), dateFormatTemplate);
    const finalDate = format(new Date(updatedDates?.finalDate || filters?.selectedDates?.finish), dateFormatTemplate);

    if (vehicles) {
      setSelectedVehicles(vehicles);
      fetchSearchData(vehicles, initialDate, finalDate, updatedDriver);
      return;
    }
  
    if (updatedDriver?.value) {
      try {
        clearAllStates();
        fetchVehicles(updatedDriver, initialDate, finalDate);
      } catch (error) {
        console.error("Erro ao validar configurações do veículo:", error);
        toast.error(
          "Erro ao validar configurações do veículo. Por favor, entre em contato com o suporte."
        );
      }
    }
  };

  const handleSelectedVehicles = (data) => {
    if (data?.length === 0) return;
    handleFilterChange({ vehicles: data });
  };

  // O action vem do Pagetoolbar e não pode ser removido no momento
  const handleSelectDriver = async (driver, action, startDate, finishDate) => {
    if (!driver) return;
    handleFilterChange({ driver });
  };

  const handleSelectDate = async (initialDate, finalDate) => {
    if (!initialDate || !finalDate) return;
    handleFilterChange({ dates: { initialDate, finalDate } });
  };

  const fetchDrivers = async () => {
    setLoading((oldState) => ({ ...oldState, drivers: true }));
    try {
      const response = await getDrivers(currentCustomer);

      const { drivers } = response.data;
      
      if (!!drivers?.length) {
        const orderedDrivers = orderByIndex(drivers, 'name');
        setDrivers(orderedDrivers);
      }
    } catch (error) {
      toast.warning(
        "Erro ao carregar lista de motoristas. Entre em contato com o suporte.",
      );
    } finally {
      setLoading((oldState) => ({ ...oldState, drivers: false }));
    }
  };

  const fetchVehicles = async (driver, initialDate, finalDate) => {
    setLoading((oldState) => ({ ...oldState, vehicles: true }));
    try {
      const getDriverVehiclesResponse = await getDriverVehiclesByPeriod(driver?.value, initialDate, finalDate);
      if (getDriverVehiclesResponse.status !== 200 || !getDriverVehiclesResponse?.data?.length) {
        return;
      }
      
      const getVehiclesByOperationResponse = await getVehiclesByOperation(currentCustomer);
      const fetchedDriverVehicles = getDriverVehiclesResponse.data;
      const fetchedDriverVehiclesIds = fetchedDriverVehicles.map(({ vehicleId }) => vehicleId);

      const fetchedOperations = getVehiclesByOperationResponse.data.customers;
      const validVehicleIds = new Set(fetchedDriverVehicles.map(v => v.vehicleId));

      const filteredOperations = fetchedOperations
        .map(operation => {
            const filteredVehicles = operation.vehicles.filter(vehicle => validVehicleIds.has(vehicle.id));
            return filteredVehicles.length ? { ...operation, vehicles: filteredVehicles } : null;
        })
        .filter(Boolean); 

      const operationVehicles = fetchedOperations.reduce((acc, curr) => [
        ...acc,
        ...curr.vehicles.map((vehicle) => ({
          ...vehicle,
          operationId: curr.id,
          operation: {
            id: curr.id,
            name: curr.name
          }
        }))
      ], []);

      const filteredOperationVehicles = operationVehicles.filter(operationVehicle => fetchedDriverVehiclesIds.includes(operationVehicle.id));
      if (filteredOperationVehicles?.length === 0 || !filteredOperationVehicles) {
        toast.warning(
          `O veículo selecionado não está mais disponível.
          Isso pode ter ocorrido devido a uma atualização na base de dados. 
          Por favor, selecione outro veículo ou entre em contato com o suporte para mais informações.`,
        );
        setShowNoData(true);
        return;
      }

      let orderedVehiclesByCustomer = filteredOperations.sort(
        (a, b) => (a.name > b.name) - (a.name < b.name)
      );

      orderedVehiclesByCustomer.map((option, i) => {
        const optionVehicles = option?.vehicles
          ?.filter(item => item?.devicesHistory !== null)
          ?.filter(item => item?.currentDevice?.identification !== '');

        orderedVehiclesByCustomer[i].vehicles = orderVehiclesByCrescent(optionVehicles);
      });

      setMultiSelectVehicles(orderedVehiclesByCustomer);
    } catch (error) {
      toast.warning(
        "Erro ao carregar lista de veículos. Entre em contato com o suporte.",
      );
    } finally {
      setLoading((oldState) => ({ ...oldState, vehicles: false }));
    }
  };

  const fetchSearchData = async (vehicleList, initialDate, finalDate, driver) => {
    const vehiclesIds = vehicleList.map(vehicleItem => vehicleItem.id);
    handleNoDataMessageComponent(vehiclesIds, initialDate, finalDate);

    await Promise.all([
      fetchVehiclePerformances(vehiclesIds, initialDate, finalDate, driver?.value),
      fetchPercentages(vehiclesIds, initialDate, finalDate, driver?.value),
      fetchStatistics(vehiclesIds, initialDate, finalDate, driver?.value),
      fetchLocations(vehiclesIds, initialDate, finalDate),
      fetchIndicatorsByOperation(vehicleList),
      fetchPressure(vehiclesIds)
    ]);
  };

  const fetchVehiclePerformances = async (vehicles, initialDate, finalDate, driverId) => {
    try {
      setLoading((oldState) => ({
        ...oldState,
        lastPack: true,
        indicators: true,
      }));

      const responseData = await getVehiclesPerformancePack(
        vehicles,
        initialDate,
        finalDate,
        driverId
      );

      if(responseData.status !== 200) return;
      
      const { performances = [] } = responseData.data?.data;

      if (!performances?.length || !performances) {
        toast.warning("Não há data da última comunicação com o veículo.");

        return;
      }

      const performancesTotal = performances.reduce((acc, curr) => {
        const { indicators } = curr;

        return {
          ...acc,
          score: curr.score + acc.score,
          hasThrottlePenalizer: indicators.hasThrottlePenalizer ?? acc.hasThrottlePenalizer,
          showThrottleGraph: indicators.showThrottleGraph ?? acc.showThrottleGraph,
          throttlePressureScore: indicators.throttlePressureScore + acc.throttlePressureScore,
        };
      }, {
        score: 0,
        hasThrottlePenalizer: false,
        showThrottleGraph: false,
        throttlePressureScore: 0,
      });

      const averageThrottlePressureScore = performancesTotal.throttlePressureScore / performances.length;
      const averageScore = performancesTotal.score ? Math.ceil(performancesTotal.score / performances.length) : 0;

      setHasThrottlePressureScore(performancesTotal.hasThrottlePenalizer);
      setShowThrottleGraphPressureScore(performancesTotal.showThrottleGraph);
      setThrottlePressureScore(averageThrottlePressureScore);

      const formattedIndicators = performances.map((performance) => Object.entries(performance.indicators).reduce((acc, { 0: key, 1: value }) => ({
        ...acc,
        [key]: {
          ...value,
          name: key,
          title: getNameIndicator(`${key}Score`)
        }
      }), {}));

      const formattedIndicatorsTotal = formattedIndicators.reduce((acc, curr) => {
        Object.keys(curr).forEach(key => {
          if (!acc[key]) {
            acc[key] = {
              ...curr[key],
              score: 0,
              percentage: 0,
              duration: 0,
              totalKm: 0,
            };
          }

          if (typeof curr[key].score === 'number') {
            acc[key].score += curr[key].score;
          }

          if (typeof curr[key].percentage === 'number') {
            acc[key].percentage += curr[key].percentage;
          }

          if (typeof curr[key].duration === 'number') {
            acc[key].duration += curr[key].duration;
          }

          if (typeof curr[key].totalKm === 'number') {
            acc[key].totalKm += curr[key].totalKm;
          }
        });

        return acc;
      }, {});

      const formattedIndicatorsAverages = Object.entries(formattedIndicatorsTotal).reduce((acc, { 0: key, 1: value }) => {
        const updatedValue = {
          ...value,
          score: Math.ceil(value.score / performances.length),
          percentage: value.percentage / performances.length,
        };

        return {
          ...acc,
          [key]: updatedValue
        }
      }, {});

      setIndicatorsPerformance(formattedIndicatorsAverages);

      const firstPerformance = performances[0];

      // Considers only the first vehicle/performance
      setConsumption({
        idleConsumption: firstPerformance.indicators.idle.fuelConsumption,
        movementConsumption: firstPerformance.indicators.movement?.fuelConsumption ?? []
      });

      setSummary({ globalScore: averageScore });

      const firstPerformanceSegment = firstPerformance?.segments;

      if (Array.isArray(firstPerformanceSegment) && !!firstPerformanceSegment.length) {
        // Considers only the first vehicle/performance
        const firstVehicleStartSegment =
          firstPerformance.segments[0]?.start_date
            ? dayjs(
              new Date(firstPerformance.segments[0]?.start_date)
            )
              .format('DD/MM/YYYY HH:mm')
            : ' - ';

        const firstVehicleEndSegment =
          firstPerformance.segments[0]?.end_date
            ? dayjs(
              new Date(firstPerformance.segments.at(-1)?.end_date)
            )
              .format('DD/MM/YYYY HH:mm')
            : ' - ';

        setSegment(`${firstVehicleStartSegment} até ${firstVehicleEndSegment}`);
      }
    } catch (error) {
      setSegment(null);
      setConsumption({
        idleConsumption: "",
        movementConsumption: "",
      });
      setHasThrottlePressureScore(0);
      setShowThrottleGraphPressureScore(false);
      setThrottlePressureScore(false);
      toast.error(
        "Erro ao buscar data de última comunicação. Contate o administrador.",
      );
    } finally {
      setLoading((oldState) => ({
        ...oldState,
        lastPack: false,
        indicators: false,
      }));
    }
  };

  const fetchIndicatorsByOperation = async (vehicleList) => {
    if (!!vehicleList.length) {
      try {
        const operation = findValueByKey(vehicleList, 'operation');

        if (!operation) return;

        const response = await getOperationById(operation?.id);

        const { operationType, requirementLevel } = response.data.data.operation;

        const responseOperation = await getOperationTypeById(operationType);

        const level = allRequirementLevelOptions.find(
          (o) => o.value === requirementLevel,
        );

        const operationsName = [];

        const penaltyTypes = ["lowPressure", "medPressure", "higPressure"];

        setPenalty(false);

        const operationIndicators = responseOperation.data.operationTypes.indicators;

        operationIndicators.forEach((indicator) => {
          if (indicator.requirement_level === level?.type) {
            let indicatorName = indicatorsAndPenalizingOptionsConcat.filter(
              (o) => o.value === indicator.indicator_id,
            );

            operationsName.push(indicatorName[0].type);

            if (penaltyTypes.includes(indicatorName[0].type) && indicator?.penalizing) {
              setPenalty(true);
            }
          }
        });
        setIndicatorsByOperation(operationsName);
      } catch (err) {
        setIndicatorsByOperation([]);
        toast.error("Erro ao buscar indicadores");
      }
    }
  };

  const fetchPercentages = async (vehicles, initialDate, finalDate, driverId) => {
    setLoading((oldState) => ({
      ...oldState,
      percentages: true
    }));

    try {
      const response = await getFleetPercentages(
        vehicles,
        initialDate,
        finalDate,
        driverId
      );

      if (response.data) {
        const fleetIndicators = response.data;
        const formatedResponseIndicators = mapValues(
          fleetIndicators,
          (indicator, key) => {
            if (
              key === "ecoRoll" &&
              !hasPermission({ scopes: ["can_view_eco_roll_indicator"] })
            ) {
              return undefined;
            }

            return {
              ...indicator,
              name: key,
              label: getNameIndicator(`${key}Score`),
            };
          },
        );
        const transformIndicatorsToArray = toArray(formatedResponseIndicators);

        setIndicatorsPercentage(transformIndicatorsToArray);
        setIndicatorsPressure({
          higPressure: fleetIndicators.higPressure,
          lowPressure: fleetIndicators.lowPressure,
          medPressure: fleetIndicators.medPressure,
        });
      }
    } catch (err) {
      setIndicatorsPercentage([]);
      setIndicatorsPressure([]);
    } finally {
      setLoading((oldState) => ({ ...oldState, percentages: false }));
    }
  };

  const fetchStatistics = async (vehiclesIds, initialDate, finalDate, driverId) => {
    setLoading((oldState) => ({ ...oldState, statistics: true, gForce: true }));

    try {
      if (!vehiclesIds.length) {
        setStatistics([]);
        return;
      }

      const response = await getFleetStatistics(
        vehiclesIds,
        initialDate,
        finalDate,
        driverId
      );

      if (response?.status !== 200 || response?.data?.totalMileage === 0) {
        setShowNoData(true);
        return;
      }

      setGForce(response.data);
      setStatistics(response.data);
    } catch (err) {
      setGForce(null);
      setStatistics([]);
    } finally {
      setLoading((oldState) => ({ ...oldState, statistics: false, gForce: false }));
    }
  };

  const fetchLocations = async (vehiclesIds, initialDate, finalDate) => {
    setLoading((oldState) => ({ ...oldState, locations: true }));
    if (state) {
      vehiclesIds = [String(vehiclesIds)];
    }
    try {
      if (vehiclesIds.length > 1) {
        setLocationsUnique(true);
        return;
      }
      const response = await getFleetLocations(
        vehiclesIds,
        initialDate,
        finalDate,
      );

      if (response.data.locations.length) {
        const newLocations = response.data.locations.map((location) => {
          return {
            device: location.device,
            locations: location.locations.map((sublocation) => {
              return {
                date: sublocation[0],
                lat: sublocation[1],
                lon: sublocation[2],
              };
            }),
          };
        });
        const transformed = {
          locations: newLocations,
        };
        setLocationsUnique(false);
        setLocations(transformed.locations);
      } else {
        setLocations([]);
      }
    } catch (err) {
      setLocations([]);
    } finally {
      setLoading((oldState) => ({ ...oldState, locations: false }));
    }
  };  

  const fetchChart = async (vehicles, initialDate, finalDate) => {
    setLoading((oldState) => ({ ...oldState, chart: true }));
    try {
      if (
        dataToRequestOfChart?.vehicles === vehicles &&
        dataToRequestOfChart?.initialDate === initialDate &&
        dataToRequestOfChart?.finalDate === finalDate
      ) {
        setShowFleetChart(true);
        return;
      }
      const vehicle = findValueByKey(filters, 'id', true) || vehicles[0];
      const vehicleProfile = await getVehicleProfile(vehicle.id);
      if (!vehicleProfile?.data) {
        throw new Error("failed to fetch chart data");
      }

      const response = await getFleetChartData(
        vehicle?.id,
        initialDate,
        finalDate,
        selectedDriver?.value,
      );

      if (!response?.data || !response?.data?.data) {
        throw new Error("failed to fetch chart data");
      }

      dispatch(
        setDriverDashboardData({ vehicles, initialDate, finalDate }),
      );

      const parsedChartData = parseChartData(
        response.data.data,
        vehicleProfile.data,
        vehicle,
        vehicleProfile,
        initialDate,
        finalDate
      );

      setChartData(parsedChartData);

    } catch (err) {
      setChartData([]);
      setShowFleetChart(false);
      noIndicatorsChartToShow();
    } finally {
      setLoading((oldState) => ({ ...oldState, chart: false }));
    }
  };

  const fetchPressure = async (vehicleId) => {
    if (!vehicleId) return;

    try {
      const response = await getPressureGasPedal(vehicleId);

      if (response.status === 200) {
        setPressureGasPedal(response.data.data);
      }
    } catch (error) {
      console.log('fetchPressure', error);
    }
  };

  const handleAlertDateDivergent = (deviceHistory) => {
    const hasStartDate = deviceHistory?.startDate;
    const hasEndDate = deviceHistory?.endDate;
    const deviceStartDate = new Date(deviceHistory?.startDate).getTime();
    const deviceEndDate = new Date(deviceHistory?.endDate).getTime();
    const filterStartDate = new Date(selectedDates?.initialDate).getTime();
    const filterEndDate = new Date(selectedDates?.finalDate).getTime();

    if (
      hasStartDate &&
      deviceStartDate >
      filterStartDate &&
      deviceStartDate <
      filterEndDate
    ) {
      setAlertDateDivergent({
        install: true,
        date: format(
          new Date(deviceHistory?.startDate),
          dateFormatTemplateOnly,
        ),
        time: format(
          new Date(deviceHistory?.startDate),
          timeFormatTemplateOnly,
        ),
        dateHistory: deviceHistory,
      });
    } else if (
      hasEndDate &&
      deviceEndDate <
      filterEndDate &&
      deviceEndDate >
      filterStartDate
    ) {
      setAlertDateDivergent({
        install: false,
        date: format(
          new Date(deviceHistory?.endDate),
          dateFormatTemplateOnly,
        ),
        time: format(
          new Date(deviceHistory?.endDate),
          timeFormatTemplateOnly,
        ),
        dateHistory: deviceHistory,
      });
    } else {
      setAlertDateDivergent(null);
    }
  }

  const handleFleetChart = (open) => {
    let actualSearchParams = {
      dates: selectedDates,
      vehicles: selectedVehicles,
    };

    if (
      JSON.stringify(actualSearchParams) !== JSON.stringify(searchParams) ||
      dataToRequestOfChart?.vehicles !== ""
    ) {
      setShowFleetChart(open);
      open &&
        fetchChart(
          selectedVehicles,
          selectedDates.initialDate,
          selectedDates.finalDate,
        );

      setSearchParams(actualSearchParams);
    } else {
      noIndicatorsChartToShow();
    }
  };

  const handleDieselControl = () => {
    const selectedFilters = {
      vehicles: selectedVehicles,
      selectedDates: {
        initialDate: selectedDates?.initialDate || null,
        finishDate: selectedDates?.finalDate || null,
      },
      drivers: selectedDriver,
    };
    dispatch(setVehicleDriverFilters(selectedFilters));
    dispatch(setVehicleStepAdd());
    dispatch(setStepAdd());
    const vehicle = findValueByKey(selectedFilters, 'id', true);
    history.push({
      pathname: "/gofurther/dashboard/dieselControl",
      state: {
        type: "vehicleDashboard",
        vehicle: [vehicle],
        initialDate: selectedDates.initialDate,
        finishDate: selectedDates.finalDate,
      },
    });
  };

  const handleGoToDriverLink = () => {
    history.push('/gofurther/maintenance/linkdrivers');
    setShowNoData(false);
  };

  const handleNoDataMessageComponent = async(vehiclesIds, initialDate, finalDate) => {
    const validation = await getVehicleValidation(
      vehiclesIds,
      initialDate,
      finalDate,
    );
    const { response: messageResponse } = validation.data;
    if (messageResponse !== "ok") {
      setVehicleValidationMessage(messageResponse);
      setShowValidationComponent(true);
    }
  };

  const ordernateIndicators = (indicatorList) => {
    let ordenateArray = [];
    const ordenateIndicators = [
      "Início faixa verde",
      "Aproveitamento de embalo",
      "Total motor ligado parado",
      "Acelerando acima do verde",
      "Excesso de velocidade",
      "Piloto automático",
      "Total faixa verde",
      "Final faixa verde",
      "Freio motor",
      "Eco-Roll/I-Roll",
      "Em movimento",
    ];

    for (const label of ordenateIndicators) {
      for (const indicator of indicatorList) {
        if (indicator.label === label) {
          ordenateArray.push(indicator);
        }
      }
    }
    return ordenateArray;
  };

  const mergeIndicatorsInfo = useCallback((indPerformance, indPercentage) => {
    const mergeIndicatorsList = [];

    for (let ind of indPercentage) {
      for (let key in indPerformance) {
        if (indPerformance?.[key]?.name === ind?.name) {
          mergeIndicatorsList.push({
            ...indPerformance[key],
            ...ind,
            color: indPerformance[key].color,
          });
        }
      }
    }

    const indOrdernated = ordernateIndicators(mergeIndicatorsList);

    setIndicators(indOrdernated);
  }, []);
  
  const linkPrint = () => {
    const token = generateRandomBase64(256);
    const vehicle = findValueByKey(selectedVehicles, 'id', true);
    const dataToPrint = {
      consumption: consumption,
      indicators: indicators,
      indicatorsByOperation: indicatorsByOperation,
      indicatorsPressure: indicatorsPressure,
      selectedVehicles: selectedVehicles,
      statistics: statistics,
      summary: summary,
      vehicleId: vehicle,
      initialDate: selectedDates.initialDate,
      finalDate: selectedDates.finalDate,
      vehicleProps: {
        vehicleIdentification: vehicle.identification,
        startDate: selectedDates.initialDate,
        finishDate: selectedDates.finalDate,
      },
      hasThrottlePressureScore: hasThrottlePressureScore,
      throttlePressureScore: throttlePressureScore,
      pressureGasPedal: pressureGasPedal,
      penalty: penalty,
      segment: segment,
      gForce: gForce
    };
    const jwtData = sign(dataToPrint, token);
    dispatch(setPrintData(jwtData));
  };

  const PrintButton = React.forwardRef(
    ({ navigate, jwtData, ...props }, ref) => {
      return (
        <Button
          fullWidth
          variant="contained"
          className={classnames(classes.btnPrint, {
            [classes.btBarginTop]: state,
          })}
          onClick={() => linkPrint()}
          ref={ref}
          {...props}
          style={{ height: "40px" }}
        >
          {props.children}
        </Button>
      );
    },
  );

  useEffect(() => {
    currentCustomer && hasPermission({ scopes: ["can_view_drivers_analysis"] }) && drivers.length === 0 && fetchDrivers();
  }, [currentCustomer]);

  useEffect(() => {
    if (state?.startDate) {
      setSelectedDates({
        initialDate: convertDateStringToDB(state.startDate),
        finalDate: convertDateStringToDB(state.finishDate),
      });
    } else if (filters?.selectedDates?.initialDate) {
      const startDate = filters?.selectedDates.initialDate;
      const finishDate = filters?.selectedDates.finishDate;
      setSelectedDates({
        initialDate: startDate,
        finalDate: finishDate,
      });
      if (filters?.drivers) { 
        handleSelectDriver(filters.drivers, null, startDate, finishDate);
      }
    } else {
      setSelectedDates({
        initialDate: format(new Date(), "yyyy-MM-dd'T'00:00:00XX"),
        finalDate: format(new Date(), "yyyy-MM-dd'T'23:59:59XX"),
      });
    }
  }, []);
  
  useEffect(() => {
    const devices = findValueByKey(selectedVehicles, 'devicesHistory');
    devices && devices?.length > 0 && handleAlertDateDivergent(devices[0])
  }, [filters]);

  useEffect(() => {
    dispatch(
      setVehicleDriverFilters({
        vehicles: selectedVehicles || null,
        selectedDates: {
          initialDate: selectedDates?.initialDate || null,
          finishDate: selectedDates?.finalDate || null,
        },
        drivers: selectedDriver || null,
      }),
    );
  }, [selectedVehicles, selectedDates, selectedDriver]);

  useEffect(() => {
    if (indicatorsPercentage && indicatorsPerformance) {
      mergeIndicatorsInfo(indicatorsPerformance, indicatorsPercentage);
    }
  }, [indicatorsPercentage, indicatorsPerformance]);

  useEffect(() => {
    if (!indicatorsPercentage || !statistics || !indicatorsPercentage?.length) return;

    const invalidPercentages = indicatorsPercentage.every(
      (item) => !item?.percentage || item?.percentage === 0 || (item?.name === "idle" && item?.percentage === 100)
    );

    if (!statistics?.totalConsumption && !statistics?.totalMileage && invalidPercentages) {
      setGForce(null);
      setIndicatorsPercentage([]);
      return;
    }
  }, [indicatorsPercentage, statistics]);

  return (
    <Aux>
      <PermissionsGate scopes={["can_view_drivers_analysis"]}>
        {showNoData ? (
          <NoDataBox
            onGoToDriverLink={handleGoToDriverLink}
            onNewPeriod={clearAllStates}
          />
        ) : (
          <>
            {showFleetChart && (
              <ModalFleetChart
                showFleetChart={showFleetChart}
                handleFleetChart={handleFleetChart}
                loading={loading.chart}
                chartData={chartData}
              />
            )}
            {(selectedDates?.initialDate || state?.startDate) && (
              <FiltersPanel
                handleSelectDate={handleSelectDate}
                selectedDates={selectedDates}
                listData={drivers}
                selectedData={selectedVehicles}
                handleSelectedData={handleSelectDriver}
                driverSelectData={selectedDriver}
                calendarMaxRanges={{
                  ...(selectedVehicles?.[0]?.devicesHistory && {
                    minDate: new Date(selectedVehicles[0].devicesHistory.at(-1).startDate),
                  }),
                  maxDate: maxDate,
                }}
                multiSelectVehicles={multiSelectVehicles}
                handleSelectedVehicles={handleSelectedVehicles}
                selectedVehicles={selectedVehicles}
                loading={loading}
              />
            )}
            <Grid container className={classes.generalContainer}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ marginBottom: 15 }}
              >
                {alertDateDivergent && (
                  <Alert severity="error">
                    Atenção!
                    {`Unidade eletrônica ${alertDateDivergent?.install
                      ? "instalada"
                      : "desinstalada ou iniciou com erro"
                      } no dia
                ${alertDateDivergent?.date} às ${alertDateDivergent?.time
                      }. Selecione um novo período ${alertDateDivergent?.install
                        ? "a partir da data e hora que foi realizada a instalação"
                        : "até a data da desinstalação."
                      } para obter dados e a análise corretamente.`}
                  </Alert>
                )}
              </Grid>
              {segment &&
                <Grid item xs={12}>
                  <Grid
                    item
                    xs={12}
                    data-cy="detalhesVeiculo"
                    className={classes.detailSegment}
                  >
                    <HeaderDetails
                      vehicle={
                        !state ? selectedVehicles[0]?.identification + ' / ' + selectedVehicles[0]?.plate || ' - '
                          : state?.vehicleId?.vehicleIdentification + ' / ' + state?.vehicleId?.plate || ' - '
                      }
                      driver={
                        !state ? selectedVehicles[0]?.currentDriver?.driverName || ' - '
                          : state?.detail?.name || ' - '
                      }
                      date={segment}
                      showBackButton={false}
                      loading={false}
                    />
                  </Grid>
                </Grid>
              }
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                {size.mobile ? null : (
                  <PermissionsGate scopes={["can_view_dashboard_print"]}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {selectedVehicles.length > 0 &&
                        !showValidationComponent && (
                          <Link
                            target="_blank"
                            component={PrintButton}
                            to={{
                              pathname: "/dashboard/print",
                            }}
                            data-cy="divImprimir"
                          >
                            Imprimir
                          </Link>
                        )}
                    </Grid>
                  </PermissionsGate>
                )}
              </Grid>
            </Grid>
            {showValidationComponent && (
              <ValidationMessageComponent message={vehicleValidationMessage} isComparison />
            )}
            {!selectedVehicles.length > 0 && !showValidationComponent ? (
              <NotFoundInfo
                description="Para gerar as informações da sua frota, selecione um motorista"
                strongDescription="clicando no filtro localizado no menu acima."
                localStorageKey="infoBoxDriverDashboard"
              />
            ) : (
              <Grid
                container
                spacing={4}
                data-cy="contentAnaliseIndividual"
                className={classnames(classes.container, {
                  [classes.containerShift]: layoutState.isSidebarOpened,
                })}
              >
                <PermissionsGate scopes={["can_view_dashboard_performance"]}>
                  <Grid
                    item
                    xl={2}
                    lg={!layoutState.isSidebarOpened ? 2 : 3}
                    md={3}
                    sm={4}
                    xs={12}
                    data-cy="performanceDesempenho"
                  >
                    <Typography variant="h4" className={classes.cardTitle} data-cy="h4TituloDesempenho">
                      Desempenho
                    </Typography>
                    <Paper elevation={0} className={classes.performance} data-cy="h4DesempenhoDados">
                      <Grid container alignItems="center">
                        {loading.indicators ? (
                          <PerformanceSkeleton />
                        ) : (
                          <PerformanceComponent
                            data={summary}
                            selectedVehicles={selectedVehicles}
                            indicators={indicators}
                          />
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                </PermissionsGate>
                <PermissionsGate scopes={["can_view_dashboard_indicators"]}>
                  <Grid
                    item
                    xl={10}
                    lg={!layoutState.isSidebarOpened ? 10 : 9}
                    md={9}
                    sm={8}
                    xs={12}
                    data-cy="divperformanceIndicadores"
                  >
                    <Typography variant="h4" className={classes.cardTitle} data-cy="h4Indicadores">
                      Indicadores
                    </Typography>
                    <FleetPerformance
                      loading={loading}
                      indicators={
                        !hasPermission({ scopes: ["can_view_eco_roll_indicator"] })
                          ? indicators.filter((obj) => obj.name !== "ecoRoll")
                          : indicators
                      }
                      consumption={consumption}
                      summary={summary}
                      handleDieselControl={handleDieselControl}
                      handleFleetChart={() => {
                        handleFleetChart(true);
                      }}
                      indicatorsByOperation={indicatorsByOperation}
                      selectedVehicles={selectedVehicles}
                      displayFuelButton={false}
                    />
                  </Grid>
                </PermissionsGate>
                <PermissionsGate scopes={["can_view_dashboard_gforce"]}>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    data-cy="divPerformanceForcaG"
                  >
                    <Typography variant="h4" className={classes.cardTitle} data-cy="h4TituloForcaGLateral">
                      Força G Lateral
                    </Typography>
                    {loading.gForce || !gForce ? (
                      <PressureSkeleton />
                    ) : (
                      <GForce data={gForce} />
                    )}
                  </Grid>
                </PermissionsGate>
                {showThrottleGraphPressureScore && !canViewDashboardGForce && (
                  <PermissionsGate scopes={["can_view_dashboard_pressure"]}>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={3}
                      sm={12}
                      xs={12}
                      data-cy="divPerformancePressaoAcelerador"
                    >
                      <Typography variant="h4" className={classes.cardTitle} data-cy="h4TituloPressaoAcelerador"
                      >
                        Pressão do acelerador
                      </Typography>
                      {loading.percentages ? (
                        <PressureSkeleton />
                      ) : (
                        <Pressure
                          hasThrottlePressureScore={hasThrottlePressureScore}
                          data={indicatorsPressure}
                          throttlePressureScore={throttlePressureScore}
                          penalty={penalty}
                          pressureGasPedal={pressureGasPedal}
                        />
                      )}
                    </Grid>
                  </PermissionsGate>
                )}
                <PermissionsGate scopes={["can_view_dashboard_statistics"]}>
                  <Grid
                    item
                    xl={showThrottleGraphPressureScore > 0 || canViewDashboardGForce ? 9 : 12}
                    lg={showThrottleGraphPressureScore > 0 || canViewDashboardGForce ? 9 : 12}
                    md={showThrottleGraphPressureScore > 0 || canViewDashboardGForce ? 9 : 12}
                    sm={12}
                    xs={12}
                    data-cy="performanceEstatisticas"
                  >
                    <Typography variant="h4" className={classes.cardTitle} data-cy="h4Estatisticas">
                      Estatísticas
                    </Typography>
                    {loading.statistics || loading.percentages ? (
                      <CardsSkeleton />
                    ) : (
                      <StatisticsCards
                        indicatorsPressure={
                          Object.keys(indicatorsPressure).length > 0 &&
                          indicatorsPressure?.higPressure?.percentage > 0
                        }
                        statistics={statistics}
                        selectedVehicles={selectedVehicles}
                      />
                    )}
                  </Grid>
                </PermissionsGate>
                <PermissionsGate scopes={["can_view_dashboard_map"]}>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    data-cy="divPerformanceTrajetoViagem"
                  >
                    <Typography variant="h4" className={classes.cardTitle} data-cy="h4TituloTrajetoViagem">
                      Trajeto da viagem
                    </Typography>
                    {loading.locations ? <MapSkeleton /> :
                      (!locations || locations.length === 0) ?
                        (
                          <Alert severity="info">
                            Atenção!
                            Não foram encontrados indicadores para a frota selecionada,{" "}
                            <strong>verifique os filtros.</strong>
                          </Alert>
                        ) : (locationsUnique) ?
                          (
                            <Alert severity="info">
                              Atenção!
                              Para que possa visualizar o trajeto das rotas do veículo no mapa é
                              necessário selecionar <strong>apenas um veículo.</strong>
                            </Alert>
                          ) : <Map data={locations} disabled={selectedVehicles.length > 1} />
                    }
                  </Grid>
                </PermissionsGate>
              </Grid>
            )}
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}></Grid>
              {state?.screen && size.mobile ? (
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnBack}
                    onClick={() => {
                      if (state.screen !== "bonus") {
                        dispatch(setStepDeduct());
                      } else {
                        dispatch(setStepDeductBonus());
                      }
                      history.goBack();
                    }}
                  >
                    Voltar
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </>
        )}
      </PermissionsGate>
    </Aux>
  );
}
