import React from "react";
import { Typography } from "@mui/material";
import classnames from "classnames";
import { FormControlLabel, Checkbox } from '@mui/material';
import makeStyles from "./styles";

export const makeColumns = (selectedVehicles, setSelectedVehicles, vehiclesFiltered) => {
  const classes = makeStyles();

  const getCell = (data, textBold) => {
    return (
      <Typography
        variant="body2"
        className={classnames({
          [classes.textBold]: textBold,
        })}
        noWrap={true}
      >
        {data || '-'}
      </Typography>
    );
  };

  const getCheckbox = (vehicle_id) => {
    const handleCheckboxClick = () => {
      setSelectedVehicles((prev) =>
        prev.includes(vehicle_id)
          ? prev.filter((id) => id !== vehicle_id) 
          : [...prev, vehicle_id] 
      );
    };
  
    return (
      <Checkbox
        edge="start"
        checked={selectedVehicles.includes(vehicle_id)}
        onClick={handleCheckboxClick}
        tabIndex={-1}
        disableRipple
        className={classes.checkBox}
        color="secondary"
      />
    );
  };

  const getCheckboxForAll = () => {
    const checkedAll = selectedVehicles.length >= vehiclesFiltered.length;
    const selectOrRemoveAll = () => {
      if(selectedVehicles.length >= vehiclesFiltered.length) {
        setSelectedVehicles([]);
        return;
      }
  
      const vehicleIds = vehiclesFiltered.map((vehicle) => vehicle.vehicle_id);
      setSelectedVehicles(vehicleIds);
    }
  
    return (
      <FormControlLabel
        control={
          <Checkbox
            edge="start"
            checked={checkedAll}
            onClick={selectOrRemoveAll}
            tabIndex={-1}
            disableRipple
            className={classes.checkBox}
            color="primary"
          />
        }
        label={"Selecionar todos"}
        sx={{
          margin: 0,
          "& .MuiTypography-root": {
            margin: 0,
          },
        }}
      />
    );
  };

  return [
    {
      name: "id",
      label: getCheckboxForAll(),
      options: {
        customBodyRender: (value) => getCheckbox(value),
      },
    },
    {
      name: "plate",
      label: "Placa",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "identification",
      label: "Chassi",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
  ];
};