import React from 'react';

export const PrintableTable = React.forwardRef(function PrintableTable({ data, columns, tableTitle }, ref) {
  const printableColumns = columns.filter(column => typeof column.label === 'string' && column.label !== 'Ações');

  return (
    <div ref={ref} className="p-4">
      <h2 className="text-2xl font-bold mb-4">{tableTitle}</h2>
      <table className="w-full border-collapse">
        <thead>
          <tr>
            {printableColumns.map((column, index) => (
              <th key={index} className="border p-2 text-left bg-gray-100">
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {printableColumns.map((column, colIndex) => (
                <td key={colIndex} className="border p-2">
                  {column.options && column.options.customBodyRender
                    ? column.options.customBodyRender(item[column.name], { rowIndex })
                    : item[column.name]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});