import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import CreatableSelect from 'react-select/creatable';
import { useFormContext, Controller, FormProvider, useForm } from "react-hook-form";

import { useCallback } from "react";

import { yupResolver } from "@hookform/resolvers/yup"

// components
import Input from "components/react-hook-form/input";
import BackButton from "components/BackButton";

// services
import { FaqFormSchema } from "./schema"

import useStyles from "./styles";

const FaqForm = ({ defaultValues, submitLabel = 'Cadastrar', onSubmit, onError, }) => {
  const classes = useStyles();
  const methods = useForm({
    defaultValues: {
      ...defaultValues,
    },
    resolver: yupResolver(FaqFormSchema)
  });
  const { control, handleSubmit } = methods
  return (
    <FormProvider {
      ...methods
    }>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Paper elevation={2} className={classes.container}>
          <Grid container spacing={4}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography className={classes.title}>Pergunta</Typography>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name="question"
                          render={({ field: { ref, value = '', ...rest }, fieldState: { error } }) => (
                            <Input
                              {...rest}
                              value={value}
                              variant="outlined"
                              fullWidth
                              label="Insira a pergunta"
                              className={classes.disabledInput}
                              helperText={error?.message}
                              error={!!error}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography className={classes.title}>Resposta</Typography>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name="answer"
                          render={({ field: { ref, value = '', ...rest }, fieldState: { error } }) => (
                            <Input
                              {...rest}
                              value={value}
                              variant="outlined"
                              fullWidth
                              label="Insira a resposta"
                              className={classes.disabledInput}
                              helperText={error?.message}
                              error={!!error}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mb={2}>
                  <Typography className={classes.title}>Tags ou categorias</Typography>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name="tags"
                          render={({ field: { ref, value = [], onChange, ...rest }, fieldState: { error } }) => (
                            <>
                              <CreatableSelect
                                isMulti
                                value={value}
                                onChange={(selected) => onChange(selected)}
                                placeholder="Insira tags ou categorias para ajudar na busca e categorização"
                                className={classes.customSelect}
                                classNamePrefix="react-select"
                                noOptionsMessage={() => null}
                                formatCreateLabel={(inputValue) => `Adicionar "${inputValue}"`}
                                {...rest}
                              />
                              {error && (
                                <Typography color="error" variant="caption">
                                  {error.message}
                                </Typography>
                              )}
                            </>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mb={2}>
                  <Typography className={classes.title}>Exibir onde?</Typography>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Controller
                          name="v3"
                          defaultValue={false}
                          control={control}
                          render={({ field: { value = false, onChange } }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={value}
                                  onChange={(e) => onChange(e.target.checked)}
                                  color="secondary"
                                />
                              }
                              label="V3"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Controller
                          name="app"
                          defaultValue={false}
                          control={control}
                          render={({ field: { value = false, onChange } }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={value}
                                  onChange={(e) => onChange(e.target.checked)}
                                  color="secondary"
                                />
                              }
                              label="Aplicativo"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Grid justifyContent="flex-end" container spacing={2} mt={2}>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <BackButton className={classes.backButton} />
                </Box>
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    className={classes.btConfirm}
                  >
                    {submitLabel}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default FaqForm;
