import chroma from 'chroma-js';
import colors from 'themes/gobrax';
export const multiSelectStyles = {
  control: (styles) => ({ 
    ...styles, 
    backgroundColor: colors.palette.primary.contrastText, 
    border: 'transparent',
    fontSize: '14px',
    overflow: 'auto',
    maxHeight: 250
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color || colors.palette.primary.main);
    return {
      ...styles,
      textAlign: 'left',
      fontSize: '14px',
      borderLeft: isSelected ? 
        `6px solid ${colors.palette.secondary.main}`
          : 'none',
      fontWeight: isSelected ? 'bold' : 'normal',
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? colors.palette.background.background2
          : isFocused
            ? colors.palette.background.hoverMultiMenu
            : undefined,
      color: isDisabled
        ? colors.palette.text.hint
        : isSelected
          ? chroma.contrast(color, colors.palette.black) > 2
            ? colors.palette.black
            : colors.palette.secondary.contrastText
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      paddingLeft: data.isChild ? 20 : 10,
      display: data.isHidden ? 'none' : 'block',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: colors.palette.background.background2,
      borderRadius: '20px',
      padding: '3px 10px'
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: colors.palette.black,
    fontWeight: 'bold',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: colors.palette.black,
    ':hover': {
      backgroundColor: data.color,
      color: colors.palette.primary.contrastText,
    },
  }),
};

