import React, { useState, useRef, useEffect, useCallback } from "react";
import { GoogleMap, OverlayView, OverlayViewF } from "@react-google-maps/api";
import dayjs from "dayjs";
import supercluster from "supercluster";
import PropTypes from "prop-types";
import VehiclePopover from "./_components/VehiclePopover";
import { useHistory } from 'react-router-dom';
import ClusterSingle from "componentsNew/atoms/clusterSingle/clusterSingle";

import "./map.css";
import ClusterGroup from "componentsNew/atoms/clusterGroup/clusterGroup";
import { TypeStatus } from "helpers/statusVehicle";
import MapLoader from "components/MapLoader";

const containerStyle = {
  borderRadius: 16,
  width: "100%",
  height: "100%",
};

const Map = ({
  points = [],
  initZoom = 15,
  initLocationCenter,
  height,
  width,
  message,
  disableMarkerClick = false
}) => {

  const history = useHistory();
  const mapRef = useRef(null);
  const clusterIndex = useRef(null);
  const [clusters, setClusters] = useState([]);
  const [zoom, setZoom] = useState(initZoom);
  const [center, setCenter] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (points.length > 0) {
      clusterIndex.current = new supercluster({
        radius: 60,
        maxZoom: 16,
      });

      clusterIndex.current.load(
        points.map((point) => ({
          type: "Feature",
          properties: { cluster: false, pointId: point.vehicleId, color: point.color, title: point.title },
          geometry: { type: "Point", coordinates: [point.lng, point.lat] },
        }))
      );

      // agora vai atualizar
      if (mapRef.current) {
        const bounds = mapRef.current.getBounds();
        const zoom = mapRef.current.getZoom();
        updateClusters(bounds, zoom);
      }

      // CENTRALIZA SÓ UMA VEZ PF
      if (!center && points.length) {
        setCenter({ lat: points[0].lat, lng: points[0].lng });
      }
    }
  }, [points]);

  const updateClusters = useCallback((bounds, zoom) => {
    if (clusterIndex.current && bounds) {
      const clusters = clusterIndex.current.getClusters(
        [bounds.getSouthWest().lng(), bounds.getSouthWest().lat(), bounds.getNorthEast().lng(), bounds.getNorthEast().lat()],
        zoom
      );
      setClusters(clusters);
    }
  }, []);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onIdle = useCallback(() => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      const zoom = mapRef.current.getZoom();
      setZoom(zoom);
      updateClusters(bounds, zoom);
    }
  }, [updateClusters]);

  const handleMarkerClick = (vehicleId) => {
    if (disableMarkerClick) return;

    const vehicleDetails = message.find((vehicle) => vehicle.vehicleId === vehicleId);
    if (!vehicleDetails) return;

    const { status, graphic, avgRpm } = vehicleDetails;
    const rpm = graphic?.rpm;

    if (status === 'STOPPED_MOTOR_ON' && avgRpm && (!rpm || rpm === 0)) {
      const modifiedVehicleDetails = {
        ...vehicleDetails,
        originalRpm: rpm,
        graphic: {
          ...graphic,
          rpm: avgRpm
        }
      };
      setSelectedVehicle(modifiedVehicleDetails);
    } else {
      setSelectedVehicle(vehicleDetails);
    }
    setIsModalOpen(true);
  };

  const handleClusterClick = (cluster) => {
    const expansionZoom = Math.min(clusterIndex.current.getClusterExpansionZoom(cluster.id), 20);
    mapRef.current.setZoom(expansionZoom);
    mapRef.current.panTo({
      lat: cluster.geometry.coordinates[1],
      lng: cluster.geometry.coordinates[0],
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVehicle(null);
  };

  const handleSelectVehicle = (vehicle) => {
    history.push({
      pathname: "/gofurther/dashboard/general/bonus",
      state: {
        vehicleId: vehicle.vehicleId,
        screen: "map",
        detail: vehicle,
        startDate: dayjs(vehicle.date).format("YYYY-MM-DDT00:00:00ZZ"),
        finishDate: vehicle.date,
        mapDetails: true
      },
    });
    /* history.push('/gofurther/liveMapDetails', {
      vehicle: vehicle,
    })*/
  }

  const calculateColorCounts = (clusterId) => {
    const clusterChildren = clusterIndex.current.getChildren(clusterId);
    const colors = { blue: 0, green: 0, yellow: 0, gray: 0, white: 0, red: 0 };

    clusterChildren.forEach((child) => {
      if (child.properties.cluster) {
        const subColors = calculateColorCounts(child.id);
        colors.blue += subColors.blue;
        colors.green += subColors.green;
        colors.yellow += subColors.yellow;
        colors.gray += subColors.gray;
        colors.white += subColors.white;
        colors.red += subColors.red;
      } else {
        colors[child.properties.color] += 1;
      }
    });

    return colors;
  };

  return (
    <div className="Map-container">

      <div
        className="Map-container-item"
        style={{ height: height || "50vh", width: width || "auto" }}
      >
        <MapLoader>
          <GoogleMap
            onLoad={onMapLoad}
            onIdle={onIdle}
            zoom={zoom}
            center={center || initLocationCenter}
            mapContainerStyle={containerStyle}
            options={{
              streetViewControl: true,
              mapTypeControl: true,
              disableDefaultUI: true,
              zoomControl: true,

            }}
          >
            {clusters.map((cluster) => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const { cluster: isCluster, point_count: pointCount, color, title } = cluster.properties;
              if (isCluster) {
                const colors = calculateColorCounts(cluster.id);

                return (
                  <OverlayViewF
                    key={`cluster-${cluster.id}`}
                    position={{ lat: latitude, lng: longitude }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <ClusterGroup
                      blue={colors.blue}
                      green={colors.green}
                      yellow={colors.yellow}
                      gray={colors.gray}
                      white={colors.white}
                      red={colors.red}
                      onClick={() => handleClusterClick(cluster)}
                    />
                  </OverlayViewF>
                );
              }

              return (
                <>
                  <OverlayViewF
                    key={`point-${cluster.properties.pointId}`}
                    position={{ lat: latitude, lng: longitude }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <ClusterSingle
                      color={color}
                      title={title}
                      onClick={() => handleMarkerClick(cluster.properties.pointId)}
                    />
                  </OverlayViewF>
                </>
              );
            })}
            {isModalOpen && selectedVehicle && (
              <VehiclePopover
                vehicle={selectedVehicle}
                onClose={closeModal}
                handleSelectVehicle={handleSelectVehicle}
              />
            )}
          </GoogleMap>
        </MapLoader>
      </div>
    </div>
  );
};

Map.propTypes = {
  initZoom: PropTypes.number.isRequired,
  height: PropTypes.any,
  width: PropTypes.any,
  points: PropTypes.array,
  loading: PropTypes.bool,
  message: PropTypes.array,
};

export default Map;
