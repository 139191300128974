import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Typography,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// store
import { store, useAppSelector } from "store";
import { clearFiltersByPath } from "./components/clear-filters-by-path";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
  closeSidebar,
  openSidebar,
} from "context/LayoutContext";

// components
import PermissionsGate from "components/PermissionsGate";
import SidebarLink from "./components/SidebarLink/SidebarLink";
import { getMenuStructure } from "./menuStructure";
import { getMenuStructureAdmin } from "./menuStructureAdmin";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

// styles
import useStyles from "./styles";

function Sidebar({ location }) {
  const classes = useStyles();
  const theme = useTheme();

  const user = useAppSelector((state) => state.global.user);

  const [roles, setRoles] = useState([]);

  // global
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  // local
  const [isPermanent, setPermanent] = useState(true);
  const pathArray = useMemo(() => window.location.pathname.split("/"), [location]);

  const size = useWindowSize();

  const handleWindowWidthChange = useCallback(() => {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    setPermanent(!isSmallScreen);
  }, [theme.breakpoints.values.md]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowWidthChange);

    clearFiltersByPath(store, pathArray);

    handleWindowWidthChange();

    return () => {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  }, [handleWindowWidthChange, pathArray]);

  useEffect(() => {
    const userRoles = user?.roles;

    if (userRoles) {
      setRoles(JSON.parse(Buffer.from(userRoles, "base64").toString("utf-8")));
    }
  }, [user]);

  const menuStructure = useMemo(() => getMenuStructure(roles), [roles]);
  const adminMenuStructure = useMemo(() => getMenuStructureAdmin(), []);

  const handleMouseEnter = useCallback(() => {
    if (!size.mobile) openSidebar(layoutDispatch);
  }, [size.mobile, layoutDispatch]);

  const handleMouseLeave = useCallback(() => {
    if (!size.mobile) closeSidebar(layoutDispatch);
  }, [size.mobile, layoutDispatch]);

  const handleCloseSidebar = useCallback(() => {
    if (size.mobile) toggleSidebar(layoutDispatch);
  }, [size.mobile, layoutDispatch]);

  return (
    <Drawer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
      onClose={handleCloseSidebar}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={handleCloseSidebar} size="large">
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <Grid container spacing={0} direction="column" alignItems="center">
        <List data-cy="sideBarItens" className={classes.listMenu}>
          {menuStructure.map((link, index) => (
            <SidebarLink
              onClose={handleCloseSidebar}
              key={`sidebarLink${index}`}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
        </List>
        <PermissionsGate scopes={["can_view_admin_area"]}>
          <>
            <Divider />
            <Grid container alignItems="center">
              <Grid
                item
                xs
                style={{
                  marginTop: theme.spacing(2),
                  marginLeft: theme.spacing(2),
                }}
              >
                {isSidebarOpened && (
                  <Typography
                    style={{
                      fontSize: "0.7rem",
                      fontWeight: "bold",
                      color: theme.palette.text.secondary,
                    }}
                  >
                    Área Administrativa
                  </Typography>
                )}
              </Grid>
            </Grid>
            <List data-cy="sideBarItens" className={classes.listMenu}>
              {adminMenuStructure.map((link, index) => (
                <SidebarLink
                  onClose={handleCloseSidebar}
                  key={`sidebarLinkAdmin${index}`}
                  location={location}
                  isSidebarOpened={isSidebarOpened}
                  {...link}
                />
              ))}
            </List>
          </>
        </PermissionsGate>
      </Grid>
    </Drawer>
  );
}

export default withRouter(Sidebar);
