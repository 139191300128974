import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  containerInfobox: {
    height: 450,
    display: 'flex',
    alignItems: 'center',
    '&[data-darkbox="true"]': {
      backgroundColor: 'rgb(244, 244, 246)',
    },
  },
  infoBox: {
    fontSize: theme.typography.h3.fontSize,
  },
  paper: {
    borderRadius: 20,
    padding: theme.spacing(1),
    maxWidth: "850px",
    margin: "0 auto",
  },
  okButton: {
    borderRadius: 20,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 32,
    marginBottom: 32,
    marginLeft: 32,
  },
}));
