import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useDebouncedCallback } from 'use-debounce';
import Select from "react-select";
import makeAnimated from "react-select/animated";

// helpers
import { generateRange } from "helpers/operations";
import { getCurrentMonth, orderByIndex } from "helpers/functions";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

// components
import Aux from "hoc/auxiliar";
import Infobox from "components/Infobox";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import TableResponsive from "components/TableResponsive";

import { EvaluationCriteriaModal } from "./components/EvaluationCriteriaModal";
import { makeColumns } from "./tableColumns";

// services
import { getCustomersById } from "services/customers";
import { getDrivers } from "services/driver";
import { getRankingByCustomer, getRankingByDriverCustomer, getRankingByOperation, getRankingByDriverOperation } from "services/ranking";
import { getVehiclesByOperation } from "services/vehicle";

// context
import { useAppDispatch, useAppSelector, store } from "store";
import { clearPagination, setPaginationReducer } from "store/features/rankingSlice";

// styles
import colors from "themes/gobrax";
import { colourStyles } from "./colourStyles";
import makeStyles from "./styles";

export default function Ranking() {
  const animatedComponents = makeAnimated();
  const classes = makeStyles();
  const size = useWindowSize();
  const columns = makeColumns(size);
  const dispatch = useAppDispatch();

  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const dateFormatTemplate = "YYYY-MM-DDTHH:mm:ssZZ";
  const firstOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const maxDate = new Date();
  maxDate.setHours(23, 59, 0, 0);

  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const { pagination } = useAppSelector((state) => state.ranking);

  const [customersDates, setCustomersDates] = useState(null);
  const [cutOffDatesOptions, setCutOffDatesOptions] = useState(null);
  const [selectedCutOffDate, setSelectedCutOffDate] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [rankingData, setRankingData] = useState(null);
  const [selectedDates, setSelectedDates] = useState({
    initialDate: dayjs(firstOfMonth).format(dateFormatTemplate),
    finalDate: dayjs().format("YYYY-MM-DDT23:59:59ZZ"),
  });
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedYear, setSelectedYear] = useState({ value: today.getFullYear(), label: today.getFullYear() });
  const [operations, setOperations] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const currentYear = today.getFullYear();
  const lastYearToShow = today.getMonth() > 10 ? currentYear + 1 : currentYear;

  const yearOptions = useMemo(() => {
    return Array.from({ length: lastYearToShow - 2020 + 1 }, (_, index) => {
      const year = 2020 + index;

      return { value: year, label: year };
    }).reverse();
  }, [lastYearToShow]);

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await getDrivers(currentCustomer);

      if (response.status !== 200) {
        throw new Error("Error fetching drivers");
      }

      const { drivers } = response.data;

      if (!!drivers?.length) {
        setDrivers(orderByIndex(drivers, "name"));
      }

      const responseCustomers = await getCustomersById(currentCustomer);

      if (responseCustomers.status !== 200) {
        throw new Error("Error fetching customers");
      }

      const customersOptions = responseCustomers.data.customers[0];

      setCustomersDates(customersOptions);
      handleCutOffDates(customersOptions);

    } catch (error) {
      setDrivers([]);
      setCustomersDates(null);
      toast.error(
        "Erro ao carregar dados. Entre em contato com o suporte.",
      );
    } finally {
      setLoading(false);
    }
  }

  const fetchSearchData = async (selectedDriver, initialDate, finalDate, operation, searchTerm = "") => {
    const startDate = dayjs(initialDate).format("YYYY-MM-DD");
    const endDate = dayjs(finalDate).format("YYYY-MM-DD");

    setLoading(true);

    dispatch(setPaginationReducer({ isLoading: true }));

    try {
      const updatedPagination = store.getState().ranking.pagination;
      let response;

      const cleanedSearchTerm = (searchTerm || '').trim();

      if (!selectedDriver && !operation) {
        response = await getRankingByCustomer(
          currentCustomer,
          startDate,
          endDate,
          updatedPagination.rowsPerPage,
          updatedPagination.page,
          cleanedSearchTerm
        );
      } else if (operation && selectedDriver) {
        response = await getRankingByDriverOperation(
          currentCustomer,
          selectedDriver.value,
          operation.value,
          startDate,
          endDate,
          updatedPagination.rowsPerPage,
          updatedPagination.page,
          cleanedSearchTerm
        );
      } else if (operation) {
        response = await getRankingByOperation(
          currentCustomer,
          operation.value,
          startDate,
          endDate,
          updatedPagination.rowsPerPage,
          updatedPagination.page,
          cleanedSearchTerm
        );
      } else {
        response = await getRankingByDriverCustomer(
          currentCustomer,
          selectedDriver.value,
          startDate,
          endDate,
          cleanedSearchTerm
        );
      }

      if (response.status !== 200) {
        throw new Error();
      }

      dispatch(
        setPaginationReducer({
          isLoading: false,
          count: response.data?.total || 0
        })
      );

      const rankings = response?.data?.data?.rankings || response?.data?.data || null;
      setRankingData(rankings || []);
    } catch (error) {
      console.log(error);
      toast.error(
        "Erro ao carregar ranking. Entre em contato com o suporte.",
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchOperations = async () => {
    setLoading(true);

    try {
      const response = await getVehiclesByOperation(currentCustomer);
      const operations = response.data.customers;
      const orderedOperations = operations.sort(
        (a, b) => (a.name > b.name) - (a.name < b.name)
      );
      const operationsMap = orderedOperations.map((item) => ({ value: item.id, label: item.name }))
      setOperations(operationsMap);
    } catch (err) {
      toast.error(
        "Erro ao carregar a lista de veículos. Entre em contato com o suporte.",
      );
    } finally {
      setLoading(false);
    }
  }

  const handleCutOffDates = (customersOptions) => {
    const startingDay = customersOptions.starting_day;
    const finishingDay = customersOptions.finishing_day;
    const period = customersOptions.period;

    if (startingDay && finishingDay && !isNaN(period)) {
      const cutOffDate = generateRange(startingDay, finishingDay, period, selectedYear.value);
      const selectedMonth = getCurrentMonth(finishingDay);

      setCutOffDatesOptions(cutOffDate);
      setSelectedCutOffDate(cutOffDate[selectedMonth]);

      const initialDate = dayjs(cutOffDate[selectedMonth].startDate).format(dateFormatTemplate);
      const finalDate = dayjs(cutOffDate[selectedMonth].finishDate).format("YYYY-MM-DDT23:59:59ZZ");

      handleSelectDate(initialDate, finalDate);
    } else {
      setCutOffDatesOptions(null);
      setSelectedCutOffDate(null);
    }
  };

  const handleSelectDriver = async (driver) => {
    try {
      setLoading(true);
      setSelectedDriver(driver);
      fetchSearchData(
        driver,
        selectedDates.startDate,
        selectedDates.finishDate,
        selectedOperation
      );
    } catch (err) {
      toast.error(
        "Erro ao carregar ranking. Entre em contato com o suporte.",
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelectDate = async (initialDate, finalDate) => {
    setSelectedDates({
      initialDate: initialDate,
      finalDate: finalDate,
    });

    if (!!selectedDriver?.value) {
      fetchSearchData(
        selectedDriver,
        initialDate,
        finalDate,
        selectedOperation
      );
    }
  };

  const handleOperation = async (operation) => {
    setSelectedOperation(operation);
    fetchSearchData(
      selectedDriver,
      selectedDates.startDate,
      selectedDates.finishDate,
      operation
    );
  };

  const debouncedSearch = useDebouncedCallback(
    (term) => {
      const searchTerm = term.trim();
      const isNumber = !isNaN(searchTerm);
      const shouldSearch = isNumber || searchTerm.length >= 3;
      fetchSearchData(
        selectedDriver,
        selectedDates.startDate,
        selectedDates.finishDate,
        selectedOperation,
        shouldSearch ? searchTerm : ''
      );
    },
    500
  );

  const handleSearch = useCallback((searchValue) => {
    const term = searchValue || '';
    setSearchTerm(term);
    debouncedSearch(term);
  }, [debouncedSearch]);

  const handleClose = () => setModalOpen(false);

  const handleTablePagination = (action, tableState) => {
    switch (action) {
      case "changePage":
        dispatch(setPaginationReducer({ isLoading: true, page: tableState.page + 1 }));
        fetchSearchData(selectedDriver, selectedDates.startDate, selectedDates.finishDate, selectedOperation, searchTerm || '');
        break;
      case "changeRowsPerPage":
        dispatch(setPaginationReducer({ isLoading: true, page: 1, rowsPerPage: tableState.rowsPerPage }));
        fetchSearchData(selectedDriver, selectedDates.startDate, selectedDates.finishDate, selectedOperation, searchTerm || '');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(clearPagination());
  }, [dispatch]);

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ["can_view_ranking"] })) {
      fetchData();
      hasPermission({ scopes: ["can_view_ranking_by_vehicle_group"] }) && fetchOperations();
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (selectedCutOffDate) {
      setSelectedDates(selectedCutOffDate);
      fetchSearchData(selectedDriver, selectedCutOffDate.startDate, selectedCutOffDate.finishDate, selectedOperation);
    }
  }, [selectedCutOffDate]);

  useEffect(() => {
    if (selectedYear) {
      customersDates && handleCutOffDates(customersDates);
    }
  }, [selectedYear]);

  return (
    <Aux>
      <PermissionsGate scopes={["can_view_ranking"]}>
        <EvaluationCriteriaModal open={modalOpen} onClose={handleClose} />
        <Grid container paddingBottom={1}>
          <Grid item md={5} sm={12} xs={12} className={classes.titleContainer}>
            <Typography variant="h2" className={classes.titlePage}>
              Ranking
            </Typography>
            <Typography>
              Veja a pontuação de todos os seus motoristas e o ranking dos
              melhores de acordo com a nota e km rodado
            </Typography>
          </Grid>
          <Grid item md={7} sm={12} xs={12} display="flex" justifyContent="flex-end">
            <Button
              title={"Critérios de avaliação"}
              onClick={() => setModalOpen(true)}
              className={classes.helpButton}
              data-cy="buttonHelp"
            >
              Critérios de avaliação
            </Button>
          </Grid>
          <Grid item container xs={12} spacing={2} className={classes.filters}>
            <Grid item xs={size.grid3} data-cy="divSelectDriver">
              <Paper elevation={0} className={classes.multiselect}>
                <Select
                  data-cy="selectDriver"
                  placeholder="Selecione um motorista"
                  options={drivers ? drivers.map((item) => ({ value: item.id, label: item.name })) : []}
                  onChange={handleSelectDriver}
                  noOptionsMessage={() => drivers ? "Nenhum resultado encontrado" : "Nenhum motorista cadastrado"}
                  isClearable
                  styles={{
                    ...colourStyles,
                    control: base => ({
                      ...base,
                      borderRadius: 20,
                      border: "none",
                      "&:hover": {
                        border: "none"
                      }
                    }),
                    menuPortal: base => ({
                      ...base,
                      zIndex: 9999
                    })
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={size.grid3} data-cy="divSelectYear">
              <Paper elevation={0} className={classes.paper}>
                <Select
                  data-cy="selectYear"
                  placeholder="Ano"
                  options={yearOptions.reverse()}
                  styles={{
                    ...colourStyles,
                    control: base => ({
                      ...base,
                      borderRadius: 20,
                      border: "none",
                      "&:hover": {
                        border: "none"
                      }
                    }),
                    menuPortal: base => ({
                      ...base,
                      zIndex: 9999
                    })
                  }}
                  onChange={(item) => setSelectedYear(item)}
                  value={selectedYear}
                  defaultValue={selectedYear}
                  components={animatedComponents}
                />
              </Paper>
            </Grid>
            {!cutOffDatesOptions ? (
              <Grid item xs={size.grid3} data-cy="divSelectCutOfDate">
                <Paper elevation={0} className={classes.paper} style={{ padding: "19px 24px" }}>
                  <Typography>Não foram encontradas data de corte.</Typography>
                </Paper>
              </Grid>
            ) : (
              <Grid item xs={size.grid3}>
                <Paper elevation={0} className={classes.paper} data-cy="divCutOffDateSelect">
                  <Select
                    data-cy="cutOffDateSelect"
                    placeholder="Data de corte"
                    options={cutOffDatesOptions}
                    styles={{
                      ...colourStyles,
                      control: base => ({
                        ...base,
                        borderRadius: 20,
                        border: "none",
                        "&:hover": {
                          border: "none"
                        }
                      }),
                      menuPortal: base => ({
                        ...base,
                        zIndex: 9999
                      })
                    }}
                    components={animatedComponents}
                    onChange={(date) => setSelectedCutOffDate(date)}
                    value={selectedCutOffDate}
                  />
                </Paper>
              </Grid>
            )}
            <PermissionsGate scopes={["can_view_ranking_by_vehicle_group"]}>
              <Grid item xs={size.grid3} data-cy="divSelectOperation">
                <Paper elevation={0} className={classes.multiselect}>
                  <Select
                    placeholder="Selecione uma operação"
                    options={operations}
                    onChange={handleOperation}
                    noOptionsMessage={() => drivers ? "Nenhum resultado encontrado" : "Nenhuma operação cadastrada"}
                    isClearable
                    styles={{
                      ...colourStyles,
                      control: base => ({
                        ...base,
                        borderRadius: 20,
                        border: "none",
                        "&:hover": {
                          border: "none"
                        }
                      }),
                      menuPortal: base => ({
                        ...base,
                        zIndex: 9999
                      })
                    }}
                  />
                </Paper>
              </Grid>
            </PermissionsGate>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {rankingData ? (
            <TableResponsive
              options={{
                rowsPerPage: pagination.rowsPerPage,
                serverSide: true,
                count: pagination.count,
                onTableChange: handleTablePagination,
                isLoading: pagination.isLoading,
                page: pagination.page - 1,
                search: true,
                searchText: searchTerm,
                onSearchChange: handleSearch,
                filter: false,
                setRowProps: (row, dataIndex, rowIndex) => {
                  return (rankingData[rowIndex]?.position_today <= 3) ? {
                    style: {
                      borderBottom: `2px solid ${colors.palette.success.main}`
                    }
                  } : "";
                },
              }}
              columns={columns}
              data={rankingData}
              loading={loading}
              tableName="list-ranking"
            />
          ) :
            <Infobox />
          }
        </Grid>
      </PermissionsGate>
    </Aux>
  );
}