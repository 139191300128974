import React from "react";
import { Box, Grid, Typography } from "@mui/material";

// components
import Aux from "hoc/auxiliar";
import FaqForm from "components/FaqForm";
import PermissionsGate from "components/PermissionsGate";
import useStyles from "./styles";

const FaqManager = ({ mode = "add", title = 'Cadastro de perguntas FAQ', subtitle = 'Cadastre novas perguntas e respostas do FAQ na V3 e no aplicativo do motorista', submitLabel = 'Cadastrar', PermissionsScopes = ['can_create_faq'], defaultValues = {}, onSubmit }) => {
    const classes = useStyles();

    return (
        <Aux>
            <PermissionsGate scopes={PermissionsScopes}>
                <Grid container spacing={4}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box>
                            <Grid item xl={12} xs={12}>
                                <Typography variant="h1" className={classes.title}>
                                    {title}
                                </Typography>
                                <span className={classes.spanSubTitle}>
                                    {subtitle}
                                </span>
                                <FaqForm
                                    defaultValues={defaultValues}
                                    onSubmit={onSubmit}
                                    submitLabel={submitLabel}
                                />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </PermissionsGate>
        </Aux>
    );
};

export default FaqManager;
