import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  containerShift: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  generalContainer: {
    display: "flex", 
    justifyContent: "end"
  },
  containerInfobox: {
    height: 450, 
    display: 'flex', 
    alignItems: 'center',
  },
  infoBox: {
    fontSize: theme.typography.h3.fontSize,
  },
  paper: {
    borderRadius: 20,
    padding: theme.spacing(1),
    maxWidth: "850px",
    margin: "0 auto",
  },
  okButton: {
    borderRadius: 20,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 32,
    marginBottom: 32,
    marginLeft: 32,
  },
  performance: {
    borderRadius: 28,
    height: "90%", 
    display: "flex"
  },
  cardTitle: {
    marginBottom: 10, 
    fontWeight: 'bold'
  },
  indicatorCard: {
    marginHorizontal: 20,
  },
  btnPrint: {
    borderRadius: 22,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    "&:hover": {
      color: theme.palette.black,
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600,
    },
  },
  btnBack: {
    borderRadius: 22,
    boxShadow: "none",
  },
  btBarginTop: {},
  detailSegment: {
    marginBottom: 20
  },
  spacingContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  multiselect: {
    borderRadius: 20,
    maxWidth: 250,
    marginBottom: 20,
    boxShadow: `3px 5px 4px ${theme.palette.background.backgroundButton}`,
  },
  filterButton: {
    borderRadius: 20,
    backgroundColor: 'white',
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  },
  chartButtonText: {
    textTransform: 'initial',
    fontWeight: 700,
    fontSize: 14,
    paddingRight: 5
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.title,
    marginTop: 20,
    marginBottom: 8
  },
}));
