import React from 'react';
import { useHistory } from 'react-router';
import classNames from "classnames";
import { Button } from "@mui/material";

import { handleAmplitudeEvent } from "services/amplitude";

//styles
import useStyles from './styles';

function BackButton({ className }) {
  const classes = useStyles();
  const history = useHistory()
  return (
    <Button
      fullWidth
      color="primary"
      variant="contained"
      className={classNames(classes.btnBack, className)}
      onClick={() => {
        handleAmplitudeEvent('Backward Button Clicked')
        history.goBack() 
      }}
      data-cy="buttonBack" 
    >
      Voltar
    </Button>
  )
}

export default BackButton;

