import React, { useEffect, useState } from "react"
import { Box, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import dayjs from "dayjs";

// services
import { getUserHistory } from "services/user";

// components
import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import HistoricUserSkeleton from "components/Skeletons/HistoricUserSkeleton";
import TableResponsive from "components/TableResponsive/Table";
import PermissionsGate from "components/PermissionsGate";
import Widget from "components/Widget/Widget";
import { makeColumns } from "./tableColumns";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

// styles
import useStyles from "./styles"

const OPERATION_TYPES = {
  UPDATE: "Usuário modificado",
  INSERT: "Usuário criado",
};

const FIELD_NAMES = {
  name: "Nome",
  email: "Email",
  access_type: "Tipo de acesso",
};

const formatDateTime = (dateTimeString) => {
  return dayjs(dateTimeString).format('DD/MM/YYYY HH:mm');
};

const transformHistoryData = (data) => {
  if (!data || !Array.isArray(data)) return [];

  return data.map(item => {
    if (item.operation_type === "INSERT") {
      return {
        modification_type: OPERATION_TYPES.INSERT,
        modification: "Usuário criado",
        user: item.executed_by,
        datetime: formatDateTime(item.executed_at),
        raw_data: item
      };
    }

    if (item.operation_type === "UPDATE") {
      const oldData = item.old_data?.user_history_data || {};
      const newData = item.new_data?.user_history_data || {};
      const changes = [];

      Object.keys(newData).forEach((key) => {
        const oldValue = oldData[key];
        const newValue = newData[key];

        if (JSON.stringify(oldValue) !== JSON.stringify(newValue) && oldValue !== undefined) {
          changes.push({
            field: FIELD_NAMES[key] || key,
            oldValue: oldValue || "Nenhum valor",
            newValue: newValue || "Nenhum valor",
          });
        }
      });
      if (changes.length > 0) {
        return {
          modification_type: OPERATION_TYPES.UPDATE,
          modification: changes.map((change) =>
            `${change.field}: ${change.oldValue} | Alterado para: ${change.newValue}`
          ).join(", "),
          user: item.executed_by,
          datetime: formatDateTime(item.executed_at),
          raw_data: item
        };
      }
      return null;
    }
    return {
      modification_type: OPERATION_TYPES[item.operation_type] || item.operation_type,
      modification: "Operação desconhecida",
      user: item.executed_by,
      datetime: formatDateTime(item.executed_at),
      raw_data: item
    };
  }).filter(item => item !== null);
};

const HistoricUsers = () => {
  const { state } = useLocation();
  const [userHistory, setUserHistory] = useState([]);
  const classes = useStyles()
  const size = useWindowSize()
  const columns = makeColumns({ size });
  const [loading, setLoading] = useState(true);

  const fetchUserHistory = async () => {
    try {
      setLoading(true);

      if (!state.email) {
        toast.error("Email não informado");
        throw new Error('Email não informado')
      }
      const response = await getUserHistory(state.email);

      if (response.status !== 200) {
        throw new Error(response);
      }

      if (!response.data || !response.data.data || !Array.isArray(response.data.data)) {
        toast.error("Formato da resposta inválido");
        throw new Error('Formato da resposta inválido')
      }

      const transformedData = transformHistoryData(response.data.data);
      setUserHistory(transformedData);
    } catch (err) {
      console.log('error', err)
      toast.error(
        "Erro ao carregar histórico."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state?.email) {
      fetchUserHistory();
    } else {
      console.error('State sem email:', state);
      toast.error("Email não informado");
    }
  }, [state]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_history_users']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <Widget disableWidgetMenu title="Histórico de edição de usuários">
              {loading ? (
                <HistoricUserSkeleton />
              ) : (
                <TableResponsive
                  columns={columns}
                  data={userHistory}
                  tableName="historic-users"
                />
              )}
            </Widget>
            <Grid container item justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <BackButton className={classes.backButton} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  )
}

export default HistoricUsers