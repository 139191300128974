import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import classNames from "classnames";

// components
import Aux from "hoc/auxiliar";
import Widget from "components/Widget/Widget";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import ListFaqSkeleton from "components/Skeletons/ListFaqSkeleton";
import { makeColumns } from "./tableColumns";
import TableResponsive from "components/TableResponsive";

//services
import { getFaqs, deleteFaq } from "services/faq";

// store
import { useAppDispatch, useAppSelector, store } from 'store';
import { setFaqPaginationReducer, clearFaqPagination } from "store/features/faqSlice";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

// styles
import useStyles from "./styles";

const Faq = () => {
  var theme = useTheme();
  const dispatch = useAppDispatch();
  const { pagination } = useAppSelector((state) => state.faq);
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true)
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [faqs, setFaqs] = useState([]);

  const fetchFaqs = useCallback(async () => {
    dispatch(setFaqPaginationReducer({ isLoading: true }));
    try {
      setLoading(true);
      const updatedPagination = store.getState().faq.pagination;
      const response = await getFaqs('', updatedPagination.rowsPerPage, updatedPagination.page);

      if (response.status !== 200) {
        toast.error("Erro ao carregar FAQs");
        return;
      }
      const registers = response?.data?.data;
      const totalCount = response?.data?.totalCount || 0;
      if (registers) {
        setFaqs(registers);
        dispatch(
          setFaqPaginationReducer({
            isLoading: false,
            count: totalCount,
            page: updatedPagination.page,
            rowsPerPage: updatedPagination.rowsPerPage
          })
        );
      }
    } catch (error) {
      toast.error("Erro ao carregar FAQs");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchFaqs();
  }, [fetchFaqs]);

  const handleTablePagination = (action, tableState) => {
    switch (action) {
      case 'changePage':
        const newPage = tableState.page + 1;
        dispatch(setFaqPaginationReducer({ isLoading: true, page: newPage }));
        fetchFaqs()
        break;

      case 'changeRowsPerPage':
        dispatch(setFaqPaginationReducer({ isLoading: true, rowsPerPage: tableState.rowsPerPage, page: 1 }));
        fetchFaqs()
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    dispatch(clearFaqPagination());
  }, [dispatch]);

  const handleClickAddQuestion = () => history.push("/gofurther/add-faq");
  const handleEdit = (id) => history.push(`/gofurther/edit-faq/${id}`);
  const handleDelete = useCallback(() => {

    if (!selectedFaq?.id) return;

    Swal.fire({
      customClass: {
        popup: classes.modalContainer,
        title: classes.modalTitle,
        text: classes.modalText,
        confirmButton: classes.btnConfirm,
        cancelButton: classes.btnCancel,
      },
      title: "Tem certeza que deseja excluir essa pergunta e resposta cadastrada?",
      text: "Essa é uma ação irreversível, assim que confirmar a pergunta e resposta deixará de aparecer no FAQ (Perguntas frequentes) da V3 e/ou do aplicativo dos motoristas.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Voltar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteFaq(selectedFaq.id);
          if (response.status === 200) {
            Swal.fire({
              customClass: {
                popup: classes.modalContainer,
                title: classes.modalTitle,
                text: classes.modalText,
                confirmButton: classes.btnConfirmSuccess,
              },
              title: "Excluído!",
              text: "O registro foi excluído com sucesso",
              icon: "success",
              confirmButtonText: "OK"
            });
            setSelectedFaq(null);
            fetchFaqs();
          }
        } catch (error) {
          toast.error("Erro ao excluir item do FAQ.");
        }
      }
    });
  }, [selectedFaq]);

  const size = useWindowSize()
  const columns = makeColumns({ onEdit: handleEdit, onDelete: handleDelete, size });

  const handleSelectRow = (_, rowMeta) => {
    const selected = faqs[rowMeta.dataIndex];
    setSelectedFaq(selected);
  };

  const renderTable = useCallback(() => (
    <TableResponsive
      options={{
        serverSide: true,
        onRowClick: handleSelectRow,
        rowsPerPage: pagination.rowsPerPage,
        page: pagination.page - 1,
        count: pagination.count,
        onTableChange: handleTablePagination,
        setRowProps: (row, index) => {
          return selectedFaq && faqs[index]?.id === selectedFaq.id ? { style: { backgroundColor: '#FFF5CB', cursor: 'pointer', width: '100%' } } : null
        },
        search: false,
        filter: false,
      }}
      pointer={true}
      columns={columns}
      data={faqs}
      tableName="faq-list"
    />
  ), [columns, selectedFaq, faqs, pagination, handleTablePagination]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_faq']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <PermissionsGate scopes={['can_create_faq']}>
              <Grid container justifyContent="flex-end">
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickAddQuestion}
                  >
                    Cadastrar nova
                  </Button>
                </Grid>
              </Grid>
            </PermissionsGate>
            <Widget disableWidgetMenu title="Gerenciamento de perguntas FAQ">
              <span className={classes.spanSubTitle}>
                Gerencie, edite e cadastre novas perguntas e respostas do FAQ na V3 e no aplicativo do motorista
              </span>
              {loading ? <ListFaqSkeleton /> : renderTable()}
              {/* botoes */}
              {size.mobile ?
                <PermissionsGate scopes={['can_edit_faq', 'can_remove_faq']}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid container justifyContent="flex-end" spacing={2}>
                      <PermissionsGate scopes={['can_edit_faq']}>
                        <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                          <Box >
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              onClick={() => selectedFaq && handleEdit(selectedFaq.id)}
                              disabled={!selectedFaq}
                              className={classes.buttonDetail}
                            >
                              Editar
                            </Button>
                          </Box>
                        </Grid>
                      </PermissionsGate>
                      <PermissionsGate scopes={['can_remove_faq']}>
                        <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                          <Box >
                            <Button
                              fullWidth
                              variant="outlined"
                              className={classNames(
                                classes.buttonDetail,
                                { [classes.buttonDelete]: selectedFaq }
                              )}
                              onClick={handleDelete}
                              disabled={!selectedFaq}
                            >
                              Excluir
                            </Button>
                          </Box>
                        </Grid>
                      </PermissionsGate>
                    </Grid>
                  </Grid>
                </PermissionsGate> : null}
            </Widget>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default Faq;
