import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { postAnassignVehiclesUser } from "services/user";
import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";

const UpdateVehiclesDialog = ({ isOpen, onClose, selectedVehicles, userEmail, plateFromRemovedVehicles, plateFromSelectedVehicles }) => {
  const methods = useForm();
  
  const handleAssign = async () => {
    try {
  
      const data = {
        email: userEmail,
        vehicles: selectedVehicles
      }
  
      const response = await postAnassignVehiclesUser(data);
      if (response.status !== 200) {
        throw new Error(response);
      }
  
      toast.success("Vínculos atualizados com sucesso!");
      onClose?.(true);
    } catch (error) {
      console.error("Error during assigning vehicles to user:", error);
      toast.error("Erro ao atualizar o vínculo de veículos ao usuário. Entre em contato com o suporte!");
    }
  };
  
  const getCustomTemplate = () => (
    <Box>
      {plateFromSelectedVehicles.length > 0 &&
        <>
          <Typography align="center">
            Adicionar o vínculo dos veículos:
          </Typography>
          <Typography align="center" sx={{ fontWeight: "bold", margin: "8px" }}>
            {plateFromSelectedVehicles.join(", ")}
          </Typography>
        </>
      }
      {plateFromRemovedVehicles.length > 0 &&
        <>
          <Typography align="center">
            Remover o vínculo dos veículos:
          </Typography>
          <Typography align="center" sx={{ fontWeight: "bold", margin: "8px" }}>
            {plateFromRemovedVehicles.join(", ")}
          </Typography>
        </>
      }
      <Typography align="center">
        Você confirma essa atualização ao usuário <b>{userEmail}</b>?
      </Typography>
    </Box>
  );

  const getTitle = () => {
    let partOfTitle = 'vínculo';

    if (plateFromRemovedVehicles.length > 0 && plateFromSelectedVehicles.length > 0) {
      partOfTitle = 'vínculo e desvínculo'
    }
    if (plateFromRemovedVehicles.length > 0) {
      partOfTitle = 'desvínculo'
    }

    return `Confirmar ${partOfTitle} de veículo ao usuário`;
  }

  return (
    <FormProvider {...methods}>
      <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "16px",
            padding: "24px",
          },
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{
                fontWeight: "bold",
                fontSize: "1.25rem",
                color: "black",
                flexGrow: 1,
              }}
            >
              {getTitle()}
            </Typography>
            <IconButton
              onClick={onClose}
              size="small"
              sx={{
                marginRight: "-12px",
                marginTop: "-12px",
                color: "#909198",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogActions>
          <Box sx={{ width: "100%" }}>
            <DialogContent>{getCustomTemplate()}</DialogContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "16px auto 0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "0 auto",
                }}
              >
                <Button
                  onClick={() => handleAssign()}
                  variant="contained"
                  color="secondary"
                  sx={{
                    textTransform: "none",
                    margin: "0 8px 0 auto",
                  }}
                >
                  Salvar alterações
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default UpdateVehiclesDialog;
