import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Box,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomizedPedalInput from "components/CustomizedPedalInput";
import { updatePedalConfig } from "services/customers";
import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";

const UpdateVehiclesDialog = ({ isOpen, onClose, vehiclesData, selectedVehicles }) => {
  const methods = useForm();
  const [activeStep, setActiveStep] = useState(0);
  const [pedalPressureLow, setPedalPressureLow] = useState(60);
  const [pedalPressureMid, setPedalPressureMid] = useState(70);

  const customizedData = vehiclesData
    .filter(
      (vehicle) =>
        vehicle.source === "vehicle" && selectedVehicles.includes(vehicle.vehicle_id)
    )
    .map((vehicle) => vehicle.plate);

  const nonVehicleSources = vehiclesData
    .filter(
      vehicle =>
        selectedVehicles.includes(vehicle.vehicle_id) && vehicle.source !== "vehicle"
    )
    .map(vehicle => vehicle.vehicle_id);

  const steps = ["Configurar pedal personalizado", "Escolher ação"];

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const getCommonOperationId = (selectedVehicles, vehiclesData) => {
    const selectedData = vehiclesData.filter(vehicle =>
      selectedVehicles.includes(vehicle.vehicle_id)
    );
  
    if (selectedData.length === 0) return null;
  
    const firstOperationId = selectedData[0]?.operation_id;
  
    const allSameOperationId = selectedData.every(
      vehicle => vehicle.operation_id === firstOperationId
    );
  
    if (!allSameOperationId) return null;
  
    const allVehiclesWithOperation = vehiclesData.filter(
      vehicle => vehicle.operation_id === firstOperationId
    ).map(vehicle => vehicle.vehicle_id);
  
    const allSelected = allVehiclesWithOperation.every(id =>
      selectedVehicles.includes(id)
    );
  
    return allSelected ? firstOperationId : null;
  };  
  
  const handleEditUncustomized = async (editAll) => {
    try {
      // Check for common operation ID
      const commonOperationId = getCommonOperationId(selectedVehicles, vehiclesData);
  
      // Prepare data to send
      let vehiclesDataToSend = {
        vehicles: editAll ? selectedVehicles : nonVehicleSources,
      };
  
      if (commonOperationId && selectedVehicles?.length > 1) {
        vehiclesDataToSend = {
          operationID: commonOperationId,
          onlyOperation: editAll ? false : true,
        };
      }
  
      const data = {
        ...vehiclesDataToSend,
        pedalPressureSettings: {
          low: Number(pedalPressureLow),
          mid: Number(pedalPressureMid),
        },
      };
  
      const response = await updatePedalConfig(data);
      if (response.status !== 200) {
        throw new Error(response);
      }
  
      toast.success("Pedal atualizado com sucesso");
      onClose?.(true);
    } catch (error) {
      console.error("Error during pedal update:", error);
      toast.error("Erro ao atualizar configurações do pedal. Entre em contato com o suporte!");
    }
  };
  

  const getCustomTemplate = () => (
    <Box>
      <Typography align="center" gutterBottom>
        Existem veículos com configurações personalizadas aplicadas a eles.
      </Typography>
      <Typography align="center" gutterBottom>
        Você quer:
      </Typography>
      <Typography align="center">
        Sobrepor as configurações de todos os veículos, incluindo aqueles com
        personalização, ou apenas alterar os veículos que ainda não possuem
        personalização?
      </Typography>
      <Typography align="center">
        Os veículos com configuração personalizada são:
      </Typography>
      <Typography align="center" sx={{ fontWeight: "bold", marginTop: "8px" }}>
        {customizedData.join(", ")}
      </Typography>
    </Box>
  );

  const getNormalTemplate = () => (
    <Box>
      <Typography align="center" gutterBottom>
        Não existem veículos com configurações personalizadas na sua seleção.
      </Typography>
    </Box>
  );

  useEffect(() => {
    setActiveStep(0);
  }, [isOpen]);

  const renderContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box sx={{ marginTop: "16px" }}>
            <CustomizedPedalInput
              pedalPressureLow={pedalPressureLow}
              setPedalPressureLow={setPedalPressureLow}
              pedalPressureMid={pedalPressureMid}
              setPedalPressureMid={setPedalPressureMid}
            />
          </Box>
        );
      case 1:
        return customizedData.length > 0 ? getCustomTemplate() : getNormalTemplate();
      default:
        return null;
    }
  };

  return (
    <FormProvider {...methods}>
      <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "16px",
            padding: "24px",
          },
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {activeStep > 0 && (
              <IconButton
                onClick={handleBack}
                size="small"
                sx={{
                  marginLeft: "-12px",
                  marginTop: "-12px",
                  color: "#575860",
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography
              variant="h6"
              align="center"
              sx={{
                fontWeight: "bold",
                fontSize: "1.25rem",
                color: "black",
                flexGrow: 1,
              }}
            >
              {steps[activeStep]}
            </Typography>
            <IconButton
              onClick={onClose}
              size="small"
              sx={{
                marginRight: "-12px",
                marginTop: "-12px",
                color: "#909198",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogActions>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <DialogContent>{renderContent()}</DialogContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: activeStep === 1 ? "space-between" : "flex-end",
                margin: "16px auto 0",
              }}
            >
              {activeStep === 0 && (
                <Box>
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    color="secondary"
                    sx={{ textTransform: "none" }}
                  >
                    Avançar
                  </Button>
                </Box>
              )}
              {activeStep === 1 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "0 auto",
                  }}
                >
                  <Button
                    onClick={() => handleEditUncustomized(true)}
                    variant="contained"
                    color="secondary"
                    sx={{
                      textTransform: "none",
                      margin: "0 8px 0 auto",
                    }}
                  >
                    Editar todos
                  </Button>
                  <Button
                    onClick={() => handleEditUncustomized(false)}
                    variant="outlined"
                    color="secondary"
                    sx={{
                      textTransform: "none",
                      margin: "0 auto 0 8px",
                      borderWidth: "1.5px"
                    }}
                    disabled={!(customizedData?.length > 0) || !(nonVehicleSources?.length > 0)}
                  >
                    Editar não personalizados
                  </Button>
                </Box>
              )}
              {activeStep === 2 && (
                <Button
                  onClick={() => setActiveStep(0)}
                  variant="contained"
                  color="secondary"
                  sx={{ textTransform: "none" }}
                >
                  Atualizar
                </Button>
              )}
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default UpdateVehiclesDialog;
