export const insertSortedPagination = (value) => {
  const arr = [5, 10, 25, 50, 100];

  if (arr.includes(value)) return arr;

  const index = arr.findIndex(num => num > value);
  return index === -1 ? [...arr, value] : [...arr.slice(0, index), value, ...arr.slice(index)];
};

export const sortCompareFunction = (order) => {
  return (obj1, obj2) => {
    const a = obj1.data ? obj1.data.toString() : "";
    const b = obj2.data ? obj2.data.toString() : "";
    return order === "asc" ? a.localeCompare(b) : b.localeCompare(a);
  };
};
  
export const sortCompareNumberFunction = (order) => {
  return (obj1, obj2) => {
    const a = parseFloat(obj1.data) || 0;
    const b = parseFloat(obj2.data) || 0;
    return order === "asc" ? a - b : b - a;
  };
};

export const sortComparePercentageFunction = (order, columnName, columns) => {
  return (obj1, obj2) => {
    const columnIndex = columns.findIndex(column => column.name === columnName);
    const a = parseFloat(obj1.rowData[columnIndex]?.duration) || 0;
    const b = parseFloat(obj2.rowData[columnIndex]?.duration) || 0;

    return order === "asc" ? a - b : b - a;
  };
};

  