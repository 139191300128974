import { makeStyles } from "@mui/styles";
import { alpha } from '@mui/material/styles';

export default makeStyles((theme) => ({
    containerInfobox: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
    },
    paper: {
        width: '100%',
        maxWidth: '700px',
        padding: theme.spacing(6),
        borderRadius: '1rem',
        position: 'relative'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    title: {
        textAlign: 'center',
        fontSize: theme.typography.h4.fontSize,
        marginBottom: theme.spacing(2),
        fontWeight: 'bold'
    },
    subtitle: {
        textAlign: 'center',
        marginBottom: theme.spacing(4)
    },
    highlight: {
        fontWeight: 'bold'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2)
    },
    primaryButton: {
        width: '60%',
        maxWidth: '28rem',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '2rem',
        "&:hover": {
            backgroundColor: alpha(theme.palette.secondary.main, 0.8),
            fontWeight: 600,
        },
    },
    secondaryButton: {
        borderRadius: '2rem',
        width: '60%',
        maxWidth: '28rem',
        border: '1px solid #FFD31C',
        color: "#595959",
        backgroundColor: "transparent",
        marginTop: '1rem',
        '&:hover': {
            borderColor: '#FFD31C'
        },
    },
}));
