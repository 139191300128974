import FaqManager from "components/FaqManager";
import Swal from "sweetalert2";
import useStyles from "./styles";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { createFaq } from "services/faq";

const AddFaq = () => {
  const classes = useStyles();
  const history = useHistory();

  const onSubmit = async (data) => {
    try {
      const formattedData = {
        question: data.question,
        answer: data.answer,
        is_V3: data.v3 || false,
        is_App: data.app || false,
        tags: data.tags.map(tag => tag.value),
      };
      const response = await createFaq(formattedData);
      return response;
    } catch (error) {
      console.error('Erro ao criar FAQ:', error);
      toast.error("Erro ao cadastrar FAQ");
      throw error;
    }
  };

  const handleAddFaq = async (data) => {
    await onSubmit(data);
    Swal.fire({
      customClass: {
        popup: classes.modalContainer,
        title: classes.modalTitle,
        text: classes.modalText,
        confirmButton: classes.btnConfirm,
        cancelButton: classes.btnCancel,
      },
      title: "Pergunta e resposta cadastrada com sucesso!",
      text: "Em breve, a pergunta e sua respectiva resposta estarão disponíveis na seção de FAQ (Perguntas Frequentes) do ambiente cadastrado.",
      reverseButtons: true,
      icon: "success",
      showCancelButton: true,
      confirmButtonText: "Cadastrar nova",
      cancelButtonText: "Ir para Gerenciamento",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      } else {
        history.push("/gofurther/faq-list");
      }
    });
  };

  return (
    <FaqManager
      mode="add"
      onSubmit={handleAddFaq}
      permissionsScope="can_create_faq"
    />
  );
};

export default AddFaq;