import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Button, Box, Typography } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from "react-toastify";
import { format, startOfMonth, subMonths } from 'date-fns';

// store
import { useAppSelector } from "store";

// components
import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import FormVehicleGroupComponent from "components/FormVehicleGroupComponent";
import LinkVehicleGroupComponent from "components/LinkVehicleGroupComponent";
import OperationRegisterSkeleton from "components/Skeletons/OperationRegister";
import ModalCreateOperations from "components/ModalCreateOperations";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

import operationValidation from "domain/validation/yup/operationValidation";
import operationValidationMinScore from "domain/validation/yup/operationValidationMinScore";
import fields from "domain/forms/operationInitialValues";

import makeDefaultValues from "helpers/makeDefaultValues";

// services
import { getOperationById, updateOperation } from "services/operations";
import { getVehiclesByOperation } from "services/vehicle";
import { handleAmplitudeEvent } from "services/amplitude";

// styles
import useStyles from "./styles";

const EditOperation = ({ history }) => {
  const { id } = useParams();
  const classes = useStyles();
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const [unavailableVehicles, setUnavailableVehicles] = useState([])
  const [availableVehicles, setAvailableVehicles] = useState([])
  const [selectedVehicles, setSelectedVehicles] = useState([])
  const [loading, setLoading] = useState(true);
  const [dialogData, setDialogData] = useState({});
  const [dataOld, setDataOld] = useState({});
  const [open, setOpen] = useState(false);

  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      rewardFormat: 'mileageReward',
      enabledExtraReward: false,
      considerScore: false,
    },
    resolver: hasPermission({ scopes: ['can_create_minscore'] }) ? yupResolver(operationValidationMinScore) : yupResolver(operationValidation)
  });

  const onClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (data) => {
    setLoading(true);

    let model = {
      ...dialogData,
      ...data,
      startDate: ''
    }

    if (data?.startDate) {
      const firstDayLastMonth = startOfMonth(subMonths(new Date(), 1));
      const formatFirstDayLastMonth = format(firstDayLastMonth, 'dd-MM-yyyy');

      model = {
        ...dialogData,
        ...data,
        startDate: formatFirstDayLastMonth
      }
    }

    onSubmitWithoutChanges(model);
  }

  const getAvailableVehicles = useCallback(async () => {
    try {
      const response = await getVehiclesByOperation(currentCustomer)
      const { customers: operations } = response.data
      let availableVehicles = []
      operations.forEach(op => {
        if (Number(op.id) === 0) {
          op.vehicles.forEach(vehicle => {
            availableVehicles.push(vehicle)
          })
        }
      })
      setAvailableVehicles([...new Set(availableVehicles)])
    } catch (error) {
      throw new Error()
    } finally {
      setLoading(false)
    }
  }, [currentCustomer])

  const fetchOperationById = useCallback(async (id) => {
    try {
      setLoading(true);

      const response = await getOperationById(id);

      if (response.status === 200 || response.status === 201) {
        const { data } = response.data;

        if (data.operation) {
          methods.setValue("customerId", data.operation.customerId);
          methods.setValue("targetMileage", data.operation.reward.targetMileage);
          methods.setValue("maxAmount", data.operation.reward.maxAmount);

          if (data.operation.minScore !== undefined) {
            methods.setValue("minScore", data.operation.minScore);
          }

          methods.setValue("name", data.operation.name);
          methods.setValue("id", data.operation.id);
          methods.setValue("operationTypeOption", data.operation.operationType);
          methods.setValue("requirementLevelOption", data.operation.requirementLevel);
          
          methods.setValue("enabledExtraReward", data.operation.enabledExtraReward);
          methods.setValue("extraTargetMileage", data.operation?.extraTargetMileage ?? undefined);
          methods.setValue("extraRewardMileage", data.operation?.extraRewardMileage ?? undefined);
          methods.setValue("extraIntervalMileage", data.operation?.extraIntervalMileage ?? undefined);
          methods.setValue("considerScore", data.operation?.considerScore ?? undefined);

          if (data.operation.rewardFormat) {
            if (data.operation.rewardFormat === "scoreMinimunReward") {
              methods.setValue("minScore", data.operation.minScore);
            } else if (data.operation.rewardFormat === "scoreMediumReward" && data.operation.minProportionalScore !== undefined) {
              methods.setValue("minProportionalScore", data.operation?.minProportionalScore?.toString());
            } else if (data.operation.rewardFormat === "range") {
              if (data.operation.rewardRange) {
                methods.setValue("rewardRange", data.operation.rewardRange);
              }
            }

            methods.setValue("rewardFormat", data.operation.rewardFormat);
            methods.setValue("minMileage", data.operation.minMileage);
          }

          //cadastra os dados para realizar a validação, caso tenha algum dado diferente
          setDataOld({
            customerId: data.operation.customerId,
            requirementLevelOption: data.operation.requirementLevel,
            targetMileage: data.operation.reward.targetMileage.toString(),
            maxAmount: data.operation.reward.maxAmount.toString(),
            minScore: data.operation.minScore ? data.operation.minScore.toString() : undefined,
            name: data.operation.name,
            id: data.operation.id,
            operationTypeOption: data.operation.operationType,
            rewardFormat: data.operation.rewardFormat,
            minProportionalScore: data.operation?.minProportionalScore ? data.operation.minProportionalScore.toString() : "",
            rewardRange: data.operation.rewardRange ? JSON.stringify(data.operation.rewardRange) : "",
            enabledExtraReward: data.operation.enabledExtraReward,
            extraTargetMileage: data.operation?.extraTargetMileage ? data.operation.extraTargetMileage : undefined,
            extraRewardMileage: data.operation?.extraRewardMileage ? data.operation.extraRewardMileage : undefined,
            extraIntervalMileage: data.operation?.extraIntervalMileage ? data.operation.extraIntervalMileage : undefined,
            considerScore: data.operation?.considerScore ? data.operation.considerScore : undefined,
          });

          if (data.operation.vehicles) {
            const vehiclesUnavailable = data.operation.vehicles.map((vehicle) => ({
              ...vehicle,
              id: vehicle.vehicleId,
            }));
            setUnavailableVehicles([...new Set(vehiclesUnavailable)])
          } else {
            setUnavailableVehicles([])
          }
        }
      } else {
        return toast.error("Erro ao buscar os dados da operação. Contate o suporte!");
      }
    } catch (error) {
      return toast.error("Erro estranho aconteceu. Contate o suporte!");
    } finally {
      setLoading(false)
    }
  }, [id])

  //Registra os veiculos que já existem
  useEffect(() => {
    if (dataOld.vehicles === undefined) {
      setDataOld({
        ...dataOld, vehicles: selectedVehicles?.map((vehicle) => ({
          vehicleId: vehicle.id,
        })),
      })
    }
  }, [selectedVehicles])

  const handleSelectedVehicles = (selected) => setSelectedVehicles(selected);

  const onSubmit = async (data) => {
    const vehicles = selectedVehicles?.map((vehicle) => ({
      vehicleId: vehicle.id,
    }));

    const isOtherDataChanged =
      data.customerId !== dataOld.customerId ||
      data.requirementLevel.value !== dataOld.requirementLevelOption ||
      data.targetMileage !== dataOld.targetMileage ||
      data.maxAmount !== dataOld.maxAmount ||
      data.minScore !== dataOld.minScore ||
      data.id !== dataOld.id ||
      JSON.stringify(vehicles) !== JSON.stringify(dataOld.vehicles) ||
      data.operationType.value !== dataOld.operationTypeOption ||
      data.enabledExtraReward !== dataOld.enabledExtraReward ||
      data.extraTargetMileage !== dataOld.extraTargetMileage ||
      data.extraRewardMileage !== dataOld.extraRewardMileage ||
      data.extraIntervalMileage !== dataOld.extraIntervalMileage ||
      data.considerScore !== dataOld.considerScore ||
      data.minMileage !== dataOld.minMileage

    if (isOtherDataChanged) {
      setDialogData(data)
      setOpen(true)
    } else {
      onSubmitWithoutChanges(data)
    }
  }

  const onSubmitWithoutChanges = async (data) => {
    let submitModel = {
      customerId: Number(data.customerId),
      sourceId: Number(data.id),
      rewardFormat: data?.rewardFormat,
      name: data.name,
      reward: {
        maxAmount: Number(data.maxAmount),
        targetMileage: Number(data.targetMileage)
      },
      operationType: Number(data.operationType.value),
      requirementLevel: Number(data.requirementLevel.value),
      vehicles: selectedVehicles?.map((vehicle) => ({
        vehicleId: vehicle.id,
      })),
      recalculate_past: data.recalculate_past,
      startDate: data.startDate,
      enabledExtraReward: data.enabledExtraReward,
      extraTargetMileage: data.enabledExtraReward ? data.extraTargetMileage : undefined,
      extraRewardMileage: data.enabledExtraReward ? data.extraRewardMileage : undefined,
      extraIntervalMileage: data.enabledExtraReward ? data.extraIntervalMileage : undefined,
      considerScore: data.enabledExtraReward && data.considerScore,
      minMileage: data.minMileage
    };

    if (data.rewardFormat === "scoreMinimunReward") {
      submitModel = {
        ...submitModel,
        minScore: data.minScore ? Number(data.minScore) : 0,
      };
    } else if (data.rewardFormat === "scoreMediumReward") {
      submitModel = {
        ...submitModel,
        minProportionalScore: data.minProportionalScore ? Number(data.minProportionalScore) : 0,
      };
    } else if (data.rewardFormat === "range") {
      submitModel = {
        ...submitModel,
        rewardRange: data.rewardRange ? data.rewardRange.map(it => ({
          range: it.range,
          reward: Number(it.reward)
        })) : [],
      };
    }

    //verificaçao de veiculos selecionados
    //os veiculos que permanecerem inalterados, devem ser retirados do array a ser enviado
    let vehiclesToCompare = [...unavailableVehicles];
    let currentSelectedsVehicles = [...selectedVehicles];
    //Caso estamos em Edit e a lista de veiculos selecionados é 0, isso quer dizer que todos os veiculos que estavam antes nela(unavailableVehicles), 
    //devem ser deletados
    if (currentSelectedsVehicles.length === 0) {
      currentSelectedsVehicles = vehiclesToCompare.map((item) => ({
        vehicleId: item.id,
        delete: true,
      }));
    } else {
      vehiclesToCompare.forEach((vehicle) => {
        const findedVehicle = currentSelectedsVehicles.find(
          (truck) => truck.plate === vehicle.plate,
        );
        if (findedVehicle) {
          currentSelectedsVehicles = currentSelectedsVehicles.filter(
            (truck) => truck.plate !== vehicle.plate,
          );
        } else {
          currentSelectedsVehicles.push({
            id: vehicle.id,
            delete: true,
          });
        }
      });

      const submitData = {
        ...submitModel,
        vehicles: currentSelectedsVehicles.map((truck) => ({
          ...truck,
          vehicleId: truck.id,
        })),
        id: data.id,
      };

      await onSubmitUpdate(submitData);
    }
  };

  const onSubmitUpdate = async (data) => {
    const response = await updateOperation(data);
    if (response.status === 200 || response.status === 201) {
      handleAmplitudeEvent('Vehicle Group Edited');
      toast.success("Operação atualizado com sucesso");
      history.push(`/gofurther/maintenance/operations/operationList`)
    } else {
      toast.error("Erro ao editar Operação. Verifique com o suporte");
    }
    setLoading(false)
  };

  useEffect(() => {
    if (hasPermission({ scopes: ['can_edit_operations'] }) && currentCustomer) {
      getAvailableVehicles()
    }
  }, [currentCustomer, getAvailableVehicles])

  useEffect(() => {
    if (hasPermission({ scopes: ['can_edit_operations'] })) {
      id && fetchOperationById(id);
    }
  }, [id, fetchOperationById]);

  useEffect(() => {
    handleAmplitudeEvent('Edit Vehicle Group Screen Viewed');
  }, []);

  if (loading) return <OperationRegisterSkeleton />
  return (
    <Aux>
      <PermissionsGate scopes={['can_edit_operations']}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box sx={{ m: 1, mt: 4 }}>
                    <Typography variant='h1' className={classes.title}>Grupo de veículos</Typography>
                    <FormVehicleGroupComponent
                      register={methods.register}
                    />
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box sx={{ m: 1, mt: 4 }}>
                    <Typography variant="h1" className={classes.title}>Associar veículos ao grupo</Typography>
                    <LinkVehicleGroupComponent
                      handleSelectedVehicles={handleSelectedVehicles}
                      availableVehicles={availableVehicles}
                      unavailableVehiclesByOperation={unavailableVehicles}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid container item justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                      <Box sx={{ m: 2 }}>
                        <BackButton className={classes.backButton} />
                      </Box>
                    </Grid>
                    <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                      <Box sx={{ m: 2 }}>
                        <Button
                          fullWidth
                          variant="contained"
                          type="submit"
                          className={classes.btConfirm}
                          disabled={loading}
                        >
                          Salvar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </PermissionsGate>
      <ModalCreateOperations
        onClose={onClose}
        open={open}
        onSubmit={handleSubmit}
        loading={loading}
      />

    </Aux>
  );
};

export default EditOperation;

