import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import { toast } from "react-toastify";
import dayjs from "dayjs";

// store
import { useAppSelector } from "store";

// components
import Aux from "hoc/auxiliar";
import Table from "components/Table";
import ModalDevice from "components/ModalDevice";
import Widget from "components/Widget/Widget";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import FormRoleSkeleton from "components/Skeletons/FormRoleSkeleton";
import { makeColumns } from "./tableColumns";
import TableResponsive from "../../../components/TableResponsive";

// hooks
import { useWindowSize } from "../../../hooks/useWindowsSize";

// services
import { getVehicleDeviceHistory, getVehicles } from "services/vehicle";

// styles
import useStyles from "./styles";

const ListVehicles = () => {
  const classes = useStyles();
  const history = useHistory();
  const { currentCustomer } = useAppSelector((state) => state.global.user);

  const [openModalDevice, setOpenModalDevice] = useState(false);
  const [currentDevice, setCurrentDevice] = useState({});
  const [loading, setLoading] = useState(true);
  // const [customers, setCustomers] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [vehiclesDevices, setVehiclesDevices] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rowData, setRowData] = useState({});
  const [download, setDownload] = useState({
    link: "",
    fileName: "",
  });
  const [selectedRowData, setSelectedRowData] = useState();
  const [id, setId] = useState();

  const fetchVehicles = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getVehicles(currentCustomer);
      setVehicles(response.data.customers);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de Veículos. Entre em contato com o suporte.",
      );
    } finally {
      setLoading(false);
    }
  }, [currentCustomer]);

  const handleClickAddVehicle = () => history.push("/gofurther/vehicle/form-vehicle");
  const handleClickMassAddVehicle = () => history.push("/gofurther/vehicle/mass-add-vehicle");
  const handleRecalculation = () => history.push("/gofurther/vehicle/recalculation");
  const handleViewPedalConfig = () => history.push("/gofurther/vehicle/pedal-config");
  const handleEdit = (value) => history.push(`/gofurther/vehicle/form-vehicle/${value}`);
  const handleCloseModalDevice = () => setOpenModalDevice(false);

  const handleChangeStatus = async (id) => {
    /* TODO - mudar situação */
    toast.info("Essa função será disponibilizada em breve.");
  };

  const handleLinkDevices = async (id) => {
    try {
      setLoading(true);
      const response = await getVehicleDeviceHistory(id);
      if (!response) {
        toast.error(
          "Você não possui histórico ainda. Entre em contato com o suporte.",
        );
        setVehiclesDevices();
        return;
      }
      if (!response.data.data.vehiclesDevices) {
        setVehiclesDevices();
      } else {
        const newResponse = response.data.data.vehiclesDevices.map(
          (devices) => {
            const deviceId = devices.deviceId;
            return { ...devices, deviceId };
          },
        );
        setVehiclesDevices(newResponse);
      }
    } catch (error) {
      toast.error(
        "Você não possui histórico ainda. Entre em contato com o suporte.",
      );
      return;
    } finally {
      setLoading(false);
    }
  };

  const handleVehicle = async (id) => {
    try {
      let selectedVehicle = vehicles.filter((vehicle) => {
        return vehicle.id === id;
      })[0];
      const data = {
        identification: selectedVehicle.identification,
        brand: selectedVehicle.brand,
        simcard: selectedVehicle.simcard,
        status: selectedVehicle.currentDevice,
      };
      setCurrentDevice(data);
    } catch (error) {}
  };

  const addBondHandler = (id) => {
    setRowData(id);
    handleLinkDevices(id);
    handleVehicle(id);
    setOpenModalDevice(true);
  };

  const size = useWindowSize();

  const columns = makeColumns({
    onEdit: handleEdit,
    onChangeStatus: handleChangeStatus,
    onAddBond: addBondHandler,
    size,
  });

  useEffect(() => {
    if (hasPermission({ scopes: ["can_view_vehicles"] }) && currentCustomer) {
      setDownload({
        link: `/vehicles/export?customers=${currentCustomer}`,
        fileName: `veiculos_${dayjs().format("DD-MM-YYYY_HH-mm")}.xlsx`,
      });
      fetchVehicles();
    }
  }, [currentCustomer, fetchVehicles]);

  const handleSelectRow = (data, data2) => {
    setId(data2.dataIndex);
    const id = vehicles[data2.dataIndex].id;
    setSelectedRowData(id);
  };

  const renderTable = useCallback(
    () =>
      !size.mobile ? (
        <Table
          columns={columns}
          data={vehicles}
          download={download}
          tableName="list-vehicles"
          options={{ rowsPerPage }}
          setRowsPerPage={setRowsPerPage}
        />
      ) : (
        <>
          <TableResponsive
            columns={columns}
            data={vehicles}
            download={download}
            tableName="list-vehicles"
            options={{
              onRowClick: handleSelectRow,
              rowsPerPage: rowsPerPage,
              setRowProps: (row, index) => {
                return index === id
                  ? {
                      style: {
                        backgroundColor: "#FFF5CB",
                        cursor: "pointer",
                        width: "100%",
                      },
                    }
                  : null;
              },
            }}
            pointer={true}
            setRowsPerPage={setRowsPerPage}
          />
          {/* botoes */}
          <PermissionsGate
            scopes={[
              "can_edit_vehicles",
              "can_activate_vehicles",
              "can_edit_vehicledevice",
            ]}
          >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <PermissionsGate scopes={["can_edit_vehicles"]}>
                  <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                    <Box>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => handleEdit(selectedRowData)}
                        disabled={!selectedRowData}
                        className={classes.buttonDetail}
                      >
                        Editar
                      </Button>
                    </Box>
                  </Grid>
                </PermissionsGate>
                <PermissionsGate scopes={["can_activate_vehicles"]}>
                  <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                    <Box>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          handleChangeStatus(selectedRowData);
                        }}
                        disabled={!selectedRowData}
                        className={classes.buttonDetail}
                      >
                        Ativar/Inativar
                      </Button>
                    </Box>
                  </Grid>
                </PermissionsGate>
                <PermissionsGate scopes={["can_edit_vehicledevice"]}>
                  <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                    <Box>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={()=>{
                          const url = '/gofurther/device/vehicle-devices?vehicleId=' + selectedRowData
                          history.push(url);
                        }}
                        disabled={!selectedRowData}
                        className={classes.buttonDetail}
                      >
                        Vincular unidade eletrônica
                      </Button>
                    </Box>
                  </Grid>
                </PermissionsGate>
                
              </Grid>
            </Grid>
          </PermissionsGate>
        </>
      ),
    [
      size.mobile,
      columns,
      vehicles,
      download,
      rowsPerPage,
      handleSelectRow,
      selectedRowData,
      classes.buttonDetail,
      id,
      handleEdit,
    ],
  );

  return (
    <Aux>
      <PermissionsGate scopes={["can_view_vehicles"]}>
        <Grid container spacing={4}>
          <ModalDevice
            device={currentDevice}
            loading={loading}
            open={openModalDevice}
            handleClose={handleCloseModalDevice}
            data={rowData}
            vehiclesDevices={vehiclesDevices}
            tableName="modal-list-vehicles"
          />
          <Grid item xs={12} className={classes.tableContent}>
            <Grid container justifyContent="flex-end">
            <PermissionsGate scopes={["can_view_pedal_config_page"]}>
                <Grid
                  item
                  xl={2}
                  lg={3}
                  md={4}
                  sm={12}
                  xs={12}
                  className={classes.btItem}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleViewPedalConfig}
                  >
                    Detalhes do pedal
                  </Button>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={["can_recalculate_vehicles"]}>
                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={4}
                  sm={12}
                  xs={12}
                  className={classes.btItem}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleRecalculation}
                  >
                    Recalcular
                  </Button>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={["can_import_vehicles"]}>
                <Grid
                  item
                  xl={2}
                  lg={3}
                  md={4}
                  sm={12}
                  xs={12}
                  className={classes.btItem}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickMassAddVehicle}
                  >
                    Importar em Massa
                  </Button>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={["can_create_vehicles"]}>
                <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickAddVehicle}
                  >
                    Novo Veículo
                  </Button>
                </Grid>
              </PermissionsGate>
            </Grid>
            <Widget
              className={classes.widget}
              disableWidgetMenu
              title="Gerenciamento de veículos"
            >
              {loading ? <FormRoleSkeleton /> : vehicles && renderTable()}
            </Widget>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ListVehicles;
