import React from 'react';
import { Box, Paper, Grid, Typography, Button, IconButton } from "@mui/material";
import PermissionsGate from "components/PermissionsGate";
import { Close as CloseIcon } from "@mui/icons-material";
import useStyles from "./styles";

const NoDataBox = ({ onGoToDriverLink, onNewPeriod }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.containerInfobox}>
            <Paper elevation={0} className={classes.paper}>
                <IconButton
                    className={classes.closeButton}
                    onClick={onNewPeriod}
                    size="large"
                    aria-label="fechar"
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h5" className={classes.title}>
                    Não foram encontrados dados de viagem desse motorista para o período que você selecionou.
                </Typography>
                <Typography variant="body1" className={classes.subtitle}>
                    Caso ele tenha dirigido e esteja {' '}
                    <span className={classes.highlight}>VINCULADO</span> e, mesmo assim, a informação não apareça, entre em contato com nosso suporte.
                </Typography>
                <Box className={classes.buttonContainer}>
                    <PermissionsGate scopes={['can_view_linkdrivers']}>
                        <Button
                            variant="contained"
                            className={classes.primaryButton}
                            onClick={onGoToDriverLink}
                            fullWidth
                        >
                            Ir para tela de vínculo veículo/motorista
                        </Button>
                    </PermissionsGate>
                    <Button
                        variant="outlined"
                        className={classes.secondaryButton}
                        onClick={onNewPeriod}
                        fullWidth
                    >
                        Selecionar um novo período
                    </Button>
                </Box>
            </Paper>
        </Grid>
    );
};

export default NoDataBox;