import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pagination: {
    page: 1,
    rowsPerPage: 10,
    count: 1,
    isLoading: false,
  }
};

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    clearFaqPagination: (state) => {
      state.pagination = initialState.pagination
    },
    setFaqPaginationReducer: (state, action) => {
      const newPagination = {
        ...state.pagination,
        ...action.payload,
      };
      state.pagination = newPagination;
    },
  }
});

export const {
  clearFaqPagination,
  setFaqPaginationReducer,
} = faqSlice.actions;

export default faqSlice.reducer;
