import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import FilterList from '@mui/icons-material/FilterList';
import { virtualizedTable } from '../styles';

export function HeaderRow({
  columns,
  visibleColumns,
  firstColumnWidth,
  remainingWidth,
  onFilterClick,
  excludedForFilterColumns,
}) {
  const classes = virtualizedTable();

  const displayedColumns = visibleColumns
    .filter(col => col.display)
    .map(visibleCol => {
      const columnDetails = columns.find(col => col.name === visibleCol.name);
      return {
        ...visibleCol,
        label: columnDetails?.label || visibleCol.name,
      };
    });

  return (
    <Box className={classes.header}>
      {displayedColumns.map((column, index) => (
        <Box key={index} 
          sx={{
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center',    
          }} 
          width={index === 0 ? firstColumnWidth : `${remainingWidth}%`}>
          <Typography variant="subtitle2">
            {column.label}
            {!excludedForFilterColumns.includes(column.name) && (
              <IconButton
                size="small"
                onClick={(event) => onFilterClick(event, column)}
              >
                <FilterList fontSize="small"/>
              </IconButton>
            )}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
