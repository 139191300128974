import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useDebouncedCallback } from "use-debounce";

//services
import { getVehiclesActualBonds } from "services/vehicle";
import { getTelemetryDataByDevice } from "services/telemetry";
import { getDeviceStatus, getDevicesValidate } from "services/device";

// components
import Aux from "hoc/auxiliar";
import Calendar from "components/Calendar";
import MultiSelectTelemetry from "components/MultiSelectTelemetry";
import PermissionsGate from "components/PermissionsGate";
import TableResponsive from "components/TableResponsive";
import TelemetryDataSkeleton from "components/Skeletons/TelemetryDataSkeleton";
import Title from "componentsNew/atoms/title/title";
import Map from "components/Map";
import { findValueByKey } from "helpers/functions";
import { tableColumns } from "./tableColumns";
import { orderVehiclesByCrescent } from "helpers/vehicles";
import DeviceValidationCards from "components/DeviceValidationCards"
import NotFoundInfo from "components/NotFoundInfo";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

// styles
import useStyles from "./styles";

const DevicesData = () => {
  const { state } = useLocation();
  const classes = useStyles();
  const size = useWindowSize()
  const dateFormatTemplate = "YYYY-MM-DDTHH:mm:ssZZ";
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [vehicles, setVehicles] = useState([]);
  const [deviceStatus, setDeviceStatus] = useState(null);
  const [location, setLocation] = useState(null);
  const [loadingVehicles, setLoadingVehicles] = useState(false);
  const [loading, setLoading] = useState({
    vehicles: false,
    deviceData: true,
  });
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedDates, setSelectedDates] = useState({
    initialDate: dayjs().format("YYYY-MM-DDT00:00:00ZZ"),
    finalDate: dayjs().format("YYYY-MM-DDT23:59:59ZZ"),
  });
  const [deviceData, setDeviceData] = useState([]);
  const [deviceValidationData, setDeviceValidationData] = useState(null);
  const lastFetchedItem = useRef(null);
  const lastFetchedDates = useRef({ initialDate: null, finalDate: null });
  const [hasGNV, setHasGNV] = useState(false);
  const columns = tableColumns(size, hasGNV);

  const setVehiclesData = (returnData) => {
    if (state && returnData) {
      const foundedVehicle = returnData
        .map((o) => {
          o.vehicles.filter((truck) => truck.id === state.vehicleId.id)
        })
        .reduce((last, now) => last.concat(now))
        .reduce((_last, now) => now);

      const item = foundedVehicle?.currentDevice ? {
        identification: foundedVehicle.currentDevice.identification,
        driverName: foundedVehicle.currentDriver.driverName,
        id: foundedVehicle.id,
      } : {
        identification: foundedVehicle.imei,
        driverName: foundedVehicle.driver_name,
        id: foundedVehicle.vehicle_id,
      }
      setSelectedItem([item]);
    } else {
      setSelectedItem([]);
    }
    setVehicles(returnData);
  }

  const handleSearch = useDebouncedCallback(async (value) => {
    if (value.length > 4) {
      setLoading((oldState) => ({ ...oldState, vehicles: true }));

      try {
        const response = await getVehiclesActualBonds(value);
        let orderedVehiclesByCustomer = response.data?.data.sort(
          (a, b) => (a.name > b.name) - (a.name < b.name)
        );

        orderedVehiclesByCustomer.map((option, i) => {
          const optionVehicles = option.vehicles.filter(item => {
            const deviceId = item?.currentDevice?.identification;
            return item?.devicesHistory !== null && deviceId && deviceId.trim() !== '';
          });

          orderedVehiclesByCustomer[i].vehicles = orderVehiclesByCrescent(optionVehicles);
        });

        setVehiclesData(orderedVehiclesByCustomer);
      } catch (err) {
        console.log('err', err);

        toast.error(
          "Erro ao carregar lista de veículos. Entre em contato com o suporte.",
        );
      } finally {
        setLoading((oldState) => ({ ...oldState, vehicles: false }));
      }
    }
  }, 1000);

  // Responsavel por pegar as datas no componente pageToolbar.
  const handleSelectDate = (initialDate, finalDate) => {
    setSelectedDates({
      initialDate: dayjs(initialDate).format(dateFormatTemplate),
      finalDate: dayjs(finalDate).format(dateFormatTemplate),
    });
  };

  const getSelectedItem = (selectedVehicle) => {
    let selected = [];
    vehicles.forEach((operation) => {
      operation.vehicles.forEach((ident) => {
        if (ident.id === selectedVehicle[0].id) {
          selected = [{
            identification: ident.currentDevice?.identification,
            id: ident.id,
            name: ident?.identification || "",

          }];
        }
      });
    });
    setSelectedItem(selected);
  };

  const clearData = () => {
    setLocation(null);
    setDeviceStatus(null);
    setDeviceData([]);
  }

  const fetchData = async (imei, initialDate, finalDate) => {
    setLoading((oldState) => ({ ...oldState, deviceData: true }));
    try {
      const responseStatus = await getDeviceStatus(imei);
      if (responseStatus.status !== 200) {
        throw new Error();
      }
      const lastCommunication = findValueByKey(responseStatus, 'last_communication_datetime');
      if (lastCommunication) {
        const responseStatusData = responseStatus.data.data[0];
        const localTimeDate = new Date(Date.parse(lastCommunication + ' UTC'));
        setDeviceStatus({
          ...responseStatusData,
          last_communication_datetime: localTimeDate
        });
        setLocation([{
          device: responseStatusData.device_identification,
          locations: [{
            date: localTimeDate,
            lat: responseStatusData.latitude,
            lon: responseStatusData.longitude
          }],
        }])
      } else {
        setLocation(null);
        setDeviceStatus(null);
      }
      const response = await getTelemetryDataByDevice(imei, initialDate, finalDate);

      if (response.status !== 200) {
        throw new Error();
      }
      if (!response.data.data) {
        setDeviceData([]);
        return;
      }
      let checkGNV = false;
      const mappedData = response.data.data.map((row, idx) => {
        if (row?.total_fuel_consumption_gaseous) {
          checkGNV = true; // verifica dessa forma pois pode ser que a configuração de combustível do veículo tenha mudado
        }
        return {
          id: idx,
          ...row,
        };
      });
      setHasGNV(checkGNV);
      mappedData.splice(-1)
      setDeviceData(mappedData);
    } catch (err) {
      clearData();
      toast.error(
        "Erro ao carregar dados de conectividade. Entre em contato com o suporte.",
      );
    } finally {
      setLoading((oldState) => ({ ...oldState, deviceData: false }));
    }
  };

  const fetchDevicesValidate = async (imei) => {
    try {
      setLoading(true);
      const response = await getDevicesValidate(imei);
      if (response.status !== 200) {
        throw new Error(response)
      }
      setDeviceValidationData(response.data.validations);
    } catch (error) {
      toast.error(
        "Erro ao carregar dados do veículo. Entre em contato com o suporte.",
      );
      console.error('Erro ao buscar dados de validação', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const hasSelectedVehicles = selectedItem.length > 0;
    setLoadingVehicles(hasSelectedVehicles);

    if (hasSelectedVehicles) {
      const devices = selectedItem.map((vehicle) => vehicle.identification);
      const currentDevice = devices[0];

      if (currentDevice === '') {
        clearData();
        return;
      }

      const isNewDevice = lastFetchedItem.current !== currentDevice;
      const isNewDateRange = lastFetchedDates.current.initialDate !== selectedDates.initialDate ||
        lastFetchedDates.current.finalDate !== selectedDates.finalDate;

      if (isNewDevice || isNewDateRange) {
        fetchDevicesValidate(currentDevice);
        fetchData(currentDevice, selectedDates.initialDate, selectedDates.finalDate);

        lastFetchedItem.current = currentDevice;
        lastFetchedDates.current = { ...selectedDates };
      }
    }
  }, [selectedItem, selectedDates]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_devicesdata']}>
        <Grid container spacing={{ xs: 2, sm: 4 }} className={classes.generalBox}>
          <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
            <Title value={"Validador de dispositivo"} />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <MultiSelectTelemetry
              name="list-vehicles"
              isSingleMode
              listData={vehicles}
              handleSelectedListData={getSelectedItem}
              selectedData={selectedItem}
              detailedSelect
              placeholder={loading.vehicles ? "Carregando dados, aguarde..." : "Digite o id da unidade, nome ou placa da frota, ou nome do motorista"}
              noData={loading.vehicles ? "Carregando dados, aguarde..." : "Sem dados"}
              loading={loading.vehicles}
              noOptionsMessage={() => "Sem dados"}
              onInputChange={(data) => {
                if (!!data) handleSearch(data);
              }}
              cacheOptions
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className={classes.alignCenter}>
            <Calendar
              selectedDates={{
                initialDate: selectedDates.initialDate,
                finalDate: selectedDates.finalDate,
              }}
              handleCalendar={handleSelectDate}
            />
          </Grid>
        </Grid>
        {!loadingVehicles ? (
          <NotFoundInfo
            description="Para visualizar as informações da sua frota, digite o ID da unidade, nome ou a placa do veículo, ou o nome do motorista"
            strongDescription="no filtro acima."
            localStorageKey="infoBoxDriverDevicesData"
          />
        ) : (
          <>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                {deviceValidationData && (
                  <DeviceValidationCards
                    validations={deviceValidationData}
                    deviceStatus={deviceStatus}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {location ? (
                  <>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.mapTitle}>
                      <Title value={"Último ponto de conexão "} />
                    </Grid>
                    <Map
                      style={{ height: "300px", width: "100%" }}
                      data={location}
                      singlePoint={true}
                      zoomControl={true}
                      streetViewControl={false}
                      mapTypeControl={false}
                      customZoom={2}
                    />
                  </>
                ) : null}
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box>
                <Grid item xl={12} xs={12} className={classes.table}>
                  {loading?.deviceData && deviceData ?
                    <TelemetryDataSkeleton /> :
                    <TableResponsive
                      data={deviceData}
                      columns={columns}
                      tableName="list-telemetry"
                      pointer={true}
                      setRowsPerPage={setRowsPerPage}
                      options={{
                        rowsPerPageOptions: [5, 15, 25, 50, 100, 200, 500, 1000, 5000],
                        download: true,
                        rowsPerPage: rowsPerPage,
                        setRowProps: () => {
                          return { style: { backgroundColor: "white", cursor: 'default', width: '100%' } }
                        },
                      }}
                    />
                  }
                </Grid>
              </Box>
            </Grid>
          </>
        )}
      </PermissionsGate>
    </Aux>
  );
};

export default DevicesData;