import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  tableContent: {
    marginTop: theme.spacing(2)
  },
  backButton: {
    backgroundColor: theme.palette.background.backgroundButton
  }
}));
