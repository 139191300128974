import React from "react";
import { Typography } from "@mui/material";
import classnames from "classnames";
import { FormControlLabel, Checkbox } from '@mui/material';
import makeStyles from "./styles";

export const makeColumnsForPrint = () => {
  const classes = makeStyles();

  const getCell = (data, textBold) => {
    return (
      <Typography
        variant="body2"
        className={classnames({
          [classes.textBold]: textBold,
        })}
        noWrap={true}
      >
        {data || '-'}
      </Typography>
    );
  };

  return [
    {
      name: "operation_name",
      label: "Grupo de veículo",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "plate",
      label: "Placa",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "chassi",
      label: "Chassi",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "low_pressure",
      label: "Baixo",
      options: {
        customBodyRender: (value) => getCell(value ? value : 60, true),
      },
    },
    {
      name: "mid_pressure",
      label: "Médio",
      options: {
        customBodyRender: (value) => getCell(value ? value : 70, true),
      },
    },
    {
      name: "high_pressure",
      label: "Máxima",
      options: {
        customBodyRender: (value) => getCell(value ? value : 100, true),
      },
    },
  ];
};