import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation } from 'react-router-dom';
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Grid, Typography } from "@mui/material";

// assets
import ImageNewFeature from 'assets/images/controlDiesel.png';

// store
import { useAppDispatch, useAppSelector } from "store";
import { setVehicleFilters, clearVehicleFilters } from "store/features/vehicleDashboardSlice";

// services
import {
  getFleetLocations,
  getFleetChartData,
  getVehicleProfile,
} from "services/fleetPerformance";
import { getVehiclesByOperation } from "services/vehicle";
import { getFuelCheckMultiple, getActualCheckMultiple } from "services/fuel";
import { handleAmplitudeEvent } from "services/amplitude";

// helpers
import { getApproximateDate, parseChartData } from "helpers/fleetChart";
import { fuelVolumeLabel } from "helpers/fuel";

// components
import DieselControlInfo from "componentsNew/organisms/dieselControlInfo";
import StatusEmpty from "componentsNew/atoms/statusEmpty/statusEmpty";
import Card from "componentsNew/molecules/card/card";

import DieselControlSkeleton from "components/Skeletons/DieselControlSkeleton";
import { ModalDieselControl } from "componentsNew/organisms/modalDieselControl/modalDieselControl";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import TableResponsive from "components/TableResponsive";
import NewFeatureModal from "components/ModalNewFeature";
import { OperationsSelect } from "components/OperationsSelect";
import Calendar from "components/Calendar";

import { makeColumns } from "./tableColumns";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

// styles
import useStyles from "./styles";
import "./dieselControl.css";

const DieselControl = () => {
  const classes = useStyles();

  const size = useWindowSize();

  const dispatch = useAppDispatch();

  const { state } = useLocation();

  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const { filters } = useAppSelector((state) => state.vehicleDashboard);

  const storedFilters = useMemo(() => filters, []);

  const dbDateFormat = "YYYY-MM-DDTHH:mm:ssZZ";

  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [dieselData, setDieselData] = useState(null);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [loadingModalMap, setLoadingModalMap] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [chartData, setChartData] = useState([]);
  const [loadingModalChart, setLoadingModalChart] = useState(false);
  const [openNewFeature, setNewFeature] = useState(false);

  const MAX_DATE = dayjs().subtract(1, 'day').endOf('day').toDate();
  MAX_DATE.setHours(23, 59, 59, 0);

  const [selectedDates, setSelectedDates] = useState({
    initialDate: dayjs(MAX_DATE).startOf('day').format("YYYY-MM-DDT00:00:00ZZ"),
    finalDate: dayjs(MAX_DATE).format("YYYY-MM-DDT23:59:59ZZ"),
  });

  const dateFormatTemplate = "YYYY-MM-DDTHH:mm:ssZZ";

  const isSideMenuOnDashboard = window.location.pathname.includes('dashboard');

  const sanitizeVehicles = useCallback(vehicles => vehicles.map((vehicle) => {
    const vehicleLabel = `${vehicle.identification} - ${vehicle.plate}`;
    const driverLabel = vehicle.currentDriver?.driverName ? ` - (Motorista atual: ${vehicle.currentDriver.driverName})` : '';

    return {
      id: vehicle.id,
      label: `${vehicleLabel}${driverLabel}`,
      operation: {
        id: vehicle.operationId,
        label: vehicle.operation_name
      }
    };
  }), []);

  const handleVehiclesChange = useCallback((selected) => {
    setSelectedVehicles(selected);

    if (!selected.length) {
      setDieselData(null);
    }

    handleAmplitudeEvent(
      'Filter Updated',
      {
        label: "Frota",
        value: selected.map(item => item?.label).join(", ")
      }
    );
  }, [setSelectedVehicles, handleAmplitudeEvent]);

  const handleOperationSelect = useCallback((operation) => {
    const selectedWithoutOperationVehicles = selectedVehicles.filter(selectedVehicle => selectedVehicle.operation?.id !== operation?.id);
    const operationVehicles = sanitizeVehicles(vehicles).filter(vehicle => vehicle.operation.id === operation.id);

    setSelectedVehicles([...selectedWithoutOperationVehicles, ...operationVehicles]);
  }, [sanitizeVehicles, selectedVehicles, setSelectedVehicles]);

  const handleOperationDelete = useCallback((operationId) => {
    const updatedSelectedVehicles = selectedVehicles.filter(selectedVehicle => selectedVehicle.operation?.id !== Number(operationId));

    if (!updatedSelectedVehicles.length) {
      setDieselData(null);
    }

    setSelectedVehicles(updatedSelectedVehicles);
  }, [selectedVehicles, setSelectedVehicles]);

  const noIndicatorsChartToShow = () => {
    toast.error(
      "Algo inexperado aconteceu. Contate o suporte.",
    );
  };

  const handleModal = (open) => {
    setShowModal(open);
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      const vehicleIds = selectedVehicles?.map(item => item?.id) || filters?.vehicles?.map(item => item?.id);
      const vehicleNames = selectedVehicles?.map(item => item?.label) || filters?.vehicles?.map(item => item?.identification);

      if (vehicleIds?.length > 0) {
        const initialDate = selectedDates?.initialDate ?? filters.selectedDates.initialDate;
        const finalDate = selectedDates.finalDate ?? filters.selectedDates.finalDate;

        const response = await getFuelCheckMultiple(
          vehicleIds,
          initialDate,
          finalDate,
          currentCustomer
        );

        const actualResponse = await getActualCheckMultiple(
          vehicleIds,
          initialDate,
          finalDate
        );

        if (actualResponse.status !== 200 || !response?.data.success) {
          throw new Error("Erro ao carregar dados");
        }

        const responseData = response.data?.data;
        const { actualFuelData } = actualResponse.data.data?.[0] ?? undefined;

        let returnData = {
          totalFillEvents: responseData?.totalFillEvents || 0,
          totalInconsistencyEvents: responseData?.totalInconsistencyEvents || 0,
          totalLitersFilled: responseData?.totalLitersFilled || 0,
          multipleFuelHistory: [],
          lastEntry: responseData?.multipleFuelHistory?.[0]?.fuelSupplyHistories?.lastEntry || {},
          actualFuelData
        };

        responseData.multipleFuelHistory && responseData.multipleFuelHistory.forEach(item => {
          const fuelSupplyHistories = item.fuelSupplyHistories?.fuelEntries;

          fuelSupplyHistories && fuelSupplyHistories.map(entry => {
            returnData.multipleFuelHistory.push({
              id: item.vehicleID,
              identification: item?.identification || "",
              event: entry?.event,
              date: entry?.date,
              odometer: entry?.odometer || 0,
              previousVolume: fuelVolumeLabel(entry?.previousVolume),
              differenceVolume: fuelVolumeLabel(entry?.differenceVolume),
              actualVolume: fuelVolumeLabel(entry?.actualVolume),
            });
          })
        });

        setDieselData(returnData);

        handleAmplitudeEvent('Diesel Control Loaded', {
          vehicle_id: vehicleIds.join(", "),
          vehicle_name: vehicleNames.join(", "),
          startDate: selectedDates?.initialDate,
          endDate: selectedDates?.finalDate,
          totalFills: returnData?.totalFillEvents || 0,
          totalFilled: returnData?.totalLitersFilled || 0,
          totalInconsistences: returnData?.totalInconsistencyEvents || 0
        });
      }
    } catch (err) {
      setDieselData(null);
      dispatch(clearVehicleFilters());
    } finally {
      setLoading(false);
    }
  };

  const fetchLocations = async (selectedDate, vehicleId, vehicleIdentification) => {
    setLoadingModalMap(true);

    try {
      // Subtract 10 min to startDate and add 10 min to endDate
      let startDateLocation = new Date(selectedDate);
      let endDateLocation = new Date(selectedDate);

      startDateLocation.setTime(startDateLocation.getTime() - 10 * 60 * 1000);
      endDateLocation.setTime(endDateLocation.getTime() + 10 * 60 * 1000);

      const response = await getFleetLocations(
        [String(vehicleId)],
        dayjs(startDateLocation).format(dbDateFormat),
        dayjs(endDateLocation).format(dbDateFormat),
      );

      if (response.data.locations.length) {
        const dateStringArray = response.data?.locations[0]?.locations?.map(
          (item) => {
            return {
              date: item[0],
              lat: item[1],
              lon: item[2],
            };
          },
        );

        const location = getApproximateDate(dateStringArray, selectedDate);

        setLocations([
          {
            device: vehicleIdentification,
            locations: [location],
          },
        ]);
      } else {
        setLocations([]);
      }
    } catch (err) {
      setLocations([]);
    } finally {
      setLoadingModalMap(false);
    }
  };

  const fetchChart = async (selectedDate, vehicleId) => {
    setLoadingModalChart(true);

    try {
      const vehicle = selectedVehicles ? selectedVehicles.filter(item => item.id === vehicleId)[0] : filters.vehicles.filter(item => item.id === vehicleId)[0];
      const vehicleData = vehicles.find(v => v.id === vehicleId);
      const vehicleProfile = await getVehicleProfile(vehicle.id);

      if (!vehicleProfile?.data) {
        throw new Error("failed to fetch chart data");
      }

      let initialDate = new Date(selectedDate);
      let finalDate = new Date(selectedDate);

      initialDate.setTime(initialDate.getTime() - 1 * 60 * 60 * 1000);
      finalDate.setTime(finalDate.getTime() + 1 * 60 * 60 * 1000);

      initialDate = dayjs(initialDate).format(dbDateFormat);
      finalDate = dayjs(finalDate).format(dbDateFormat)

      const response = await getFleetChartData(
        vehicle.id,
        initialDate,
        finalDate,
      );

      if (!response?.data || !response?.data?.data) {
        throw new Error("failed to fetch chart data");
      }

      const parsedChartData = parseChartData(
        response.data.data,
        vehicleProfile.data,
        vehicleData,
        vehicleProfile,
        initialDate,
        finalDate
      );

      setChartData(parsedChartData);

      handleAmplitudeEvent('Chart Modal Loaded', { vehicle_id: vehicleId, vehicle_name: vehicle.identification, period: `${initialDate} - ${finalDate}` })
    } catch (err) {
      setChartData([]);
      noIndicatorsChartToShow();
      console.log(err);
    } finally {
      setLoadingModalChart(false);
    }
  };

  const showDetails = (selectedDate, vehicleId, vehicleIdentification) => {
    fetchChart(selectedDate, vehicleId);
    fetchLocations(selectedDate, vehicleId, vehicleIdentification);
    handleModal(true);

    if (!isSideMenuOnDashboard) {
      const vehicle = vehicles.find(vehicle => vehicle.id === vehicleId);

      const selectedFilters = {
        vehicle: [vehicle],
        selectedDates,
      };

      dispatch(setVehicleFilters(selectedFilters));
    }
  };

  const columns = makeColumns(size, showDetails);

  const handleSelectDate = async (initialDate, finalDate) => {
    setSelectedDates({
      initialDate: dayjs(initialDate).format(dateFormatTemplate),
      finalDate: dayjs(finalDate).format(dateFormatTemplate),
    });

    const selectedFilters = {
      vehicle: filters.vehicles,
      selectedDates: {
        initialDate: dayjs(initialDate).format(dateFormatTemplate),
        finalDate: dayjs(finalDate).format(dateFormatTemplate),
      },
    };

    handleAmplitudeEvent('Filter Updated', { label: "Periodo", value: `${selectedFilters.selectedDates.initialDate} - ${selectedFilters.selectedDates.finalDate}` });

    dispatch(setVehicleFilters(selectedFilters));
  };

  const fetchOperations = async () => {
    setLoadingOptions(true);

    try {
      const response = await getVehiclesByOperation(currentCustomer);

      const operations = response.data.customers;

      const orderedOperations = operations.sort(
        (a, b) => (a.name > b.name) - (a.name < b.name)
      );

      // Removes vehicles without a currentDevice
      const sanitizedOperations = orderedOperations.map((operation) => {
        return {
          ...operation,
          vehicles: operation.vehicles.filter(vehicle => vehicle.currentDevice !== null)
        }
      });

      // Removes operations without valid vehicles
      const filteredOperations = sanitizedOperations.filter(sanitizedOperation => !!sanitizedOperation.vehicles.length);

      const operationsVehicles = filteredOperations.reduce((acc, curr) => ([...acc, ...curr.vehicles]), []);

      setVehicles(operationsVehicles);
    } catch (err) {
      toast.error(
        "Erro ao carregar a lista de veículos. Entre em contato com o suporte.",
      );
    } finally {
      setLoadingOptions(false);
    }
  }

  useEffect(() => {
    if (!selectedVehicles.length && state?.vehicle) {
      setSelectedDates({
        initialDate: filters?.selectedDates.initialDate,
        finalDate: filters?.selectedDates.finalDate,
      });

      const item = state?.vehicle[0]?.id ? [state?.vehicle[0]] : state?.vehicle;

      setSelectedVehicles(sanitizeVehicles(item))
    }
  }, [filters]);

  useEffect(() => {
    if (selectedVehicles?.length > 0 && selectedDates && !loading) {
      fetchData();
    }
  }, [selectedVehicles, selectedDates]);

  useEffect(() => {
    if (dieselData && filters.vehicles && !!filters.vehicles?.length) {
      handleAmplitudeEvent(
        'Diesel Control Loaded',
        {
          vehicleId: filters.vehicles.map(item => item?.id).join(", "),
          vehicleName: filters.vehicles.map(item => item?.identification).join(", "),
          startDate: filters.selectedDates.initialDate,
          endDate: filters.selectedDates.finalDate,
          totalFills: dieselData?.totalFillEvents,
          totalFilled: dieselData?.totalLitersFilled,
          totalInconsistences: dieselData?.totalInconsistencyEvents,
          actualVolume: dieselData?.actualVolume,
          lastUpdate: dieselData?.lastEntry?.date
        }
      );
    }
  }, [dieselData, filters]);


  useEffect(() => {
    if (!isSideMenuOnDashboard) {
      const getItemNewFeature = localStorage.getItem("@newFeatureDieselControl") !== 'true';

      setNewFeature(getItemNewFeature)
    }

    if (currentCustomer && hasPermission({ scopes: ["can_view_dashboard"] })) {
      handleAmplitudeEvent('Diesel Control Screen Viewed');

      fetchOperations();
    }
  }, []);

  return (
    <PermissionsGate scopes={["can_view_dieselControl_menu"]}>
      <NewFeatureModal
        title='Explore a nova funcionalidade de Gestão do nível de combustível'
        description='Essa funcionalidade oferece a você uma visão detalhada dos registros de abastecimento da sua frota dentro de um período específico, permitindo uma análise abrangente e a identificação de possíveis inconsistências no tanque de combustível.'
        image={ImageNewFeature}
        topics={[
          { content: 'Fácil análise dos abastecimentos dentro do período que você desejar' },
          { content: 'Identificação de possíveis inconsistências no tanque de combustível' },
          { content: 'Informações detalhadas do local, data e hora de cada registro de abastecimento/inconsistência' },
          { content: 'Design limpo e fácil de usar' },
          { content: 'Dados e insights já calculados otimizando o seu tempo' }
        ]}
        open={openNewFeature}
        handleClose={() => {
          setNewFeature(false)
          localStorage.setItem('@newFeatureDieselControl', true)
        }}
      />
      {showModal && locations && (
        <ModalDieselControl
          showModal={showModal}
          handleModal={handleModal}
          loadingModalMap={loadingModalMap}
          loadingModalChart={loadingModalChart}
          chartData={chartData}
          locations={locations}
        />
      )}
      <Grid container>
        <Grid container item xs={12}>
          {!isSideMenuOnDashboard ? (
            <div className={classes.header}>
              <Grid item xs={12} sm={12} md={4}>
                <Typography variant="h2" className={classes.titlePage}>
                  Gestão do nível de combustível
                </Typography>
                <span>
                  Visualize detalhes sobre os bastecimentos e inconsistências dos
                  seus veículos
                </span>
                <span className={classes.infoMessage}>
                  * As informações de abastecimento são atualizadas diariamente, com dados disponíveis a partir do dia anterior. Você pode selecionar ontem ou datas anteriores para visualização.
                </span>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6} xl={6} className={classes.operationsSelect}>
                <OperationsSelect
                  vehicles={sanitizeVehicles(vehicles).filter(vehicle => !selectedVehicles.some(selectedVehicle => selectedVehicle.id === vehicle.id))}
                  value={selectedVehicles}
                  onChange={handleVehiclesChange}
                  onOperationSelect={handleOperationSelect}
                  onOperationDelete={handleOperationDelete}
                  onAllSelect={() => setSelectedVehicles(sanitizeVehicles(vehicles))}
                  onAllDelete={() => {
                    setSelectedVehicles([]);
                    setDieselData(null);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6} xl={6} className={classes.datesSelect}>
                <Calendar
                  selectedDates={selectedDates}
                  handleCalendar={handleSelectDate}
                  calendarMaxRanges={{
                    ...(selectedVehicles?.[0]?.devicesHistory && {
                      minDate: new Date(
                        selectedVehicles[0].devicesHistory.at(-1).startDate,
                      ),
                    }),
                    maxDate: MAX_DATE,
                  }}
                />
              </Grid>
            </div>
          ) : (
            <div className={classes.headerAlt}>
              <Grid item xs={12}>
                <Typography variant="h2" className={classes.titlePage}>
                  Gestão do nível de combustível
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {dieselData && (
                  <DieselControlInfo dieselData={dieselData} filters={storedFilters} />
                )}
              </Grid>
            </div>
          )}
          {loading || loadingOptions ? (
            <DieselControlSkeleton />
          ) : (
            <>
              {dieselData ? (
                <>
                  <Grid item container xs={12} spacing={2} py={2}>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                      {dieselData && (
                        <Card
                          value={dieselData?.totalFillEvents ?? 0}
                          dataCy={"card-total-fuel"}
                          title={"Quantidade de abastecimentos"}
                          description={size.mobile ? null : "Realizados no período que você selecionou"
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                      {dieselData && (
                        <Card
                          value={dieselData?.totalLitersFilled ?? 0}
                          dataCy={"card-total-fuel-full"}
                          title={"Total abastecido"}
                          description={size.mobile ? null : "Abastecimentos realizados no período que você selecionou"
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                      {dieselData && (
                        <Card
                          style={dieselData?.totalInconsistencyEvents > 0 ? { border: '2px solid var(--cores-v-3-amarelo-primaria, #E2211C)' } : {}}
                          value={dieselData?.totalInconsistencyEvents ?? 0}
                          dataCy={"card-total-failed"}
                          title={"Quantidade de inconsistências"}
                          description={size.mobile ? null : "Quedas de combustível com o veículo parado"
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {dieselData && (
                      <TableResponsive
                        tableBodyMaxHeight={size.mobile ? size.height - 430 + "px" : size.height - 330 + "px"}
                        data={dieselData?.multipleFuelHistory ?? []}
                        options={{ rowsPerPage: 10 }}
                        columns={columns}
                        tableName="diesel-details"
                      />
                    )}
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <StatusEmpty title={'Para gerar informações, selecione os filtros.'}
                    description="Selecione um veículo clicando no filtro localizado no menu acima e em seguida o período desejado." />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </PermissionsGate>
  );
};

export default DieselControl;
