import api from "./api";

export const arrayUrl = (itens = "") => {
  let partialUrl = "";
  if (itens) {
    partialUrl = Array.isArray(itens) ? itens.join() : itens;
  }
  return partialUrl;
};


export const getUsers = async () => {
  try {
    const response = await api.get(`/users`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getUserById = async (userID) => {
  try {
    const response = await api.get(`/users/${userID}`)
    return response
  } catch (error) {
    return error;
  }
}

export const getUserByEmail = async (email) => {
  try {
    const response = await api.get(`/user/${email}`, {
      removeCredentials: true
    })
    return response
  } catch (error) {
    return error;
  }
}

export const createUser = async (data) => {
  try {
    const response = await api.post(`/users`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteUser = async (userID) => {
  try {
    const response = await api.delete(`/users/${userID}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteUserEntirely = async (userID) => {
  try {
    const response = await api.delete(`/user/${userID}/entirely`);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateUser = async (userID, data) => {
  try {
    const response = await api.put(`/users/${userID}`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const getUserByCustomers = async (customers) => {
  const partialUrl = arrayUrl(customers);
  let params = `customer_id=${partialUrl}`;
  try {
    const response = await api.get(`/list-users?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
}

export const getUserHistory = async (email) => {
  let params = `email=${encodeURIComponent(email)}`;
  try {
    const response = await api.get(`/web/v2/core/users/general-history?${params}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getUnassignVehiclesUserByCustomersIDs = async (email, customerIds) => {
  try {
    const response = await api.get(`/web/v2/vehiclesPermissions/${email}/availables?customers=${customerIds}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const postAnassignVehiclesUser = async (data) => {
  try {
    const response = await api.post(`/web/v2/vehiclesPermissions`, data);
    return response;
  } catch (err) {
    throw err;
  }
};
