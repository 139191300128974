import { clearFilters } from "store/features/driversMonitoringSlice";
import { clearComparisonPagination } from "store/features/comparisonSlice";
import { clearBonusFilters, clearPrintData } from "store/features/bonusFollowSlice";
import { clearVehicleFilters } from "store/features/vehicleDashboardSlice";
import { clearVehicleDriversFilters } from "store/features/driverDashboardSlice";
import { clearConsolidatedFilters } from "store/features/consolidatedSlice";
import { clearLiveMapMenuSelected } from "store/features/liveMapSlice";

export const clearFiltersByPath = (store, pathArray) => {
    const bonusFollowSteps = store.getState().bonusFollow.fields.step;
    const driversMonitoringSteps = store.getState().driversMonitoring.fields.step;

    const isDashboardGeneral = pathArray.includes("dashboard") && pathArray.includes("general");
    const isDashboardDriver = pathArray.includes("dashboard") && pathArray.includes("driver");

    if (isDashboardGeneral && (bonusFollowSteps === 2 || driversMonitoringSteps === 2)) {
        store.dispatch(clearVehicleFilters());
    }
    if (!pathArray.includes("driversMonitoring")) {
        store.dispatch(clearFilters());
    }
    if (!isDashboardDriver) {
        store.dispatch(clearVehicleDriversFilters());
    }
    if (!pathArray.includes("comparison")) {
        store.dispatch(clearComparisonPagination());
    }
    if (!pathArray.includes("bonus") && bonusFollowSteps !== 3 && bonusFollowSteps !== 2) {
        store.dispatch(clearBonusFilters());
    }
    if (!isDashboardGeneral) {
        store.dispatch(clearVehicleFilters());
    }
    if (!pathArray.includes("consolidated")) {
        store.dispatch(clearConsolidatedFilters());
    }
    if (!pathArray.includes("print")) {
        store.dispatch(clearPrintData());
    }
    if (!pathArray.includes("liveMap") && !pathArray.includes("liveMapDetails")) {
        store.dispatch(clearLiveMapMenuSelected());
    }
};