import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "./styles";
import classnames from "classnames";
import { timeConvert, valueToStringFloat } from "helpers/functions";
import { hasPermission } from "components/PermissionsGate";
import { sortCompareFunction, sortCompareNumberFunction, sortComparePercentageFunction} from "helpers/tables";

export const makeColumns = (size, data, hasGNV, allItemsOptionIsActive) => {
  const classes = makeStyles();

  const hasLowIdle = data && data.some(item => item.indicators?.lowIdle);
  const hasHighRpmIdle = data && data.some(item => item.indicators?.hightRpmIdle);

  const getCell = (data, textBold, dataCy) => {
    return (
      <Typography
        variant="body2"
        className={classnames({
          [classes.textBold]: textBold,
        })}
        noWrap
        data-cy={dataCy}
      >
        {data || '-'}
      </Typography>
    );
  };

  const getCellIndicator = (value, dataCy) => {
    const data = `${value?.duration
      ? `${valueToPositiveFloat(value?.percentage, 2)}% (${timeConvert(value.duration)})`
      : "0% (00:00:00)"
      }`;
    return getCell(data, false, dataCy);
  };

  const columns = [
    {
      name: "customerName",
      label: size.mobile ? " " : "Empresa",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thEmpresa"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "identification",
      label: "Frota",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thFrota"),
        filter: false,
        sort: allItemsOptionIsActive
      },
    },
    {
      name: "plate",
      label: size.mobile ? " " : "Placa",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thPlaca"),
        sort: allItemsOptionIsActive,
        sortCompare: sortCompareFunction,
        filter: false,
      },
    },
    {
      name: "brand",
      label: "Marca",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thMarca"),
        sort: allItemsOptionIsActive,
        sortCompare: sortCompareFunction,
        filter: false,
      },
    },
    {
      name: "truckModel",
      label: "Modelo do veículo",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thModeloVeiculo"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: sortCompareFunction,
      },
    },
    {
      name: "fabricationYear",
      label: "Ano de fabricação",
      options: {
        customBodyRender: (value) => (
          <Typography variant="body2" noWrap data-cy="thAnoFabricacao">
            {getCell(value, true, "thAnoFabricacao")}
          </Typography>
        ),
        sort: allItemsOptionIsActive,
        sortCompare: sortCompareNumberFunction,
        filter: false,
      },
    },
    {
      name: "motor",
      label: "Motorização",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thMotorizacao"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: sortCompareFunction,
      },
    },
    {
      name: "wheelDriver",
      label: "Tração",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thTracao"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "operationName",
      label: "Grupo de veículos",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thGrupoVeiculos"),
        filter: false,
        sort: allItemsOptionIsActive
      },
    },
    {
      name: "score",
      label: "Nota geral",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thNotaGeral"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: sortCompareNumberFunction,
      },
    },
    {
      name: "totalMileage",
      label: "Km total",
      options: {
        customBodyRender: (value) => (
          <Typography
            variant="body2"
            noWrap
            data-cy="thKmTotal"
          >
            {`${valueToStringFloat(value)} Km`}
          </Typography>
        ),
        filter: false,
        sort: false,
      },
    },
    {
      name: "averageSpeed",
      label: "Velocidade média",
      options: {
        customBodyRender: (value) => (
          <Typography
            variant="body2"
            noWrap
            data-cy="thVelocidadeMedia"
          >
            {`${valueToPositiveFloat(value, 2)} Km/h`}
          </Typography>
        ),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: sortCompareNumberFunction,
      },
    },
    {
      name: "totalConsumption",
      label: "Consumo total",
      options: {
        customBodyRender: (value, tableData) => {
          const content = (!hasGNV || !tableData.rowData[34]) ? `${valueToPositiveFloat(value, 2)} L` :
            `${valueToPositiveFloat(tableData.rowData[34], 2)} kg | ${valueToPositiveFloat(tableData.rowData[35], 2)} m³`;
          return (
            <Typography
              variant="body2"
              noWrap
              data-cy="thConsumoTotal"
            >
              {content}
            </Typography>
          )
        },
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: sortCompareNumberFunction,
      },
    },
    {
      name: "consumptionAverage",
      label: "Média computador de bordo",
      options: {
        customBodyRender: (value, tableData) => {
          const content = (!hasGNV || !tableData.rowData[32]) ? `${valueToPositiveFloat(value, 2)} Km/L` :
            `${valueToPositiveFloat(tableData.rowData[32], 2)} km/kg | ${valueToPositiveFloat(tableData.rowData[33], 2)} km/m³`;
          return (
            <Typography
              variant="body2"
              noWrap
              data-cy="thMediaComputadorBordo"
            >
              {content}
            </Typography>
          )
        },
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: sortCompareNumberFunction,
      },
    },
    {
      name: "odometer",
      label: "Odômetro",
      options: {
        customBodyRender: (value) => (
          <Typography
            variant="body2"
            noWrap
            data-cy="thOdometro"
          >
            {`${valueToPositiveFloat(value, 2)} Km`}
          </Typography>
        ),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: sortCompareNumberFunction,
      },
    },
    {
      name: "totalBreakingOnHighSpeed",
      label: "Freadas em alta veloc.",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thFreadasAltaVelocidade"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: sortCompareNumberFunction,
      },
    },
    {
      name: "totalBreaking",
      label: "Freadas totais",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thFreadasTotais"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: sortCompareNumberFunction,
      },
    },
    {
      name: "totalBreakingIn100Km",
      label: "Freadas a cada 100 km",
      options: {
        customBodyRender: (value) => (
          <Typography
            variant="body2"
            noWrap
            data-cy="thVelocidadeMedia"
          >
            {value ?? 0}
          </Typography>
        ),
        sort: allItemsOptionIsActive,
        sortCompare: sortCompareNumberFunction,
      }
    },
    {
      name: "indicators.extraEconomicRange",
      label: "Início da faixa verde",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thInicioFaixaVerde"),
        sort: allItemsOptionIsActive,
        sortCompare: (order) => sortComparePercentageFunction(order, "indicators.extraEconomicRange", columns),
      },
      
    },
    {
      name: "indicators.idle",
      label: "Total motor ligado parado",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thMotorLigadoParadoIdle"),
        sort: allItemsOptionIsActive,
        sortCompare: (order) => sortComparePercentageFunction(order, "indicators.idle", columns),
      },
    },
    {
      name: "indicators.powerRange",
      label: "Acelerando acima do verde",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thAcelerandoAcimaVerde"),
        sort: allItemsOptionIsActive,
        sortCompare: (order) => sortComparePercentageFunction(order, "indicators.powerRange", columns),
      },
    },
    {
      name: "indicators.leverage",
      label: "Aproveitamento de embalo",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thAproveitamentoEmbalo"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: (order) => sortComparePercentageFunction(order, "indicators.leverage", columns),
      },
    },

    {
      name: "indicators.speeding",
      label: "Excesso de velocidade",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thExcessoVelocidade"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: (order) => sortComparePercentageFunction(order, "indicators.speeding", columns),
      },
    },
    {
      name: "indicators.cruiseControl",
      label: "Piloto automático",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thPilotoAutomatico"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: (order) => sortComparePercentageFunction(order, "indicators.cruiseControl", columns),
      },
    },
    {
      name: "indicators.greenRange",
      label: "Faixa verde",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thFaixaVerde"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: (order) => sortComparePercentageFunction(order, "indicators.greenRange", columns),
      },
    },
    {
      name: "indicators.economicRange",
      label: "Final da faixa verde",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thFaixaEconomica"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: (order) => sortComparePercentageFunction(order, "indicators.economicRange", columns),
      },
    },
    {
      name: "indicators.engineBrake",
      label: "Freio motor",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thFreioMotor"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: (order) => sortComparePercentageFunction(order, "indicators.engineBrake", columns),
      },
    },
    {
      name: "indicators.ecoRoll",
      label: "Eco-Roll/I-Roll",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thEcoRoll"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: (order) => sortComparePercentageFunction(order, "indicators.ecoRoll", columns),
      },
    },
    {
      name: "indicators.movement",
      label: "Tempo em movimento",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thTempoMovimento"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: (order) => sortComparePercentageFunction(order, "indicators.movement", columns),
      },
    },
    {
      name: "indicators.lowPressure",
      label: "Uso do pedal ideal",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thUsoPedalIdeal"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: (order) => sortComparePercentageFunction(order, "indicators.lowPressure", columns),
      },
    },
    {
      name: "indicators.medPressure",
      label: "Uso do pedal atenção",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thUsoPedalAtencao"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: (order) => sortComparePercentageFunction(order, "indicators.medPressure", columns),
      },
    },
    {
      name: "indicators.higPressure",
      label: "Uso do pedal crítico",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thUsoPedalCritico"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: (order) => sortComparePercentageFunction(order, "indicators.higPressure", columns),
      },
    }
  ];

  if (hasGNV) {
    columns.push({
      name: "gnvFuelConsumption.averageConsumptionKg",
      label: "averageConsumptionKg",
      options: {
        viewColumns: false,
        hideColumn: true,
        display: false
      },
    }, {
      name: "gnvFuelConsumption.averageConsumptionM3",
      label: "averageConsumptionM3",
      options: {
        viewColumns: false,
        hideColumn: true,
        display: false
      },
    }, {
      name: "gnvFuelConsumption.totalGaseousConsumpKg",
      label: "totalGaseousConsumpKg",
      options: {
        viewColumns: false,
        hideColumn: true,
        display: false
      },
    }, {
      name: "gnvFuelConsumption.totalGaseousConsumpM3",
      label: "totalGaseousConsumpM3",
      options: {
        viewColumns: false,
        hideColumn: true,
        display: false
      },
    });
  }

  columns.push({
    name: "id",
    label: "ID",
    options: {
      filter: false,
      sort: false,
      display: false,
      viewColumns: false,
      hideColumn: true,
    },
  })

  if (hasLowIdle) {
    columns.splice(18, 0, {
      name: "indicators.lowIdle",
      label: "Motor ligado parado",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thMotorLigadoParado"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: (order) => sortComparePercentageFunction(order, "indicators.lowIdle", columns),
      },
    });
  }

  if (hasHighRpmIdle) {
    columns.splice(20, 0, {
      name: "indicators.hightRpmIdle",
      label: "Motor ligado parado em alta rotação",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thMotorLigadoAltaRotacao"),
        filter: false,
        sort: allItemsOptionIsActive,
        sortCompare: (order) => sortComparePercentageFunction(order, "indicators.hightRpmIdle", columns),
      },
    });
  }


  const filteredColumns = columns.filter((column) => {
    if (column.name === "indicators.ecoRoll") {
      return hasPermission({ scopes: ["can_view_eco_roll_indicator"] });
    }
    return true;
  });

  return filteredColumns;
};
