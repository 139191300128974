import React, { useEffect, useState, useRef } from "react"
import { Box, Grid, Button } from "@mui/material";
import { toast } from "react-toastify";
import { useAppSelector } from "store";

// services
import { getPedalConfigByCustomersIDs } from "services/customers";

// components
import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import VirtualizedTable from "components/VirtualizedTable/VirtualizedTable";
import PermissionsGate from "components/PermissionsGate";
import Widget from "components/Widget/Widget";
import { makeColumns } from "./tableColumns";
import { makeColumnsForPrint } from "./tableColumnsForPrint";
import TruckLoader from "components/TruckLoader";
import UpdateVehiclesDialog from "./fragments/update-vehicles-dialog";

// styles
import useStyles from "./styles";


const PedalConfig = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true);
  const pedalConfigList = useRef([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [vehiclesFiltered, setVehiclesFiltered] = useState([]);
  const columns = makeColumns(selectedVehicles, setSelectedVehicles, vehiclesFiltered);
  const columnsForPrint = makeColumnsForPrint(selectedVehicles, setSelectedVehicles, vehiclesFiltered);
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const [isModalOpen, setModalOpen] = useState(false);
  const title = "Configurações do pedal dos veículos";
  
  const handleOpen = () => setModalOpen(true);
  
  const handleClose = (callAgain = false) => {
    if (typeof callAgain !== "boolean") {
      callAgain = false;
    }
    setModalOpen(false);
    if (callAgain) {
      fetchPedalConfigList(currentCustomer);
      setSelectedVehicles([]);
    }
  };

  const fetchPedalConfigList = async (customersIDs) => {
    try {
      setLoading(true);
      const response = await getPedalConfigByCustomersIDs(customersIDs);
      if (response.status !== 200) {
        throw new Error(response);
      }
      pedalConfigList.current = response.data.data;
    } catch (error) {
      toast.error(
        "Erro ao carregar lista configurações do pedal. Entre em contato com o suporte!",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    pedalConfigList.current.length === 0 && fetchPedalConfigList(currentCustomer)
  }, [currentCustomer])

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_history_vehicle']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <Widget disableWidgetMenu title={title}>
              {loading ? (
                <TruckLoader />
              ) : (
                <Box height="600px">
                  <VirtualizedTable
                    data={pedalConfigList.current}
                    columns={columns}
                    tableName="pedal-config"
                    initialRowsPerPage={100}
                    setFiltered={setVehiclesFiltered}
                    excludedForFilterColumns={['vehicle_id']}
                    tableTitle={title}
                    columnsForPrint={columnsForPrint}
                  />
                </Box>
              )}
            </Widget>
            <Grid container item justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Button
                    fullWidth
                    color="secondary"
                    className={classes.updateButton}
                    disabled={selectedVehicles.length === 0}
                    variant="contained"
                    onClick={handleOpen}
                    data-cy="buttonUpdate" 
                  >
                    Atualizar
                  </Button>
                 {selectedVehicles.length > 0 && <UpdateVehiclesDialog isOpen={isModalOpen} onClose={handleClose} vehiclesData={pedalConfigList.current} selectedVehicles={selectedVehicles} />}
                </Box>
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <BackButton className={classes.backButton} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  )
}

export default PedalConfig