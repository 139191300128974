import React, { useState, useEffect } from "react";
import { Grid, Paper, Box, FormControlLabel, Checkbox, Button } from "@mui/material";
import useStyles from "./styles";

const NotFoundInfo = ({
  description,
  strongDescription,
  checkboxLabel = "Não mostrar essa mensagem novamente",
  onOkClick, //Can pass any additional action
  localStorageKey = "@infobox", // Optional, use for different messages
  darkBox = false, // For pages that have light background
}) => {
  const classes = useStyles();
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const localStorageValue = localStorage.getItem(localStorageKey);
    if (localStorageValue) {
      setIsVisible(false);
    }
  }, [localStorageKey]);

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  const handleOkClick = () => {
    if (checkboxChecked) {
      localStorage.setItem(localStorageKey, true);
    }
    setIsVisible(false);
    if (onOkClick) {
      onOkClick();
    }
  };

  if (!isVisible) return null;

  return (
    <Grid container className={classes.containerInfobox} data-darkbox={darkBox}>
      <Paper elevation={0} className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              fontFamily="fontFamily"
              justifyContent="center"
              fontSize="h3.fontSize"
              textAlign="center"
              lineHeight={2}
              className={classes.infoBox}
              p={4}
            >
              {description} <strong>{strongDescription}</strong>
            </Box>
            <Grid item className={classes.containerButton}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                    color="secondary"
                  />
                }
                label={checkboxLabel}
              />
              <Button
                onClick={handleOkClick}
                variant="contained"
                className={classes.okButton}
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default NotFoundInfo;
