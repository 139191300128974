import { timeConvert } from "./functions";
import { indicatorsOptions, penalizingOptions } from "domain/selectOptions";
import colors from 'themes/gobrax';

/*
Faixa extra econômica = Início faixa verde
Potência = Acelerando acima do verde,
Aproveitamento de embalo = Aproveitamento de embalo
Direção defensiva = Direção defensiva
Piloto automático =  Piloto automático
Motor ligado parado =  Motor Ligado Parado
Faixa verde = Total faixa verde
Faixa econômica = Final faixa verde
Freio motor = Freio motor
Eco-Roll/I-Roll = Eco-roll/I-roll
Em movimento(Tempo em movimento) */

export const getNameIndicator = (key) => {
  switch (key) {
    case "greenRangeScore":
      return "Total faixa verde";

    case "extraEconomicScore":
      return "Início faixa verde";

    case "economicRangeScore":
      return "Final faixa verde";

    case "leverageScore":
      return "Aproveitamento de embalo";

    case "ecoRollScore":
      return "Eco-Roll/I-Roll";

    case "cruiseControlScore":
      return "Piloto automático";

    case "engineBrakeScore":
      return "Freio motor";

    case "powerScore":
      return "Acelerando acima do verde";

    case "speedingScore":
      return "Excesso de velocidade";

    case "idleScore":
      return "Total motor ligado parado";

    case "lowIdleScore":
      return "Motor ligado parado";

    case "hightRpmIdleScore":
      return "Motor ligado parado em alta rotação";

    case "movementScore":
      return "Em movimento";

    case "throttlePressureScore":
      return "Pressão do acelerador";

    case "defensiveScore":
      return "Excesso de velocidade";

    case "lowPressureScore":
      return "Uso do pedal ideal";

    case "medPressureScore":
      return "Uso do pedal atenção";

    case "higPressureScore":
      return "Uso do pedal crítico";

    default:
      return undefined;
  }
};

export const ordernateIndicators = (indicators) => {
  const orderArray = [
    "extraEconomicScore",
    "leverageScore",
    "throttlePressureScore",
    "idleScore",
    "powerScore",
    "engineBrakeScore",
    "cruiseControlScore",
    "speedingScore",
  ];

  const sortedIndicators = [...indicators].sort((a, b) => orderArray.indexOf(a.key) - orderArray.indexOf(b.id));

  return sortedIndicators;
};

export const getScoreColor = (value) => {
  let color = '';

  if (value <= 40) {
    color = colors.palette.error.main;
  } else if (value <= 80) {
    color = colors.palette.warning.main;
  } else {
    color = colors.palette.success.main;
  }

  return color;
};

export const getIndicatorDuration = (indicators, nameIndicator) => {
  if (indicators.length > 0) {
    let indicator = indicators.find(function (indicator) {
      return indicator.name === nameIndicator;
    });
    return timeConvert(indicator?.duration);
  }
  return null;
};

export const getIndicatorFilterType = (indicators) => {

  let highControll = [];
  let mediumControll = [];
  let lowControll = [];
  let penalizingHighControll = [];
  let penalizingMediumControll = [];
  let penalizingLowControll = [];
  let penalizingCustomControll = [];
  let customControll = [];
  indicators.forEach((ind) => {
    let id = ind.indicator_id;
    let conduction = !ind.penalizing
      ? indicatorsOptions.find((indOpt) => indOpt.value === ind.indicator_id)
      : penalizingOptions.find((indOpt) => indOpt.value === ind.indicator_id);
    let acceptablePercentage = ind.lower_limit;
    let idealPercentage = ind.upper_limit;
    if (ind.requirement_level === "high" && !ind.penalizing) {
      highControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "high" && ind.penalizing) {
      penalizingHighControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "medium" && !ind.penalizing) {
      mediumControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "medium" && ind.penalizing) {
      penalizingMediumControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "low" && !ind.penalizing) {
      lowControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "low" && ind.penalizing) {
      penalizingLowControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "custom" && !ind.penalizing) {
      customControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else {
      penalizingCustomControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    }
  });
  return {
    highControll,
    mediumControll,
    lowControll,
    penalizingHighControll,
    penalizingMediumControll,
    penalizingLowControll,
    customControll,
    penalizingCustomControll,
  };
};

export const getIndicatorVisibilityKey = (indicator) => {
  switch (indicator) {
    case "extraEconomicDrivingScore":
      return "hasExtraEconomic";
    case "leveragingScore":
      return "hasLeverage";
    case "idleScore":
        return "hasIdle";
    case "lowIdleScore":
      return "hasLowIdle";
    case "powerScore":
      return "hasPower";
    case "cruiseControlScore":
      return "hasCruiseControl";
    case "defensiveDrivingScore":
      return "hasDefensiveDriving";
    default:
      return "";
  }
}; 