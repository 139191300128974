import { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  Typography,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useWatch, useFormContext, useFormState, Controller } from "react-hook-form";
import { toast } from "react-toastify";

// store
import { useAppSelector } from "store";
// components
import Input from "components/react-hook-form/input";
import InputAdornment from "components/react-hook-form/inputAdornment";
// helpers
import Aux from "hoc/auxiliar";
import { makeSelectOptions } from "helpers/makeSelectOptions";
import { orderByName, findValueByKey } from "helpers/functions";

// domain
import {
  accelerationIdentificationMethodOptions,
  fuelCalcMethodOptions,
  distanceCalcMethodOptions,
  odomTypeOptions,
} from "domain/selectOptions";

// skeleton
import FormVehicleSkeleton from "components/Skeletons/FormVehicleSkeleton";

// services
import { getCustomersById } from "services/customers";
import { getOperations } from "services/operations";
import { getBrands, getModelsByBrand, getWheeldrivers, getFueltypes } from "services/vehicle";

import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";

const StyledAutocomplete = styled(Autocomplete)(() => ({
  maxWidth: '540px',
  '.MuiInputBase-root': {
    borderRadius: 20,
  }
}));

const FormVehicle = () => {
  const classes = useStyles();

  const { currentCustomer, listCustomers } = useAppSelector((state) => state.global.user);

  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });

  const customerOption = useWatch({ control, name: "customerOption" });
  const operationOption = useWatch({ control, name: "operationOption" });
  const fueltypeOption = useWatch({ control, name: "fueltypeOption" });
  const wheeldriverOption = useWatch({ control, name: "wheeldriverOption" });
  const distanceCalcMethodOption = useWatch({ control, name: "distanceCalcMethodOption" });
  const fuelCalcMethodOption = useWatch({ control, name: "fuelCalcMethodOption" });
  const accelerationIdentificationMethodOption = useWatch({ control, name: "accelerationIdentificationMethodOption" });
  const brandOption = useWatch({ control, name: "brandOption" });
  const modelOption = useWatch({ control, name: "modelOption" });
  const hasEcoRoll = useWatch({ control, name: "hasEcoRoll" });
  const hasDafEcoRoll = useWatch({ control, name: "hasDafEcoRoll" });

  const brand = useWatch({ control, name: "brand" });
  const model = useWatch({ control, name: "model" });
  const odomType = useWatch({ control, name: "odomType" });

  const [customers, setCustomers] = useState([]);
  const [operations, setOperations] = useState([]);

  const [fuelTypes, setFuelTypes] = useState([]);
  const [wheeldrivers, setWheeldrivers] = useState([]);
  const [distanceCalcMethods, setDistanceCalcMethods] = useState([]);
  const [fuelCalcMethods, setFuelCalcMethods] = useState([]);
  const [accelerationIdentificationMethods, setAccelerationIdentificationMethods] = useState([]);

  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);

  const fetchCustomerList = useCallback(async () => {
    try {
      const response = await getCustomersById(listCustomers);

      const customers = findValueByKey(response, 'customers');

      const customerOptions = makeSelectOptions({
        data: customers ?? [],
        keyLabel: "name",
        keyValue: "id",
      });

      const orderOptions = orderByName(customerOptions);

      setCustomers(orderOptions);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de rastreadores. Entre em contato com o suporte.",
      );
      console.log(err);
    }
  }, [listCustomers]);

  const fetchOperationList = async (customer) => {
    if (customer) {
      try {
        const response = await getOperations(customer);

        const operation = findValueByKey(response, 'operation');

        const operationOptions = makeSelectOptions({
          data: operation ?? [],
          keyLabel: "name",
          keyValue: "id",
        });

        const orderOptions = orderByName(operationOptions);

        setOperations(orderOptions);
      } catch (err) {
        toast.error(
          "Erro ao carregar lista de operações. Entre em contato com o suporte.",
        );
        console.log(err);
      }
    }
  };

  const fetchFueltypesList = async () => {
    try {
      const response = await getFueltypes();

      const fuelTypes = findValueByKey(response, 'fuelTypes');

      const options = makeSelectOptions({
        data: fuelTypes ?? [],
        keyLabel: "name",
        keyValue: "id",
      });

      const modelsOptions = orderByName(options);

      setFuelTypes(modelsOptions ?? []);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de combustíveis. Entre em contato com o suporte.",
      );
      console.log(err);
    }
  };

  const fetchWheeldriversList = async () => {
    try {
      const response = await getWheeldrivers();

      const wheeldrivers = findValueByKey(response, 'wheeldrivers');

      const options = makeSelectOptions({
        data: wheeldrivers ?? [],
        keyLabel: "name",
        keyValue: "id",
      });

      const modelsOptions = orderByName(options);

      setWheeldrivers(modelsOptions ?? []);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de trações. Entre em contato com o suporte.",
      );
    }
  };

  const fetchDistanceCalcMethodsList = () => {
    const options = makeSelectOptions({
      data: distanceCalcMethodOptions,
      keyLabel: "name",
      keyValue: "id",
    });

    const distanceOptions = orderByName(options);

    setDistanceCalcMethods(distanceOptions);
  };

  const fetchFuelCalcMethodList = () => {
    const options = makeSelectOptions({
      data: fuelCalcMethodOptions,
      keyLabel: "name",
      keyValue: "id",
    });

    const fuelOptions = orderByName(options);

    setFuelCalcMethods(fuelOptions);
  };

  const fetchAccelerationIdentificationMethodList = () => {
    const options = makeSelectOptions({
      data: accelerationIdentificationMethodOptions,
      keyLabel: "name",
      keyValue: "id",
    });

    const accelerationOptions = orderByName(options);

    setAccelerationIdentificationMethods(accelerationOptions);
  };

  const fetchBrandList = async () => {
    try {
      const response = await getBrands();

      const brands = findValueByKey(response, 'brands');

      const options = makeSelectOptions({
        data: brands ?? [],
        keyLabel: "name",
        keyValue: "id",
      });

      const brandOptions = orderByName(options);

      setBrands(brandOptions ?? []);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de marcas. Entre em contato com o suporte.",
      );
      console.log(err);
    }
  };

  const fetchModelList = async (brand) => {
    try {
      if (brand) {
        const response = await getModelsByBrand(brand);

        const models = findValueByKey(response, 'models');

        const options = makeSelectOptions({
          data: models ?? [],
          keyLabel: "name",
          keyValue: "id",
        });

        const modelsOptions = orderByName(options);

        setModels(modelsOptions);
      }
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de perfis. Entre em contato com o suporte.",
      );
      console.log(err);
    }
  };

  const handleEcoRollChange = (e) => {
    setValue('hasEcoRoll', e.target.checked);
  };

  const handleDafEcoRollChange = (e) => {
    setValue('hasDafEcoRoll', e.target.checked);
  };

  useEffect(() => {
    if (!currentCustomer) return;

    fetchCustomerList();
    fetchOperationList(currentCustomer);
    fetchFueltypesList();
    fetchAccelerationIdentificationMethodList();
    fetchDistanceCalcMethodsList();
    fetchFuelCalcMethodList();
    fetchWheeldriversList();
    fetchBrandList();

    if (brandOption) {
      fetchModelList(brandOption);
    }
  }, [brandOption]);

  useEffect(() => {
    const initialCustomer = customers.find((customer) => customer.value === customerOption);

    if (initialCustomer) {
      setValue('customer', initialCustomer);
    }
  }, [customers, customerOption]);

  useEffect(() => {
    const initialOperation = operations.find((operation) => operation.value === operationOption);

    if (initialOperation) {
      setValue('operation', initialOperation);
    }
  }, [operations, operationOption]);

  useEffect(() => {
    const initialFuelType = fuelTypes.find((fuelType) => fuelType.value === fueltypeOption);

    if (initialFuelType) {
      setValue('fueltype', initialFuelType);
    }
  }, [fuelTypes, fueltypeOption]);

  useEffect(() => {
    const initialWheelDriver = wheeldrivers.find((wheeldriver) => wheeldriver.value === wheeldriverOption);

    if (initialWheelDriver) {
      setValue('wheeldriver', initialWheelDriver);
    }
  }, [wheeldrivers, wheeldriverOption]);

  useEffect(() => {
    const initialDistanceCalcMethod = distanceCalcMethods.find((distanceCalcMethod) => distanceCalcMethod.value === distanceCalcMethodOption);

    if (initialDistanceCalcMethod) {
      setValue('distanceCalcMethod', initialDistanceCalcMethod);
    }
  }, [distanceCalcMethods, distanceCalcMethodOption]);

  useEffect(() => {
    const initialFuelCalcMethod = fuelCalcMethods.find((fueldCalcMethod) => fueldCalcMethod.value === fuelCalcMethodOption);

    if (initialFuelCalcMethod) {
      setValue('fuelCalcMethod', initialFuelCalcMethod);
    }
  }, [fuelCalcMethods, fuelCalcMethodOption]);

  useEffect(() => {
    const initialFuelCalcMethod = accelerationIdentificationMethods.find((accelerationIdentificationMethod) => accelerationIdentificationMethod.value === accelerationIdentificationMethodOption);

    if (initialFuelCalcMethod) {
      setValue('accelerationIdentificationMethod', initialFuelCalcMethod);
    }
  }, [accelerationIdentificationMethods, accelerationIdentificationMethodOption]);

  useEffect(() => {
    const initialBrand = brands.find((brand) => brand.value === brandOption);

    if (initialBrand) {
      setValue('brand', initialBrand);
    }
  }, [brands, brandOption]);

  useEffect(() => {
    const initialModel = models.find((model) => model.value === modelOption);

    if (!model && initialModel) {
      setValue('model', initialModel);
    }

  }, [models, modelOption]);

  if (!customers || !brands || !fuelTypes) {
    return <FormVehicleSkeleton />;
  }

  return (
    <Aux>
      <Paper elevation={2} className={classes.container}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title}>Empresa</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Controller control={control} name="customer" render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                        <>
                          <StyledAutocomplete
                            {...rest}
                            options={customers}
                            renderInput={(params) => <TextField {...params} label="Selecione uma empresa *" error={error} />}
                            value={value}
                            onChange={(_, option) => {
                              onChange(option);

                              if (option?.value) {
                                fetchOperationList(option.value);
                              }
                            }}
                            isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                            getOptionLabel={(option) => option?.label ?? ''}
                            renderOption={({ key, ...props }, option) => (
                              <Box key={option.value} component="li" {...props}>
                                <Typography>{option.label}</Typography>
                              </Box>
                            )}
                            disablePortal
                          />
                          <FormHelperText className={classes.formHelperText} error={true}>
                            {error?.message}
                          </FormHelperText>
                        </>
                      )} />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Controller control={control} name="operation" render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                        <>
                          <StyledAutocomplete
                            {...rest}
                            options={operations}
                            renderInput={(params) => <TextField {...params} label="Grupo de veículo" error={error} />}
                            value={value}
                            onChange={(_, value) => onChange(value)}
                            isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                            getOptionLabel={(option) => option?.label ?? ''}
                            renderOption={({ key, ...props }, option) => (
                              <Box key={option.value} component="li" {...props}>
                                <Typography>{option.label}</Typography>
                              </Box>
                            )}
                            disablePortal
                          />
                          <FormHelperText className={classes.formHelperText} error={true}>
                            {error?.message}
                          </FormHelperText>
                        </>
                      )} />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title}>Dados do veículo</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Controller
                        control={control}
                        name="identification"
                        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                          <Input
                            {...rest}
                            variant="outlined"
                            fullWidth
                            label="Frota *"
                            helperText={error?.message}
                            error={!!error}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Controller
                        control={control}
                        name="plate"
                        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                          <Input
                            {...rest}
                            variant="outlined"
                            fullWidth
                            label="Placa *"
                            helperText={error?.message}
                            error={!!error}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Controller
                        control={control}
                        name="fueltype"
                        render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                          <>
                            <StyledAutocomplete
                              {...rest}
                              options={fuelTypes}
                              renderInput={(params) => <TextField {...params} label="Tipo de combustível" error={error} />}
                              value={value}
                              onChange={(_, value) => onChange(value)}
                              isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                              getOptionLabel={(option) => option?.label ?? ''}
                              renderOption={({ key, ...props }, option) => (
                                <Box key={option.value} component="li" {...props}>
                                  <Typography>{option.label}</Typography>
                                </Box>
                              )}
                              disablePortal
                            />
                            <FormHelperText className={classes.formHelperText} error={true}>
                              {error?.message}
                            </FormHelperText>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Controller
                        control={control}
                        name="wheeldriver"
                        render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                          <>
                            <StyledAutocomplete
                              {...rest}
                              options={wheeldrivers}
                              renderInput={(params) => <TextField {...params} label="Tração" error={error} />}
                              value={value}
                              onChange={(_, value) => onChange(value)}
                              isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                              getOptionLabel={(option) => option?.label ?? ''}
                              renderOption={({ key, ...props }, option) => (
                                <Box key={option.value} component="li" {...props}>
                                  <Typography>{option.label}</Typography>
                                </Box>
                              )}
                              disablePortal
                            />
                            <FormHelperText className={classes.formHelperText} error={true}>
                              {error?.message}
                            </FormHelperText>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Controller
                        control={control}
                        name="odomType"
                        render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                          <>
                            <StyledAutocomplete
                              {...rest}
                              options={odomTypeOptions}
                              renderInput={(params) => <TextField {...params} label="Tipo de odômetro *" error={error} />}
                              value={value}
                              onChange={(_, value) => onChange(value)}
                              isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                              getOptionLabel={(option) => option?.label ?? ''}
                              renderOption={({ key, ...props }, option) => (
                                <Box key={option.value} component="li" {...props}>
                                  <Typography>{option.label}</Typography>
                                </Box>
                              )}
                              disablePortal
                            />
                            <FormHelperText className={classes.formHelperText} error={true}>
                              {error?.message}
                            </FormHelperText>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Controller
                        control={control}
                        name="initialKm"
                        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                          <Input
                            {...rest}
                            variant="outlined"
                            type="number"
                            fullWidth
                            label="Km inicial *"
                            className={classes.disabledInput}
                            helperText={error?.message}
                            error={!!error}
                            disabled={!odomType}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <FormControl className={classes.formControl} variant="outlined">
                        <InputLabel htmlFor="maxFuelCapacity">
                          Limite tanque *
                        </InputLabel>
                        <InputAdornment
                          name="maxFuelCapacity"
                          label="Limite tanque *"
                          position="start"
                          adornment="Litros"
                          type="number"
                          error={errors.maxFuelCapacity && true}
                        />
                        <FormHelperText className={classes.formHelperText} error={true}>
                          {errors.maxFuelCapacity?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="maxAcceleration">
                          Aceleração máx *
                        </InputLabel>
                        <InputAdornment
                          name="maxAcceleration"
                          label="Aceleração máx *"
                          position="start"
                          adornment="m/s²"
                          type="number"
                          error={errors.maxAcceleration && true}
                        />
                        <FormHelperText
                          className={classes.formHelperText}
                          error={true}
                        >
                          {errors.maxAcceleration?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Input
                        name="chassi"
                        variant="outlined"
                        fullWidth
                        label="Chassi"
                        error={errors.chassi && true}
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {errors.chassi?.message}
                      </FormHelperText>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Input
                        name="motor"
                        variant="outlined"
                        fullWidth
                        label="Motorização"
                        error={errors.motor && true}
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {errors.motor?.message}
                      </FormHelperText>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Input
                        name="fabricationYear"
                        variant="outlined"
                        fullWidth
                        type="number"
                        label="Ano do veículo"
                        error={errors.fabricationYear && true}
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {errors.chassi?.message}
                      </FormHelperText>
                    </Grid>

                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Input
                        name="truckModel"
                        variant="outlined"
                        fullWidth
                        label="Modelo"
                        error={errors.truckModel && true}
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {errors.truckModel?.message}
                      </FormHelperText>
                    </Grid>

                  </Grid>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title}>Cálculo de</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Controller
                        control={control}
                        name="distanceCalcMethod"
                        render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                          <>
                            <StyledAutocomplete
                              {...rest}
                              options={distanceCalcMethods}
                              renderInput={(params) => <TextField {...params} label="Distância por *" error={error} />}
                              value={value}
                              onChange={(_, value) => onChange(value)}
                              isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                              getOptionLabel={(option) => option?.label ?? ''}
                              renderOption={({ key, ...props }, option) => (
                                <Box key={option.value} component="li" {...props}>
                                  <Typography>{option.label}</Typography>
                                </Box>
                              )}
                              disablePortal
                            />
                            <FormHelperText className={classes.formHelperText} error={true}>
                              {error?.message}
                            </FormHelperText>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Controller
                        control={control}
                        name="fuelCalcMethod"
                        render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                          <>
                            <StyledAutocomplete
                              {...rest}
                              options={fuelCalcMethods}
                              renderInput={(params) => <TextField {...params} label="Consumo por *" error={error} />}
                              value={value}
                              onChange={(_, value) => onChange(value)}
                              isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                              getOptionLabel={(option) => option?.label ?? ''}
                              renderOption={({ key, ...props }, option) => (
                                <Box key={option.value} component="li" {...props}>
                                  <Typography>{option.label}</Typography>
                                </Box>
                              )}
                              disablePortal
                            />
                            <FormHelperText className={classes.formHelperText} error={true}>
                              {error?.message}
                            </FormHelperText>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Controller
                        control={control}
                        name="accelerationIdentificationMethod"
                        render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                          <>
                            <StyledAutocomplete
                              {...rest}
                              options={accelerationIdentificationMethods}
                              renderInput={(params) => <TextField {...params} label="Aceleração por *" error={error} />}
                              value={value}
                              onChange={(_, value) => onChange(value)}
                              isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                              getOptionLabel={(option) => option?.label ?? ''}
                              renderOption={({ key, ...props }, option) => (
                                <Box key={option.value} component="li" {...props}>
                                  <Typography>{option.label}</Typography>
                                </Box>
                              )}
                              disablePortal
                            />
                            <FormHelperText className={classes.formHelperText} error={true}>
                              {error?.message}
                            </FormHelperText>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Box flexDirection="row">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={hasEcoRoll}
                              name="hasEcoRoll"
                              onChange={handleEcoRollChange}
                              color="secondary"
                            />
                          }
                          label={<Typography>ECO-ROLL</Typography>}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={hasDafEcoRoll}
                              name="hasDafEcoRoll"
                              onChange={handleDafEcoRollChange}
                              color="secondary"
                            />
                          }
                          label={<Typography>DAF ECO-ROLL</Typography>}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title}>Velocidade máxima</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="maxSpeedAllowed">Sem chuva *</InputLabel>
                        <InputAdornment
                          name="maxSpeedAllowed"
                          label="Sem chuva *"
                          position="start"
                          adornment="Km/h"
                          type="number"
                          error={errors.maxSpeedAllowed && true}
                        />
                        <FormHelperText
                          className={classes.formHelperText}
                          error={true}
                        >
                          {errors.maxSpeedAllowed?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="maxSpeedAllowedWithRain">Com chuva *</InputLabel>
                        <InputAdornment
                          name="maxSpeedAllowedWithRain"
                          label="Com chuva *"
                          position="start"
                          adornment="Km/h"
                          type="number"
                          error={errors.maxSpeedAllowedWithRain && true}
                        />
                        <FormHelperText
                          className={classes.formHelperText}
                          error={true}
                        >
                          {errors.maxSpeedAllowedWithRain?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="leveragePeak">
                          Pico aproveitamento de embalo *
                        </InputLabel>
                        <InputAdornment
                          name="leveragePeak"
                          label="Pico aproveitamento de embalo *"
                          position="start"
                          adornment="Km/h"
                          type="number"
                          error={errors.leveragePeak && true}
                        />
                        <FormHelperText
                          className={classes.formHelperText}
                          error={true}
                        >
                          {errors.leveragePeak?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title}>Vínculo faixas de RPM</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Controller
                        control={control}
                        name="brand"
                        render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                          <>
                            <StyledAutocomplete
                              {...rest}
                              options={brands}
                              renderInput={(params) => <TextField {...params} label="Marca *" error={error} />}
                              value={value}
                              onChange={(_, option) => {
                                onChange(option);

                                if (option?.value) {
                                  fetchModelList(option.value);
                                }
                              }}
                              isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                              getOptionLabel={(option) => option?.label ?? ''}
                              renderOption={({ key, ...props }, option) => (
                                <Box key={option.value} component="li" {...props}>
                                  <Typography>{option.label}</Typography>
                                </Box>
                              )}
                              disablePortal
                            />
                            <FormHelperText className={classes.formHelperText} error={true}>
                              {error?.message}
                            </FormHelperText>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Controller
                        control={control}
                        name="model"
                        render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                          <>
                            <StyledAutocomplete
                              {...rest}
                              options={models}
                              renderInput={(params) => <TextField {...params} label="Perfil *" error={error} />}
                              value={value}
                              onChange={(_, option) => onChange(option)}
                              isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                              getOptionLabel={(option) => option?.label ?? ''}
                              renderOption={({ key, ...props }, option) => (
                                <Box key={option.value} component="li" {...props}>
                                  <Typography>{option.label}</Typography>
                                </Box>
                              )}
                              disablePortal
                              disabled={!brand}
                            />
                            <FormHelperText className={classes.formHelperText} error={true}>
                              {error?.message}
                            </FormHelperText>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Box
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => { console.log('Click') }}
                          className={classes.buttonModel}
                        >
                          Editar Modelo Perfil
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mb={2}>
                <Typography className={classes.title}>Anotações sobre o veículo</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Grid container spacing={2}>
                    <Input
                      name="observation"
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={3}
                      label="Observações"
                    />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ mt: 2 }}>
              <Typography className={classes.explanation}>*Os campos marcados com o asterisco (*) são aqueles que precisam obrigatoriamente ser preenchidos.</Typography>
              <Typography className={classes.explanation}>*LEMBRE-SE de que após o cadastro do veículo é necessário VINCULÁ-LO a uma unidade eletrônica para que os dados comecem a aparecer para o cliente.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Aux>
  );
};

export default FormVehicle;
