import * as yup from "yup";

export const FaqFormSchema = yup.object().shape({
    question: yup
        .string()
        .required("Campo obrigatório"),
    answer: yup
        .string()
        .required("Campo obrigatório"),
    tags: yup
        .array()
        .min(1, "Adicione pelo menos uma tag")
        .required("Campo obrigatório"),
    v3: yup.boolean(),
    app: yup.boolean()
});
