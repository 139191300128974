import React, { useRef, useEffect, useState } from "react";
import { Alert, AlertTitle, Button, Grid, Paper, Typography, Tooltip } from "@mui/material";
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from "@mui/material/styles";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ShowChart from "@mui/icons-material/ShowChart";
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import classNames from "classnames";
import IndicatorSkeleton from "components/Skeletons/IndicatorSkeleton";
import Indicator from "components/Indicator";
import CustomIndicator from "components/CustomIndicator";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import useStyles from "./styles";
import "@splidejs/splide/dist/css/splide.min.css";
import { useWindowSize } from "hooks/useWindowsSize";
import LoadingComponent from "components/LoadingComponent";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily
  },
}));

const FleetPerformance = ({
  indicators,
  loading,
  handleFleetChart,
  indicatorsByOperation,
  handleDieselControl,
  print,
  selectedVehicles = [],
  displayGraphButton = true,
  displayFuelButton = true
}) => {
  const classes = useStyles();
  const size = useWindowSize();
  const splideRef = useRef(null);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (splideRef.current) {
      const { list } = splideRef.current?.splide?.Components?.Elements;
      list.classList.add('ulIndicadores');
    }
  }, [splideRef.current]);

  return (
    <Paper
      elevation={0}
      className={classNames(classes.root, { [classes.print]: print })}
    >
      <Grid container alignItems="center" data-cy="infoFrotaPorcentagens">
        {loading.indicators ? (
          <IndicatorSkeleton />
        ) : !indicators || indicators.length === 0 ? (
          <Alert severity="info">
            <AlertTitle>Atenção!</AlertTitle>
            Não foram encontrados indicadores para a frota selecionada,{" "}
            <strong>verifique os filtros.</strong>
          </Alert>
        ) : (
          <Grid container spacing={2}>
            {!print && (!size.mobile || hasPermission({ scopes: ['can_view_dashboard_chart_bt_mobile'] })) && (
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                xs={12}
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row-reverse' }}
                gap={2}
              >
                {displayGraphButton && (
                  <PermissionsGate scopes={["can_view_dashboard_chart"]}>
                    <StyledTooltip
                      open={open && selectedVehicles?.length > 1}
                      onClick={() => setOpen(!open)}
                      onOpen={() => setOpen(true)}
                      onClose={() => setOpen(false)}
                      placement="bottom-end"
                      title={<>Selecione apenas um veículo para visualizar o gráfico</>}
                    >
                      <Grid style={{ cursor: 'pointer' }}>
                        <Button
                          onClick={() => handleFleetChart(true)}
                          variant="contained"
                          className={classes.chartButton}
                          data-cy="buttonGrafico"
                          disabled={selectedVehicles?.length > 1}
                        >
                          <Typography className={classes.chartButtonText}>Gráfico</Typography> <ShowChart fontSize="small" />
                        </Button>
                      </Grid>
                    </StyledTooltip>
                  </PermissionsGate>
                )}
                {displayFuelButton && (
                  <PermissionsGate scopes={["can_view_diesel_control"]}>
                    <Button
                      onClick={handleDieselControl}
                      variant="contained"
                      className={classes.chartButton}
                      data-cy="buttonControleDiesel"
                    >
                      <Typography className={classes.chartButtonText}>Gestão do nível de combustível</Typography> <LocalGasStationIcon fontSize="small" />
                    </Button>
                  </PermissionsGate>
                )}
              </Grid>
            )}
            <Grid item xl={12} lg={12} md={12} xs={12}>
              {loading.statistics ? (
                <LoadingComponent />
              ) : !print ? (
                <Splide
                  options={{
                    type: "slide",
                    perPage: size.mobile
                      ? Math.trunc(size.width / 200)
                      : Math.trunc(size.width / 300),
                    rewind: true,
                    pagination: false,
                    classes: {
                      prev: 'splide__arrow--prev buttonLeft',
                      next: 'splide__arrow--next buttonRight',
                    },
                    label: {
                      prev: 'Slide anterior',
                      next: 'Próximo slide',
                    }
                  }}
                  className={classes.splide}
                  aria-label="Indicadores"
                  ref={splideRef}
                >
                  {indicators.map((ind, index) => {
                    const hasBonus = indicatorsByOperation.find(
                      (o) => o === ind.name,
                    );
                    
                    return (
                      <SplideSlide key={index} >
                        {ind.name === "throttlePressureScore" ? (
                          <CustomIndicator
                            indicator={ind}
                            hasBonus={hasBonus}
                          />
                        ) : (
                          <Indicator indicator={ind} hasBonus={hasBonus} />
                        )}
                      </SplideSlide>
                    );
                  })}
                </Splide>
              ) : (
                <Grid container spacing={0}>
                  {indicators.map((ind, index) => {
                    const hasBonus = indicatorsByOperation.find((o) => o === ind.name);

                    return (
                      <Grid key={index} item xs={3} className={classes.itemToPrint}>
                        {ind.name === "throttlePressureScore" ? (
                          <CustomIndicator
                            indicator={ind}
                            hasBonus={hasBonus}
                          />
                        ) : (
                          <Indicator
                            indicator={ind}
                            hasBonus={hasBonus}
                            print={print}
                          />
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default FleetPerformance;


