import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  tableContent: {
    marginTop: theme.spacing(2)
  },
  btnNew: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    borderRadius: '2rem',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  buttonDetail: {
    textTransform: "inherit",
    fontSize: 16,
    fontWeight: "bold",
    borderRadius: 10,
    boxShadow: "none",
  },
  buttonDelete: {
    color: 'red',
    borderColor: 'red',
  },
  spanSubTitle: {
    fontSize: 16,
    fontWeight: 400,
  },
  modalContainer: {
    borderRadius: 24,
    color: "#181814",
  },
  modalTitle: {
    color: "#181814",
    fontSize: 20,
    fontWeight: 700,
  },
  modalText: {
    color: "#181814",
    fontSize: 16,
    fontWeight: 400,
  },
  btnConfirm: {
    borderRadius: '2rem',
    border: '1px solid #FFD31C',
    color: "#595959",
    backgroundColor: "transparent",
    marginTop: '1rem',
    height: '3rem',
    minWidth: "12rem",
    '&:hover': {
      borderColor: '#FFD31C'
    },
  },
  btnCancel: {
    borderRadius: '2rem',
    color: "#181814",
    backgroundColor: '#FFD31C',
    marginTop: '1rem',
    minWidth: "12rem",
    '&:hover': {
      borderColor: '#FFD31C'
    },
  },
  btnConfirmSuccess: {
    borderRadius: '2rem',
    color: "#181814",
    backgroundColor: '#FFD31C',
    marginTop: '1rem',
    minWidth: "12rem",
    '&:hover': {
      borderColor: '#FFD31C'
    },
  },
}));