import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Grid } from "@mui/material";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

// store
import { useAppSelector } from "store";

// helpers
import { convertDateBr } from "helpers/dates";

//services
import {
  getVehicles,
  unassignDriverFromVehicle,
} from "services/vehicle";
import { handleAmplitudeEvent } from "services/amplitude";

// components
import Aux from "hoc/auxiliar";
import LinkDriversSkeleton from "components/Skeletons/LinkDriversSkeleton";
import ModalUnassignDriverToVehicle from "components/ModalUnassignDriverToVehicle";
import PageToolbar from "components/PageToolbar";
import Widget from "components/Widget";
import NotFoundInfo from "components/NotFoundInfo";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import Table from "components/Table";
import TableResponsive from "components/TableResponsive";
import { makeColumns } from "./tableColumns";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

// styles
import useStyles from "./styles";

export default function LinkDrivers() {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const classes = useStyles();
  const size = useWindowSize()
  const history = useHistory()
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [rowData, setRowData] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [download, setDownload] = useState({
    link: "",
    fileName: ""
  });

  const [selectedRowData, setSelectedRowData] = useState();
  const [id, setId] = useState();

  const handleUnlink = (params) => {
    handleAmplitudeEvent('Unlink Driver Clicked');

    setRowData(params);

    setConfirmOpen(true);
  };

  const handleConfirmClose = () => setConfirmOpen(false);
  
  const handleHistoric = (params) => {
    handleAmplitudeEvent('Log Button Clicked');

    history.push({
      pathname: "/gofurther/maintenance/historicLinkDrivers",
      state: {
        params: params
      },
    });
  }

  const columns = makeColumns({
    handleHistory: handleHistoric,
    handleUnlink: handleUnlink,
    size
  });


  const fetchVehicles = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getVehicles(currentCustomer);

      if (response.status === 200 || response.status === 201) {
        const fetchedVehicles = response.data.customers;

        const sanitizedVehicles = fetchedVehicles.map((vehicle) => ({
          ...vehicle,
          entryData: { vehicleId: vehicle.id, driverId: vehicle.currentDriver.driverId },
          currentDriverName: vehicle.currentDriver.driverName,
          lastestLinkDate: vehicle.currentDriver.startDate ? convertDateBr(vehicle.currentDriver.startDate) : undefined
        }));

        setVehicles(sanitizedVehicles)
      } else {
        toast.error('Um erro inesperado aconteceu. Contate o suporte.')
      }
    } catch (error) {
      toast.error('Erro ao buscar veículos. Contate o suporte.')
    } finally {
      setLoading(false);
    }
  }, [currentCustomer]);


  const handleUnassignDriverToVehicle = async (data) => {
    try {
      setLoading(true);

      const response = await unassignDriverFromVehicle(data);

      if (response.status === 200) {
        toast.success("Desvinculado com sucesso!");

        fetchVehicles(currentCustomer);
        setRowData({});
        setSelectedRowData();
        setId();
        handleConfirmClose();
      } else {
        toast.error("Um erro inexperado aconteceu. Verifique com o suporte");
      }
    } catch (error) {
      toast.error("Erro ao desvincular veículo. Verifique com o suporte");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ['can_view_linkdrivers'] })) {
      fetchVehicles();

      setDownload({
        link: `/vehicles/exportVehiclesDrivers?customers=${currentCustomer}`,
        fileName: `vinculo-veiculo-motorista_${dayjs().format("DD-MM-YYYY_HH-mm")}.xlsx`
      });
    }
  }, [currentCustomer, fetchVehicles]);

  const handleSelectRow = (data, data2) => {
    setId(data2.dataIndex);

    const currentVehicle = vehicles[data2.dataIndex];

    const formatData = [
      currentVehicle?.identification ?? "",
      data[1].props.children === "Sem motorista vinculado" ? undefined : data[1].props.children,
      currentVehicle?.plate ?? "",
      data[3].props.children === "Sem vínculo atual" ? undefined : data[3].props.children,
      { vehicleId: vehicles[data2.dataIndex].id, driverId: currentVehicle?.currentDriver?.driverId },
    ];

    setSelectedRowData(formatData);
  }

  if (loading) return <LinkDriversSkeleton />;

  if (!loading && (!vehicles && !vehicles?.customers)) {
    return (
      <NotFoundInfo 
        description="Não foi encontrado vínculo para essa empresa." 
        localStorageKey="infoBoxLinkDrivers"
        darkBox
      />
    );
  }

  const handleClickMassAddDriver = () => history.push("/gofurther/vehicle/mass-add-link-drivers");

  const massAddDrivers = () => {
    return (
      <PermissionsGate scopes={["can_import_link_drivers"]}>
        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={4}
          xs={12}
          className={classes.btItem}
        >
          <Button
            fullWidth
            variant="contained"
            className={classes.btnNew}
            onClick={handleClickMassAddDriver}
          >
            Importar em Massa
          </Button>
        </Grid>
      </PermissionsGate>
    )
  };

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_linkdrivers']}>
        <ModalUnassignDriverToVehicle
          confirmOpen={confirmOpen}
          handleConfirmClose={handleConfirmClose}
          handleUnassignDriverToVehicle={handleUnassignDriverToVehicle}
          data={rowData}
        />
        <PageToolbar
          menuBreadcrumb
          onlyBreadcrumb
          breadcrumb="Manutenção"
        />
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12} marginTop={2}>
            <Box>
              <Widget disableWidgetMenu title="Vínculo veículo/motorista" customButton={massAddDrivers}>
                <Grid item xl={12} xs={12} className={classes.table}>
                  {size.mobile ?
                    <TableResponsive
                      pointer={true}
                      columns={columns}
                      data={vehicles}
                      download={download}
                      tableName="link-drivers"
                      setRowsPerPage={setRowsPerPage}
                      options={{
                        onRowClick: handleSelectRow,
                        rowsPerPage: rowsPerPage,
                        setRowProps: (row, index) => {
                          return index === id ? { style: { backgroundColor: '#FFF5CB', cursor: 'pointer', width: '100%' } } : null
                        },
                      }}
                    /> :
                    <Table
                      pointer={true}
                      columns={columns}
                      data={vehicles}
                      download={download}
                      tableName="link-drivers"
                      setRowsPerPage={setRowsPerPage}
                      options={{
                        onRowClick: handleSelectRow,
                        rowsPerPage: rowsPerPage,
                        setRowProps: (row, index) => {
                          return index === id ? { style: { backgroundColor: '#FFF5CB', cursor: 'pointer', width: '100%' } } : null
                        },
                      }}
                    />
                  }
                  {/* botoes */}
                  {size.mobile && <Grid item xs={12}>
                    <Grid container justifyContent="flex-end" spacing={1}>
                      <PermissionsGate scopes={['can_view_linkdrivershistory']}>
                        <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                          <Box >
                            <Button
                              fullWidth
                              variant="contained"
                              color={'secondary'}
                              onClick={() => handleHistoric(selectedRowData)}
                              disabled={!selectedRowData}
                              className={classes.buttonDetail}
                            >
                              Historico
                            </Button>
                          </Box>
                        </Grid>
                      </PermissionsGate>
                      <PermissionsGate scopes={['can_edit_linkdrivers']}>
                        {selectedRowData && !selectedRowData[1] ? null :
                          <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                            <Box>
                              <Button
                                fullWidth
                                variant="contained"
                                color={'secondary'}
                                onClick={() => handleUnlink(selectedRowData)}
                                disabled={!selectedRowData}
                                className={classes.buttonDetail}
                              >
                                Desvincular
                              </Button>
                            </Box>
                          </Grid>
                        }
                      </PermissionsGate>
                    </Grid>
                  </Grid>}
                </Grid>
              </Widget>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
}
