import React from "react";
import { IconButton, Grid, Button, Tooltip } from "@mui/material";
import { 
  Delete as DeleteIcon, 
  Edit as EditIcon, 
  RemoveRedEyeOutlined as EyeIcon, 
  History as HistoryIcon,
  RvHookup as RvHookupIcon
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import makeStyles from "./styles";

export const makeColumns = ({ onDelete, onEdit, onHistoricUser, size }) => {
  const classes = makeStyles();
  let columns = [
    {
      name: "customerName",
      label: size.mobile ? ' ' : "Empresa",
      options: {
        sort: !size.mobile,
        customBodyRender: (value) => {
          value = Array.isArray(value) ? value.toString() : value;
          const shouldTruncate = value.length > 300 || (value.length > 25 && size.mobile);
          const truncatedValue = shouldTruncate
            ? size.mobile ? `${value.slice(0, 25)}...` : `${value.slice(0, 300)}...`
            : value;

          return (
            <Grid
              container
              style={{
                maxWidth: "300px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {shouldTruncate && !size.mobile ? (
                <>
                  {truncatedValue}
                  <Tooltip title={shouldTruncate ? value : ""}>
                    <Button size="small" color="primary">
                      [...]
                    </Button>
                  </Tooltip>
                </>
              ) : (
                truncatedValue
              )}
            </Grid>
          );
        },
      },
    },
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "email",
      label: "E-mail",
      options: {
        sort: !size.mobile,
        customBodyRender: (value) => {
          const shouldTruncate = value.length > 15 && size.mobile;
          const truncatedValue = shouldTruncate
            ? `${value.slice(0, 15)}...`
            : value;

          return (
            <Grid
              container
              style={{
                maxWidth: "300px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {truncatedValue}
            </Grid>
          );
        },
      },
    },
    {
      name: "role",
      label: "Tipo de acesso",
    },
  ];

  if (!size.mobile && hasPermission({ scopes: ['can_edit_users', 'can_remove_users', 'can_view_users_without_edit', 'can_view_history_users'] })) {
    columns.push({
      name: "custom",
      label: "Ações",
      options: {
        sort: false,
        print: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <Grid container className={classes.actionColumn} justifyContent="center" alignItems="center">
              <PermissionsGate scopes={['can_edit_users']}>
                <Grid container item justifyContent="center" alignItems="center" xl={2} lg={2} md={2} sm={2} xs={2}>
                  <IconButton
                    onClick={() => onEdit(value.id)}
                    aria-label="edit"
                    title="Editar"
                    size="large">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_view_history_users']}>
                <Grid container item justifyContent="center" alignItems="center" xl={2} lg={2} md={2} sm={2} xs={2}>
                  <IconButton
                    onClick={() => onHistoricUser(value)}
                    aria-label="history-user"
                    title="Visualizar histórico do usuário"
                    size="large">
                    <HistoryIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_users']}>
                <Grid container item justifyContent="center" alignItems="center" xl={2} lg={2} md={2} sm={2} xs={2}>
                  <IconButton
                    onClick={() => onDelete(value)}
                    aria-label="delete"
                    title="Excluir"
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_view_users_without_edit']}>
                <Grid container item justifyContent="center" alignItems="center" xl={2} lg={2} md={2} sm={2} xs={2}>
                  <IconButton
                    aria-label="view-user"
                    title="Visualizar usuário"
                    component={Link}
                    to={`/gofurther/user/view-user/${value.id}`}
                    size="large">
                    <EyeIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_edit_assign_user_vehicles']}>
                <Grid container item justifyContent="center" alignItems="center" xl={2} lg={2} md={2} sm={2} xs={2}>
                  <IconButton
                    aria-label="view-user"
                    title="Visualizar usuário"
                    component={Link}
                    to={`/gofurther/user/user-vehicles/${value.id}`}
                    size="large">
                    <RvHookupIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }

  return columns;
};
