import { makeStyles } from "@mui/styles";
import { alpha } from '@mui/material/styles';

export default makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.title,
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
  btConfirm: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  backButton: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.backgroundButton
  },
  formHelperText: {
    marginLeft: theme.spacing(2)
  },
  bottomForm: {
    marginTop: theme.spacing(2)
  },
  btItem: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  buttons: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  inputHidden: {
    display: "none",
  },
  spanSubTitle: {
    fontSize: 16,
    fontWeight: 400,
  },
  modalContainer: {
    borderRadius: 24,
    color: "#181814",
  },
  modalTitle: {
    color: "#181814",
    fontSize: 20,
    fontWeight: 700,
  },
  btnConfirm: {
    borderRadius: '2rem',
    color: "#181814",
    backgroundColor: '#FFD31C',
    marginTop: '1rem',
    minWidth: "12rem",
    '&:hover': {
      borderColor: '#FFD31C'
    },
  },
  btnCancel: {
    borderRadius: '2rem',
    border: '1px solid #FFD31C',
    color: "#595959",
    backgroundColor: "transparent",
    marginTop: '1rem',
    '&:hover': {
      borderColor: '#FFD31C'
    },
  },
}));

