import React from "react";
import { Grid, TextField, Box, Tooltip } from "@mui/material";

const CustomizedPedalInput = ({ pedalPressureLow, setPedalPressureLow, pedalPressureMid, setPedalPressureMid }) => {
  
  const handlePositiveNumberChange = (setter, newValue) => {
    let value = 0;

    if (newValue === "" || /^[0-9]+$/.test(newValue)) {
      value = newValue;
    }

    if (value >= 100) {
      value = 99;
    }

    setter(value);
  };

  const validatePressureValues = () => {
    if (pedalPressureMid <= pedalPressureLow) {
      setPedalPressureMid(Number(pedalPressureLow) + 1);
      return;
    }
    if (pedalPressureLow >= pedalPressureMid) {
      setPedalPressureLow(Number(pedalPressureMid) - 1);
    }
  };


  const getRanges = () => {
    return [
      { label: "Ideal", range: `0 até ${pedalPressureLow}` },
      { label: "Médio", range: `${Number(pedalPressureLow) + 1} até ${pedalPressureMid}` },
      { label: "Alto", range: `${Number(pedalPressureMid) + 1} até 100` },
    ];
  };

  const ranges = getRanges();

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={6}>
          <Tooltip
            title={`${ranges[0].label}: ${ranges[0].range}, ${ranges[1].label}: ${ranges[1].range}, ${ranges[2].label}: ${ranges[2].range}`}
            arrow
          >
            <TextField
              label="Pedal baixo (ideal até)"
              type="number"
              value={pedalPressureLow}
              onChange={(e) =>
                handlePositiveNumberChange(setPedalPressureLow, e.target.value)
              }
              onBlur={validatePressureValues}
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Tooltip
            title={`${ranges[0].label}: ${ranges[0].range}, ${ranges[1].label}: ${ranges[1].range}, ${ranges[2].label}: ${ranges[2].range}`}
            arrow
          >
            <TextField
              label="Pedal médio (médio até)"
              type="number"
              value={pedalPressureMid}
              onChange={(e) =>
                handlePositiveNumberChange(setPedalPressureMid, e.target.value)
              }
              onBlur={validatePressureValues}
              fullWidth
            />
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomizedPedalInput;
