import React, {
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { useAppSelector } from "store";
import * as amplitude from "@amplitude/analytics-browser";
import { getAmplitudeScoreCustomer } from "../services/customers";

const AmplitudeScoreContext = createContext();

const AmplitudeScoreProvider = ({ children }) => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);

  const fetchAmplitudeScore = useCallback(async () => {
    if (!currentCustomer) return;

    const response = await getAmplitudeScoreCustomer(currentCustomer);
    
    if(!response.data?.data || response.status !== 200) {
      return;
    }

    const amplitudeCustomer = response.data?.data;

    const { customer_score, customer_avg, drivers_performance } = amplitudeCustomer;
      if (drivers_performance?.length) {
        drivers_performance.forEach(driver => {
          const identify = new amplitude.Identify()
            .set("customer_score", customer_score)
            .set("customer_avg", customer_avg)
            .set("driver_month_score", driver.monthScore)
            .set("driver_avg", driver.consumptionAvg);
            
          amplitude.identify(identify);
        });
      }
  }, [currentCustomer]);

  const identifyUser = useCallback((userId, name, email) => {
    if (userId) {
      amplitude.setUserId(userId);
    }

    const identify = new amplitude.Identify()
      .set("name", name)
      .set("email", email);

    amplitude.identify(identify);
  }, []);

  
  useEffect(() => {
    if (currentCustomer) {
      fetchAmplitudeScore();
    }
  }, [fetchAmplitudeScore, currentCustomer]);

  return (
    <AmplitudeScoreContext.Provider value={{ identifyUser }}>
      {children}
    </AmplitudeScoreContext.Provider>
  );
};

function useAmplitudeScore() {
  const context = useContext(AmplitudeScoreContext);
  return context;
}

export { AmplitudeScoreProvider, useAmplitudeScore };
