import api from "./api";

export const getRankingByCustomer = async (customerId, startDate, finishDate, limit, page, filter = '') => {
  try {
    const response = await api.get(`/web/v1/rankings/${customerId}?start_date=${startDate}&end_date=${finishDate}&limit=${limit}&page=${page}&filter=${filter}`);
    if (!response.ok) {
      throw new Error("Api error")
    }
    return response;
  } catch (err) {
    throw err;
  }
};

export const getRankingByDriverCustomer = async (customerId, driverId, startDate, finishDate, filter = '') => {
  try {
    const response = await api.get(`/web/v1/rankings/${customerId}/drivers/${driverId}?start_date=${startDate}&end_date=${finishDate}&filter=${filter}`);
    if (!response.ok) {
      throw new Error("Api error")
    }
    return response;
  } catch (err) {
    throw err;
  }
};

export const getRankingByOperation = async (customerId, operationId, startDate, finishDate, limit, page, filter = '') => {
  try {
    const response = await api.get(`/web/v1/rankings/${customerId}/operation/${operationId}?start_date=${startDate}&end_date=${finishDate}&limit=${limit}&page=${page}&filter=${filter}`);
    if (!response.ok) {
      throw new Error("Api error")
    }
    return response;
  } catch (err) {
    throw err;
  }
};

export const getRankingByDriverOperation = async (customerId, driverId, operationId, startDate, finishDate, limit, page, filter = '') => {
  try {
    const response = await api.get(`/web/v1/rankings/${customerId}/drivers/${driverId}/operation/${operationId}?start_date=${startDate}&end_date=${finishDate}&limit=${limit}&page=${page}&filter=${filter}`);
    if (!response.ok) {
      throw new Error("Api error")
    }
    return response;
  } catch (err) {
    throw err;
  }
};