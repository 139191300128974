import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";

export default makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(5),
    borderRadius: 28
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.title
  },
  autoComplete: {
    height: 55,
    fontFamily: theme.typography.fontFamily,
    borderRadius: 20
  },
  rpmInput: {
    height: 55,
    fontFamily: theme.typography.fontFamily,
    borderRadius: 20
  },
  iconRight: {
    fontSize: '50px',
    marginTop: theme.spacing(5),
  },
  subtitle: {
    fontSize: 13,
    color: theme.palette.text.title,
    lineHeight: '1',
    minHeight: 30,
    fontWeight: 500,
  },
  explanation: {
    fontSize: 16,
    fontWeight: 400
  },
  formHelperText: {
    marginLeft: theme.spacing(2)
  },
  disabledInput: {
    '& .Mui-disabled': {
      backgroundColor: theme.palette.gray.extraLight,
      borderRadius: "20px"
    }
  },
  item: {
    marginTop: theme.spacing(2)
  },
  formControl: {
    width: '100%',
  },
  buttonModel: {
    textTransform: 'none',
  },
  btConfirm: {
    borderRadius: '2rem',
    backgroundColor: theme.palette.background.backgroundButton,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  backButton: {
    backgroundColor: "transparent",
    border: '1px solid #FFD31C',
    borderRadius: '2rem',
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  customSelect: {
    "& .react-select__control": {
      minHeight: "56px",
      borderRadius: "20px",
      fontSize: "0.876rem",
    },
  },
}));

