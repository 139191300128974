import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import Swal from "sweetalert2";

// store
import { useAppSelector } from "store";

// services
import { getVehiclesByOperation } from "services/vehicle";
import { getOperations, deleteOperationById } from "services/operations";
import { handleAmplitudeEvent } from "services/amplitude";

// components
import Aux from "hoc/auxiliar";
import OperationsListSkeleton from "components/Skeletons/OperationsListSkeleton";
import Widget from "components/Widget/Widget";
import Table from "components/Table";
import NotFoundInfo from "components/NotFoundInfo";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import TableResponsive from "components/TableResponsive";

import { makeColumns } from "./tableColumns/tableColumns";

import { allRequirementLevelOptions } from "domain/selectOptions";

import { useWindowSize } from "hooks/useWindowsSize";

// styles
import useStyles from "./styles";

const OperationList = () => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();

  const [availableVehicles, setAvailableVehicles] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [operations, setOperations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [download, setDownload] = useState({
    link: "",
    fileName: ""
  });
  const [selectedRowData, setSelectedRowData] = useState();
  const [id, setId] = useState();

  const onNavigateToHistory = (operationHistoryId) => {
    handleAmplitudeEvent('Log Button Clicked')
    history.push({
      pathname: "/gofurther/maintenance/operations/operationListHistory",
      state: { operationHistoryId },
    });
  }

  const getAvailableVehicles = useCallback(async () => {
    try {
      const response = await getVehiclesByOperation(currentCustomer)
      const { customers: operations } = response.data
      let availableVehicles = []
      operations.forEach(op => {
        if (Number(op.id) === 0) {
          op.vehicles.forEach(vehicle => {
            availableVehicles.push(vehicle)
          })
        }
      })
      const vehicles = ([...new Set(availableVehicles)])
      setAvailableVehicles(vehicles.length)
    } catch (error) {
      throw new Error()
    } finally {
      setLoading(false)
    }
  }, [currentCustomer])

  const getRewardFormatProperty = (item) => {
    if (item?.rewardFormat === 'scoreMinimunReward') {
      return item?.minScore;
    }
    if (item?.rewardFormat === 'scoreMediumReward') {
      return item?.minProportionalScore;
    }

    return undefined;
  }

  const fetchOperations = useCallback(async () => {
    try {
      setLoading(true)
      const response = await getOperations(currentCustomer)
      if (response.status === 200 || response.status === 201) {
        let listOperation = []
        if (response.data.data?.operation) {
          const { operation } = response.data.data;
          operation.forEach(item => {
            listOperation.push({
              ...item,
              name: item?.name,
              vehiclesAmount: item.vehicles ? item.vehicles.length : 0,
              maxAmount: item?.reward.maxAmount,
              requirementLevel: getRequirementLevel(item?.requirementLevel) || '',
              score: getRewardFormatProperty(item)
            });
          })
        } else {
          return null
        }
        setOperations(listOperation);
      } else {
        return toast.error(
          "Erro estranho aconteceu. Entre em contato com o suporte.",
        );
      }
    } catch (error) {
      toast.error(
        "Erro ao buscar operações. Entre em contato com o suporte.",
      );
    } finally {
      setLoading(false)
    }
  }, [currentCustomer])

  const getRequirementLevel = (item) => {
    const requirementLevel = allRequirementLevelOptions.find(
      (req) => Number(req.value) === Number(item),
    )
    return requirementLevel.label
  }

  const handleEdit = (id) => {
    handleAmplitudeEvent('Edit Button Clicked');
    history.push(`/gofurther/maintenance/operations/editOperation/${id}`);
  };

  const handleDelete = (value) => {
    handleAmplitudeEvent('Delete Button Clicked')
    Swal.fire({
      title: "Você está excluindo um grupo de veículos.",
      text: "TODOS veículos vinculados a esse grupo ficarão sem nenhum vínculo. Deseja excluir mesmo assim?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.palette.secondary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteOperationById(value)
          if (response.status === 200 || response.status === 201) {
            handleAmplitudeEvent('Vehicle Group Deleted')
            toast.success("Operação excluída com sucesso!")
            await fetchOperations(currentCustomer)
          } else {
            toast.error("Erro ao remover operação, contate o suporte!")
          }
        } catch (error) {
          toast.error("Erro ao remover operação, contate o suporte!")
        }
      }
    });
  };

  const size = useWindowSize()

  const columns = makeColumns({ onDelete: handleDelete, onEdit: handleEdit, onNavigateToHistory, size });

  const renderTable = useCallback(() => (
    size.mobile ?
      <TableResponsive
        pointer={true}
        options={{
          onRowClick: handleSelectRow,
          rowsPerPage: rowsPerPage,
          setRowProps: (row, index) => {
            return index === id ? { style: { backgroundColor: '#FFF5CB', cursor: 'pointer', width: '100%' } } : null
          },
        }}
        columns={columns}
        data={operations}
        download={download}
        setRowsPerPage={setRowsPerPage}
        tableName="list-vehicle-group"
      /> :
      <Table
        columns={columns}
        data={operations}
        download={download}
        options={{ rowsPerPage }}
        setRowsPerPage={setRowsPerPage}
        tableName="list-vehicle-group"
      />
  ), [columns, operations, download, rowsPerPage]);

  const handleClickAddVehicleForm = () => {
    handleAmplitudeEvent('New Vehicle Group Clicked');
    history.push("/gofurther/maintenance/operations/createOperation");
  };

  useEffect(() => {
    if (hasPermission({ scopes: ['can_view_operations'] })) {
      fetchOperations()
      setDownload({
        link: `/operations-prfc/export?customers=${currentCustomer}`,
        fileName: `grupo-veiculos_${dayjs().format("DD-MM-YYYY_HH-mm")}.xlsx`
      });
    }
  }, [currentCustomer, fetchOperations])

  useEffect(() => {
    currentCustomer && getAvailableVehicles(currentCustomer)
  }, [currentCustomer, getAvailableVehicles]);

  useEffect(() => {
    handleAmplitudeEvent('Vehicle Group Screen Viewed');
  }, []);

  if (loading) return <OperationsListSkeleton />;

  const notfoundContent = (
    <NotFoundInfo
      description='Nenhum grupo de veículos cadastrado,'
      strongDescription="cadastre um grupo de veículos para iniciar."
      localStorageKey="infoBoxOperationList"
    />
  )

  const handleSelectRow = (data, data2) => {
    setId(data2.dataIndex);
    const id = operations[data2.dataIndex].id;
    setSelectedRowData(id)
  };

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_operations']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <PermissionsGate scopes={['can_create_operations']}>
              <Grid container item justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNewDriver}
                    onClick={handleClickAddVehicleForm}
                    data-cy="buttonNewGroup"
                  >
                    Novo grupo
                  </Button>
                </Grid>
              </Grid>
            </PermissionsGate>
            {!operations.length ? notfoundContent : (
              <Widget disableWidgetMenu title="Grupo de veículos">
                <Typography style={size.mobile ? { marginLeft: 15 } : {}} className={classes.amountVehicleTitle} data-cy="pDescription">Veículos sem grupo: {availableVehicles}</Typography>
                {renderTable()}
              </Widget>
            )}
          </Grid>
          {/* botoes */}
          {size.mobile ?
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <PermissionsGate scopes={['can_edit_operations']}>
                  <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                    <Box >
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => handleEdit(selectedRowData)}
                        disabled={!selectedRowData}
                        className={classes.buttonDetail}
                        data-cy="buttonEdit"
                      >
                        Editar
                      </Button>
                    </Box>
                  </Grid>
                </PermissionsGate>
                <PermissionsGate scopes={['can_remove_operations']}>
                  <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                    <Box >
                      <Button
                        fullWidth
                        variant="outlined"
                        style={{ color: selectedRowData ? 'red' : 'auto', borderColor: selectedRowData ? 'red' : 'auto' }}
                        onClick={() => handleDelete(selectedRowData)}
                        disabled={!selectedRowData}
                        className={classes.buttonDetail}
                        data-cy="buttonDelete"
                      >
                        Excluir
                      </Button>
                    </Box>
                  </Grid>
                </PermissionsGate>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box >
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => onNavigateToHistory(selectedRowData)}
                      disabled={!selectedRowData}
                      className={classes.buttonDetail}
                      data-cy="buttonHistory"
                    >
                      Histórico
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid> : null}
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default OperationList;
