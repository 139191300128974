import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import Select from "react-select";

// components
import Calendar from "components/Calendar";
import MultiSelect from "components/MultiSelect";

// styles
import useStyles from "./styles";
import { colourStyles } from "./filters-panel-color-styles";
import { multiSelectStyles } from "./multiSelectStyles";

const FiltersPanel  = React.memo(({
  calendarMaxRanges,
  handleSelectDate,
  handleSelectedData,
  listData,
  selectedDates,
  driverSelectData,
  multiSelectVehicles,
  handleSelectedVehicles,
  selectedVehicles,
  loading
}) => {
  const classes = useStyles();
  const handleCalendar = (startDate, endDate) => {
    handleSelectDate(startDate, endDate);
  };

  return (
    <Grid
      container
      spacing={2}
      data-cy="divGrupoSelects"
      className={classes.container}
    >
      <Grid
        item 
        xl={3}
        lg={12}
        md={6}
        sm={12}
        xs={12}
      >
        <Typography variant="h5" className={classes.title}>
          Análise individual de motoristas
        </Typography>
      </Grid>

      <Grid
        item
        xl={3}
        lg={4}
        md={6}
        sm={12}
        xs={12}
        className={classes.spacingContainer}
      >
        <Paper elevation={0} className={classes.multiselect}>
          <Select
            data-cy="divSelectMotorista"
            placeholder="Selecione um motorista"
            options={listData.map((item) => ({ value: item.id, label: item.name }))}
            value={driverSelectData}
            onChange={handleSelectedData}
            styles={{
              ...colourStyles,
              control: (base) => ({
                ...base,
                borderRadius: 20,
                border: "none",
                padding: "5px 20px",
                "&:hover": {
                  border: "none"
                }
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999
              })
            }}
          />
        </Paper>
      </Grid>

      <Grid
        item
        xl={3}
        lg={4}
        md={6}
        sm={12}
        xs={12}
        className={classes.spacingContainer}
      >
        <Paper elevation={0} className={classes.multiselect}>
          <Calendar
            selectedDates={selectedDates}
            handleCalendar={handleCalendar}
            calendarMaxRanges={calendarMaxRanges}
          />
        </Paper>
      </Grid>

      <Grid
        item
        xl={3}
        lg={4}
        md={6}
        sm={12}
        xs={12}
      >
        <Paper elevation={0} className={classes.multiselect}>
          <MultiSelect
            isSingleMode={false}
            listData={loading.vehicles ? [] : multiSelectVehicles}
            handleSelectedListData={handleSelectedVehicles}
            customStyles={multiSelectStyles}
            selectedData={selectedVehicles}
            noFoundData="Sem veículos vinculados no período filtrado"
          />
        </Paper>
      </Grid>
    </Grid>
  );
});

export default FiltersPanel ;
