import { useMemo, useCallback, useRef, useState } from "react";
import { Autocomplete, TextField, Box, Chip, Typography } from "@mui/material";
import { LocalShipping as TruckIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import useStyles from "./styles";

const StyledAutocomplete = styled(Autocomplete)((theme) => ({
  padding: '6px 0',
  width: '100%',
  'fieldset': {
    border: 'none',
  },
  '.MuiFormLabel-root': {
    margin: 0,
    color: '#818894 !important',
    top: '50%',
    transform: 'translateX(12px) translateY(-50%)'
  },
  '.MuiInputBase-root': {
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: 112,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      width: '6px',
      borderRadius: '18px',
      backgroundColor: '#f5f5f5'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '12px',
      backgroundColor: '#00000014',
    }
  },
}));

export const OperationsSelect = ({
  vehicles = [],
  maxChipTextLength = 10,
  onChange,
  onOperationSelect,
  onOperationDelete,
  onAllSelect,
  onAllDelete,
  ...props
}) => {
  const styles = useStyles();

  const autocompleteRef = useRef(null);

  const [focused, setFocused] = useState(false);
  const [hasText, setHasText] = useState(false);

  const options = useMemo(() => {
    return [
      { id: 0, label: 'Toda minha frota', operation: undefined },
      ...vehicles
    ]
  }, [vehicles]);

  const operations = useMemo(() => {
    return vehicles.reduce((acc, vehicle) => {
      const alreadyExists = acc.some(operation => operation?.id === vehicle.operation?.id);

      return alreadyExists ? acc : [...acc, vehicle.operation];
    }, []);
  }, [vehicles]);

  const vehiclesByOperation = useMemo(() => vehicles.reduce((acc, vehicle) => {
    const operationId = vehicle.operation?.id;

    if (!acc[operationId]) {
      acc[operationId] = [];
    }

    acc[operationId].push(vehicle);

    return acc;
  }, {}), [vehicles]);

  const handleFocus = () => setFocused(true);

  const handleBlur = () => setFocused(false);

  const handleTextChange = (event) => {
    setHasText(event.target.value.length > 0);
    
    if (props.onInputChange) {
      props.onInputChange(event);
    }
  };

  const handleOnChange = useCallback((event, selectedVehicles) => {
    const allSelected = !!selectedVehicles.find(selectedVehicle => selectedVehicle?.id === 0);

    if(allSelected) {
      onAllSelect?.();
    } else {
      const isAllRemoval = selectedVehicles.length === vehicles.length - 1;
      const selectedVehicleLabel = event.currentTarget.innerText;
      const selectedVehicle = vehicles.find(vehicle => vehicle.label === selectedVehicleLabel);

      if(isAllRemoval) {
        onChange?.([selectedVehicle]);
      } else {
        onChange?.(selectedVehicles)
      }
    }
  }, [onChange, onAllSelect]);

  const handleOperationClick = useCallback((operation) => {
    onOperationSelect?.(operation);

    if (autocompleteRef.current) {
      autocompleteRef.current.blur();
    }
  }, [autocompleteRef, onOperationSelect]);

  return (
    <div className={styles.container}>
      <TruckIcon />
      <StyledAutocomplete
        {...props}
        options={options}
        groupBy={(option) => option.operation?.id}
        renderInput={(params) => {
          const shouldHideLabel = focused || hasText || (props.value && props.value.length > 0);

          return (
            <TextField
              {...params}
              inputRef={autocompleteRef}
              label={shouldHideLabel ? '' : "Digite o nome da frota ou a placa"}
              InputLabelProps={{
                shrink: false,
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleTextChange}
            />
          );
        }}
        isOptionEqualToValue={(option, value) => option.label === value?.label}
        renderGroup={(params) => {
          const currentOperation = operations.find(operation => operation?.id === params.group);
          const operationVehicles = vehicles.filter(vehicle => vehicle.operation.id === params.group);

          return (
            <div key={params.key}>
              {currentOperation && (
                <button onClick={() => handleOperationClick(currentOperation)} className={styles.operation}>
                  <span className={styles.operationName}>{currentOperation?.label}</span>
                  <span className={styles.operationVehicles}>{operationVehicles?.length}</span>
                </button>
              )}
              {params.children}
            </div>
          )
        }}
        renderOption={({ key, ...props }, option) => (
          <Box key={key} component="li" {...props}>
            <Typography>{option.label}</Typography>
          </Box>
        )}
        renderTags={(selected, getTagProps) => {
          const isAllSelected = selected?.length === vehicles?.length || (!!selected?.length && !vehicles?.length);

          if (isAllSelected) {
            const { key: allSelectedKey, ...allSelectedProps } = getTagProps({ index: 0 });
            
            return <Chip {...allSelectedProps} key={allSelectedKey} label="Toda minha frota" onDelete={onAllDelete}  />;
          }

          const selectedByOperation = selected?.reduce((acc, vehicle) => {
            const operationId = vehicle.operation?.id;

            if (!acc[operationId]) {
              acc[operationId] = [];
            }
            
            acc[operationId].push(vehicle);

            return acc;
          }, {});

          return Object.keys(selectedByOperation).map((operationId) => {
            const vehiclesInOperation = vehiclesByOperation[operationId];
            const selectedInOperation = selectedByOperation[operationId];

            if (!!selectedInOperation?.length && !vehiclesInOperation?.length) {
              const operationIndex = selected.findIndex(item => item.operation?.id === Number(operationId));

              const { key: selectedOperationKey, ...selectedOperationProps } = getTagProps({ index: operationIndex });

              const operationLabel =
                selectedInOperation[0].operation.label.length > (maxChipTextLength * 1.75)
                  ? selectedInOperation[0].operation.label.slice(0, (maxChipTextLength * 1.75)) + '...'
                  : selectedInOperation[0].operation.label;

              return (
                <Chip
                  {...selectedOperationProps}
                  key={selectedOperationKey}
                  label={
                    <span className={styles.chip}>
                      <span className={styles.chipName}>{operationLabel}</span>
                      <span className={styles.chipAmount}>({selectedInOperation.length})</span>
                    </span>
                  }
                  onDelete={() => onOperationDelete(operationId)}
                />
              );
            }

            return selectedInOperation.map((vehicle) => {
              const vehicleIndex = selected.findIndex(item => item?.id === vehicle?.id);

              const { key: selectedVehicleKey, ...selectedVehicleProps } = getTagProps({ index: vehicleIndex });

              const vehicleLabel = vehicle.label.length > maxChipTextLength ? vehicle.label.slice(0, maxChipTextLength) + '...' : vehicle.label;

              return (
                <Chip
                  {...selectedVehicleProps}
                  key={selectedVehicleKey}
                  label={vehicleLabel}
                />
              )
            });
          });
        }}
        onChange={handleOnChange}
        noOptionsText="Nenhum veículo cadastrado"
        disablePortal
        multiple
      />
    </div>
  )
}
