import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  content: {
    padding: 32,
  },
  iconButton: {
    position: 'absolute',
    right: 16,
    top: 16,
    color: theme.palette.grey[500],
    fill: '#FFFEFF',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.20)',
    width: 16,
    height: 16
  },
  title: {
    fontWeight: 600,
    fontSize: "20px",
    textAlign: "center"
  },
  text: {
    paddingTop: 15
  },
  listItem: {
    paddingTop: 3,
    fontWeight: 600,
  },
  button: {
    marginTop: 20,
    borderRadius: 20,
    textTransform: "none"
  }
}));
